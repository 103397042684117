<template>
  <!-- story-doc -->
  <div class="px-[20px]">
    <template v-for="current of props.panes ?? [props.pane]" :key="current">
      <template v-if="current === 'props'">
        <div class="flex h-12 items-center gap-2 font-medium text-gray-600">
          <div v-if="showName" class="w-32 font-medium">Prop</div>
          <div v-if="showDescription" class="max-w-[560px] grow">Description</div>
          <div v-if="showType" class="w-40">Type</div>
          <div v-if="showDefault" class="w-32">Default</div>
          <!-- <div v-if="showControls" class="w-32">Controls</div> -->
        </div>
        <template v-for="prop in doc?.props ?? []" :key="prop.name">
          <story-doc-item
            v-if="!prop.tags?.ignore && (prop.tags?.access as any)?.[0]?.description !== 'private'"
            :item="prop"
            :state="currentState"
            :options="props.story.options"
          >
          </story-doc-item>
        </template>
      </template>

      <template v-else-if="current === 'events'">
        <div class="flex h-12 items-center gap-2 font-medium text-gray-600">
          <div class="w-40 shrink-0 font-medium">Event</div>
          <div class="w-94 shrink-0">Description</div>
          <div class="w-32 shrink-0">Arguments</div>
          <div class="w-32 shrink-0">Type</div>
          <div class="w-94 shrink-0">Description</div>
        </div>
        <template v-for="event in publicEvents" :key="event.name">
          <div class="flex items-start gap-2 pb-4">
            <div class="w-40 shrink-0 font-medium">@{{ event.name }}</div>
            <div class="w-94 shrink-0">
              {{ event.description }}
            </div>
            <div class="w-32 shrink-0 italic">
              <div v-for="prop in event.properties" :key="prop.name">
                {{ prop.name }}
              </div>
            </div>
            <div class="w-32 shrink-0">
              <div v-for="name in event?.type?.names" :key="name">
                {{ name }}
              </div>
            </div>
            <div class="w-94 shrink-0">
              <div v-for="prop in event.properties" :key="prop.description">
                {{ prop.description }}
              </div>
            </div>
          </div>
        </template>
      </template>

      <template v-else-if="current === 'slots'">
        <template v-for="slot in doc?.slots" :key="slot.name">
          <story-accordeon v-if="props.showSlots && doc?.slots?.length" :is-expanded="false">
            <template #header>
              <h4 class="!m-0 text-sm">
                <span class="font-medium">#{{ slot.name }}</span
                ><span class="text-gray-500" v-if="slot.bindings"
                  >="{
                  {{ slot.bindings?.map(b => toCamelCase(b.name ?? '')).join(', ') }}
                  }"</span
                >
              </h4>
              <div class="text-sm">{{ slot.description }}</div>
            </template>
            <template #content>
              <div class="flex h-12 items-center gap-2 font-medium text-gray-600">
                <div class="w-40 shrink-0 font-medium">Binding</div>
                <div class="w-32 shrink-0">Type</div>
                <div class="w-[560px] shrink-0">Description</div>
              </div>
              <template v-for="binding in slot.bindings" :key="binding.name">
                <div class="flex items-start gap-2 pb-4">
                  <div class="w-40 shrink-0 font-medium">{{ toCamelCase(binding.name ?? '') }}</div>
                  <div class="w-32 shrink-0">
                    {{ binding?.type?.name }}
                  </div>
                  <div class="w-[560px] shrink-0">
                    {{ binding.description }}
                  </div>
                </div>
              </template>
            </template>
          </story-accordeon>
        </template>
      </template>

      <template v-else-if="current && props.story.states?.[current]">
        <div
          class="flex h-12 items-center gap-2 font-medium text-gray-600"
          :letSubstate="(subState = props.story.states?.[current])"
        >
          <div v-if="showName" class="w-32 font-medium">Variable</div>
          <div v-if="showDescription" class="max-w-[560px] grow">Description</div>
          <div v-if="showType" class="w-40">Type</div>
          <div v-if="showDefault" class="w-32">Action</div>
          <!-- <div v-if="showControls" class="w-32">Controls</div> -->
        </div>
        <template v-for="arg in Object.keys(subState)" :key="arg">
          <story-doc-item
            :item="{
              name: arg,
              description: subState[arg]?.description,
              required: subState[arg].isRequired,
              type: { name: subState[arg].type },
            }"
            :state="subState[arg]"
            :options="subState"
            access-arg="value"
          />
        </template>
        <div class="flex pb-3" v-if="(subState as any)[actionsKey$]">
          <div class="grow"></div>
          <div v-for="action in (subState as any)[actionsKey$] as IStoryButton[]" :key="action.label">
            <nx-button
              class="border border-solid border-gray-300 bg-gray-100 hover:bg-gray-300"
              @click="action.onClick"
            >
              {{ action.label }}
            </nx-button>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { getDoc } from '@hauru/common'
import { actionsKey$ } from '@storytime'
import type { IStory, IStoryButton, ILinkedState } from '@storytime'
import { computed, ref } from 'vue'

interface IProps {
  /**
   * Name of component whose documentation we want to show
   */
  component: string
  pane?: 'events' | 'props' | 'slots' | string
  panes?: ('events' | 'props' | 'slots' | string)[]
  /**
   * Reactive object that contains the current state of the story (prop values, types ...)
   */
  story: IStory<unknown>
  /**
   * Boolean that indicates if all columns should be shown
   */
  showAll?: boolean
  /**
   * Boolean that indicates if name column should be shown
   */
  showName?: boolean
  /**
   * Boolean that indicates if description column should be shown
   */
  showDescription?: boolean
  /**
   * Boolean that indicates if type column should be shown
   */
  showType?: boolean
  /**
   * Boolean that indicates if default column should be shown
   */
  showDefault?: boolean
  /**
   * Boolean that indicates if controls column should be shown
   */
  showControls?: boolean
  /**
   * Boolean that indicates if events should be shown
   */
  showEvents?: boolean
  /**
   * Boolean that indicates if slots should be shown
   */
  showSlots?: boolean
  /**
   * Name of the component used as title
   */
  title?: string
}

const props = withDefaults(defineProps<IProps>(), {
  showAll: true,
  showName: undefined,
  showDescription: undefined,
  showType: undefined,
  showDefault: undefined,
  showControls: undefined,
  showEvents: true,
  showSlots: true,
})

const showName = computed(() => (props.showAll && props.showName !== false) || props.showName)
const showDescription = computed(() => (props.showAll && props.showDescription !== false) || props.showDescription)
const showType = computed(() => (props.showAll && props.showType !== false) || props.showType)
const showDefault = computed(() => (props.showAll && props.showDefault !== false) || props.showDefault)

let subState: ILinkedState

const selectedStateIndex = ref(0)
const currentState = computed(() =>
  Array.isArray(props.story.props) ? props.story.props[selectedStateIndex.value] : props.story.props,
)

const doc = computed(() => (props.component ? getDoc(props.component) : undefined))
const publicEvents = computed(
  () => doc.value?.events?.filter(e => e.tags?.findIndex(t => (t as any)?.content === 'private') === -1) ?? [],
)

function toCamelCase(str: string) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}
</script>
