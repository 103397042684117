<style scoped>
.code {
  font-family: monospace;
  min-width: 100%;
  min-height: 80%;
}
.data-report-run .buttons button {
  margin-right: 5px;
}
.data-report-run .variables {
  display: flex;
  flex-direction: row;
}
.data-report-run .variables .variable {
  display: flex;
  flex-direction: column;
  margin: 14px 0;
  padding: 0 24px;
  border-left: solid rgb(50, 50, 50, 0.2) 1px;
}
.data-report-run .variables .variable:first-child {
  border-left: none;
}
.data-report-run .url-block input {
}
.data-report-run .url-block .url-container {
  display: flex;
  width: 100%;
  margin-bottom: 14px;
}
.data-report-run .url-block .url-container input {
  width: 100%;
  max-width: 100%;
  flex: auto;
}
.data-report-run .url-block .url-container .actions {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.data-report-run .url-block .url-container .actions svg {
  width: 16px;
  height: 16px;
}
.data-report-run .result {
  margin-top: 24px;
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
}
.data-report-run .result .vue-codemirror {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #e3e3e3;
}

.data-report-run .CodeMirror {
  height: 100%;
  max-height: 100%;
}
.data-report-run button svg {
  height: 16px;
  width: 16px;
}
</style>

<template lang="pug">
h1 {{ `${t.data_report}: ${name}` }}
  button(@click="$router.push({ path: $root.appath +  'data-report', query: { id } })") {{ t.edit }}

.block
  .row
    h2 {{ t.variables || 'Variables' }}
  .row.variables(v-if="!loading")
    .variable(v-for="meta of variablesMeta")
      label {{ t[meta.label] || meta.label }}
      input(type="text" :placeholder="meta.value" v-if="meta.type === 'string'" v-model="variables[meta.label]")
      input(type="number" :placeholder="meta.value" v-if="meta.type === 'number'" v-model="variables[meta.label]")
      input(type="checkbox" v-if="meta.type === 'boolean'" v-model="variables[meta.label]")

.block.expand.url-block
  .url-container
    input(readonly="readonly" ref="url" :value="url.replace(/.token=.*/, '')")
    .actions
      button.ghost(tt="Copy" @click="copy()")
        svg-icon(name="ic_file_copy")
      button(tt="Run" @click="test")
        svg-icon(name="pt-icon-play")
  .row.result.expand
    // codemirror(:code="result" :options="{ mode: 'javascript', json: true, readOnly: true, theme: 'default' }")
    .row.expand
      .block
        label {{ t.result || 'result' }}
        textarea.code(readonly v-model="result")
</template>

<script>
import dataReportService from '@100-m/hauru/src/services/DataReportService'

export const additions = {}
export default {
  data() {
    return {
      loading: true,
      dr: null,
      id: 0,
      name: '',
      variables: {},
      result: '',
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.url)
    },
    async init() {
      this.loading = true
      this.id = parseInt(this.$route.query.id)
      this.dr = await this.getQuery(this.id)
      this.name = this.dr.name
      this.variables = { ...this.dr.variables }
      this.loading = false
    },
    async getQuery(id) {
      return await dataReportService.getById(id)
    },

    async test() {
      const r = await dataReportService.run(this.id, this.variables)
      this.result = JSON.stringify(r, null, 2)
    },
  },
  computed: {
    variablesMeta() {
      if (!this.dr?.variables) return []
      return Object.entries(this.dr.variables).map(([k, v]) => {
        return {
          label: k,
          type: typeof v,
          value: v,
        }
      })
    },
    url() {
      const baseUrl = config.graphqlEndpoint.includes('localhost') ? '' : `${window.location.protocol}//${window.location.host}`
      return `${baseUrl}${config.graphqlEndpoint}/dr/${this.id}/run${this.varUrl}${this.varUrl === '' ? `?` : `&`}token=${$root.profile.idToken}`
    },
    varUrl() {
      if (!this.variables) return ''
      return Object.entries(this.variables).reduce((a, [k, v]) => {
        return `${a}${a ? '&' : '?'}${k}=${encodeURI(v)}`
      }, '')
    },
  },
}
</script>
