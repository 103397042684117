export function isNumeric(x: any) {
  if (typeof x === 'number') return isFinite(x)
  if (typeof x === 'string') {
    // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    // and ensure strings of whitespace fail
    return !isNaN(x as unknown as number) && !isNaN(parseFloat(x))
  }
  return false
}

export function isText(x: any) {
  if (typeof x === 'string') return true
  return false
}

/**
 * Return max numeric key from map
 * @example: getMaxNumericKey({0: "val0", 1: "val1", 3: "val3"}) => 3
 */
export function getMaxNumericKey(obj: object = {}): number | null {
  const keys = Object.keys(obj).map(strKey => parseFloat(strKey) || 0)
  if (!keys.length) {
    return null
  }
  return Math.max.apply(null, keys) || null
}

export function numberFormat(
  value: number | null | undefined,
  digits: number = 0,
  isPercent = false,
  isMaxDigits = false,
) {
  if (isText(value)) value = value?.replace(',', '.')
  const lang = localStorage.number_lang || 'en'
  let format = isMaxDigits ? { maximumFractionDigits: digits } : { minimumFractionDigits: digits }
  if (isPercent) {
    // @ts-ignore
    format = { minimumFractionDigits: digits, style: 'percent' }
  }
  const formated = value && Intl.NumberFormat(lang, format).format(value)
  if (Number.isNaN(formated) || formated === 'NaN') {
    console.warn('input format is incorrect')
    return value
  }
  return formated
}

/**
 * Returns the given number if it is between a minimum and a maximum,
 * otherwise returns the closest acceptable value
 * @param n The number we are checking against
 * @param min The minimum acceptable number
 * @param max The maximum acceptable number
 */
export function minMax(n: number, min: number, max: number) {
  return Math.max(min, Math.min(max, n))
}
