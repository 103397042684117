<template>
  <lgi-block :data="data" :options="options">
    <div class="flex flex-1 flex-col">
      <div class="nx-pie relative m-auto w-[200px] h-[200px]">
        <svg viewBox="-150 -150 300 300">
          <g v-for="item in items" :key="item.stringValue">
            <path :d="item.arc" :style="{ opacity: item.opacity }"></path>
          </g>
        </svg>
      </div>
      <div class="flex flex-wrap justify-center">
        <div v-for="item in items" :key="item.stringValue" class="legend-item">
          <span class="legend-color" :style="{ opacity: item.opacity }"></span>
          <span class="legend-text">{{ item.shortLabel }}: {{ item.value }}</span>
        </div>
        <div v-if="showTotal" class="legend-item">
          <span>Total: {{ format(totalDisplay, options.digit + options.unit) }}</span>
        </div>
      </div>
      <!-- <div class="nx-legend">

      </div> -->
    </div>
  </lgi-block>
</template>

<script setup>
// WARNING do not use this component as a template for new components (code quickly for lgi)
// Mix of css and tailwind
// strange logic
import format from '../../format.js'
import * as d3 from 'd3'
import { computed } from 'vue'
const { data, options } = defineProps(['data', 'options'])

const getValue = obj => obj.value || Object.values(obj).find(v => typeof v === 'number')
const getStringKey = obj => Object.keys(obj).find(k => typeof obj[k] === 'string' && k.toLowerCase() !== 'total')

const shortenLabel = str => {
  if (str.includes(' ')) {
    return str
      .split(' ')
      .map(word => word.substring(0, 2))
      .join('')
      .substring(0, 8)
  } else {
    return str.substring(0, 12)
  }
}

const filteredData = data.filter(obj => {
  return !Object.entries(obj).some(([key, value]) => key.toLowerCase() === 'total' || String(value).toLowerCase() === 'total')
})

const total = filteredData.map(getValue).reduce((a, b) => a + b, 0)
const limitedData = filteredData.slice(0, options.limit || Infinity)
const limitedTotal = limitedData.map(getValue).reduce((a, b) => a + b, 0)

const outerRadius = 80
const labelOffset = 30

const totalDisplay = limitedTotal
const showTotal = options.total

const items = limitedData.map((v, i, ds) => {
  const value = getValue(v)
  const stringKey = getStringKey(v)
  const stringValue = v[stringKey]
  const shortLabel = shortenLabel(stringValue)
  const prev = ds
    .slice(0, i)
    .map(getValue)
    .reduce((a, b) => a + b, 0)
  const next = prev + value
  const percentage = (value / limitedTotal) * 100

  const startAngle = (2 * Math.PI * prev) / limitedTotal
  const endAngle = (2 * Math.PI * next) / limitedTotal
  const midAngle = startAngle + (endAngle - startAngle) / 2

  const labelRadius = outerRadius + labelOffset
  const labelX = labelRadius * Math.cos(midAngle - Math.PI / 2)
  const labelY = labelRadius * Math.sin(midAngle - Math.PI / 2)

  const arc = d3.arc()({
    innerRadius: 60,
    outerRadius: 90,
    startAngle,
    endAngle,
  })
  const opacity = [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1]
  return {
    ...v,
    value,
    stringValue,
    shortLabel,
    arc,
    opacity: opacity[i % opacity.length],
    label: { x: labelX, y: labelY, show: percentage >= 5 },
  }
})
</script>

<style>
.nx-pie {
  flex: 1;
  margin-left: 20px;
}

.nx-legend {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  flex-wrap: wrap;
  max-height: 200px;
}
.legend-item {
  display: inline-block;
  margin-right: 10px;
  width: 80px;
}

.legend-color {
  background: rgb(var(--primary)) !important;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid #ccc;
}

.legend-text {
  font-size: 12px;
}

.nx-pie circle {
  pointer-events: none;
  fill: none;
  stroke: white;
  stroke-width: 4px;
}

.nx-pie circle {
  pointer-events: none;
  fill: none;
  stroke: white;
  stroke-width: 2px;
}

.nx-pie path:nth-child(1) {
  fill: rgb(var(--primary)) !important;
}
</style>
<script>
export default {
  api: {
    total: {
      label: 'Show Total',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: '',
      },
    },
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 1,
      attrs: {
        type: 'number',
      },
    },
    limit: {
      label: 'Limit',
      attrs: {
        type: 'number',
      },
    },
    header: {
      label: 'Header',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: '',
      },
    },
    auto: {
      label: 'Autosize',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: '',
      },
    },
    compact: {
      label: 'Compact',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: '',
      },
    },
  },
}
</script>
