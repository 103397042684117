/* globals window, $root, config */
import { GraphQlService } from './GraphQlService'

export class DataReportService extends GraphQlService {
  async list() {
    return await (await fetch(`${config.graphqlEndpoint}/dr?token=${$root.profile.idToken}`)).json()
  }

  async getByName(name, variables) {
    return (
      await this.query(
        `query getDrByName($name: String!, $variables: JSON) {
          rawData {
            dataReportDefinitionByName(name: $name, variables: $variables) {
              id,
              name,
              query,
              variables
            }
          }
        }`,
        { name, variables },
      )
    ).data.rawData.dataReportDefinitionByName
  }

  async getById(id, variables) {
    return (
      await this.query(
        `query getDrById($id: Int!, $variables: JSON) {
          rawData {
            dataReportDefinitionById(id: $id, variables: $variables) {
              id,
              name,
              query,
              variables
            }
          }
        }`,
        { id, variables },
      )
    ).data.rawData.dataReportDefinitionById
  }
  
  async run(id, variables, options = {preProcess:false, postProcess: false}) {
    const toQuery = (obj) =>
        Object.entries(obj || {}).reduce((a, [k, v]) => `${a}${a ? '&' : '?'}${k}=${encodeURI(v)}`, '');

    try {
        const response = await fetch(
            `${config.graphqlEndpoint}/dr/${id}/run${toQuery({
                ...variables,
                token: $root.profile.idToken,
                preProcess: options.preProcess,
                postProcess: options.postProcess
            })}`
        );
        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        const data = await response.json();
        return { data, error: null };
    } catch (error) {
        return { data: null, error: error.message || 'Unexpected error' };
    }
  }

  async create({ name, query, variables }) {
    return (
      await this.query(
        `
      mutation create($name: String!, $query: String!, $variables: JSON) {
        addDataReportDefinition(name: $name, query: $query, variables: $variables) {
          id
        }
      }`,
        { name, query, variables },
      )
    ).data.addDataReportDefinition
  }

  async update(id, { name, query, variables }) {
    if (!variables) variables = {}
    return (
      await this.query(
        `
      mutation update($id: Int!, $name: String, $query: String, $description: String, $variables: JSON) {
        updateDataReportDefinition(id: $id, name: $name, query: $query, description: $description, variables: $variables) {
          id
        }
      }`,
        { id, name, query, variables },
      )
    ).data.updateDataReportDefinition
  }

  async remove(id) {
    return (
      await this.query(
        `
      mutation remove($id: Int!) {
        deleteDataReportDefinition(id: $id) {
          id
        }
      }`,
        { id },
      )
    ).data.deleteDataReportDefinition
  }
}

const dataReportService = new DataReportService()
export default dataReportService
