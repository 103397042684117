<style scoped></style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1 Templates ({{templates?.length || 0}})
.block.expand
  spreadsheet.stripped.expand.nosort(:data="templates" :options="{ columns: ['name', 'Data Report', 'Last Update', 'Usage', ,'actions'], editable: false }")
    template(v-slot:cell-name="{ column, line }")
      input(:value="line[column]" @change="rename(line, $event.target.value)")
    template(v-slot:cell-last-update="{ column, line }")
      input(:value="line.createdAt" @change="rename(line, $event.target.value)")
    template(v-slot:cell-usage="{ column, line }")
      input(:value="line.usage" @change="rename(line, $event.target.value)")
    template(v-slot:cell-data-report="{ column, line }")
      input(:value="line.dataReportName")
    template(v-slot:cell-actions="{ column, line }")
      .row
        button.ghost(tt="Edit" @click="edit(line)")
          svg-icon(name="pt-icon-edit")
        button.ghost(tt="Duplicate" @click="duplicate(line)")
          svg-icon(name="ic_file_copy")
        button.ghost(tt="Delete" @click="confirmRemoveId=line" v-if="line.usage === 0")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(@click="create") Create template
  popup(:show="confirmRemoveId")
      template(v-slot:header)
        | {{t["confirmation_remove"]}}
      template(v-slot:content)
        | {{t["text_remove"]}}
      template(v-slot:action)
        button.secondary-action(@click="confirmRemoveId = null") {{t['confirmation_btn_close']}}
        button.main-action(@click="remove(confirmRemoveId)") {{t["confirmation_btn_delete"]}}
</template>

<script>
import Popup from '../../../components/ui/popup.vue'
export default {
  icon: 'ic_picture_as_pdf',
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import templateService from '../../../services/TemplateService'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import { commandrSDK } from '../../../features/commandr'
import { merge } from '@100-m/hauru/src/utils/merge'

const isLoading = ref(true)
const templates = ref([])
const confirmRemoveId = ref(null)


const getTemplatesWithUsage = async () => {
  isLoading.value = true
  const partialTemplates = await templateService.listWithTemplateContext()
  const pressesTemplateNames = (await commandrSDK.get('/data/presses'))?.v().filter(press=>!press.disabled).map(presse => presse.template)
  const dataReportList = await dataReportService.list()
  const templatesInformation = partialTemplates.reduce((acc, partialTemplate) => {
    const nbPresses = pressesTemplateNames?.filter(pressTemplateName => pressTemplateName === partialTemplate.name).length
    const dataReportName = dataReportList.find(dataReport => dataReport.id === partialTemplate.dataReportId)?.name || '-'
    acc.push({ ...partialTemplate, usage:nbPresses, dataReportName })
    return acc
  }, [])
  isLoading.value = false
  return await(templatesInformation)
}

const rename = async (name, newName) => {
  console.log(name, newName)
  // await templateService.rename(path, name)
  // templates.value = await getTemplatesWithUsage()
}
const create = async () => {
  const newName = prompt('Enter template name', new Date().format('YYYY-MM-DD hh:mm'))
  if (templates.value.find(v => v === newName)) return alert('Template already exists')
  const defaultTemplate = {
    theme: {
      prefix: 'grk',
      page_size: 'A4',
      page_orientation: 'portrait',
      spacing: 3,
      primary: '#069f86',
      secondary: '#444444',
      font: 'MarkPro',
      text_size: 2.5,
      title_size: 4,
      style: '',
    },
    pages: [{ lines: [{ options: {title: 'block'}, component: 'block' }, { options: {title: 'block'}, component: 'block' }] }],
  }
  const newTemplate = merge(defaultTemplate, config.defaultTemplate)
  await templateService.create(newName, newTemplate)
  edit({name: newName})
}
const edit = templateInfo => {
  $root.$router.push(`/builder/?templateName=${templateInfo.name}`)
}
const duplicate = async templateInfo => {
  const template = await templateService.get(templateInfo.name)
  const newName = prompt(
    'Enter template name',
    `${name.replace(/ \d\d\d\d-\d\d-\d\d \d\d:\d\d/, '')} ${new Date().format('YYYY-MM-DD hh:mm')}`,
  )
  await templateService.create(newName, template.layout, template.dataReportId)
  edit({name: newName})
}
const remove = async templateInfo => {
  confirmRemoveId.value = null
  await templateService.removeAll(templateInfo.name)
  templates.value = await getTemplatesWithUsage()
}
onMounted(async () => {
  templates.value = await getTemplatesWithUsage()
})
</script>
