import { onMounted, onUnmounted, ref } from 'vue'
import fileService from '../../../services/FileService'

export function useFiles(options = { limit: 100 }) {
  const files = ref([])
  const loaded = ref(false)
  let t = null
  onMounted(async () => {
    files.value = await fileService.list(options)
    loaded.value = true
    t = setInterval(refresh, 30000)
  })

  onUnmounted(() => {
    clearInterval(t)
  })

  const refresh = async () => {
    files.value = await fileService.list(options)
  }

  return {
    files,
    loaded,
  }
}
