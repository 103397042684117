export default {
  logo: 'logo.png',
  nav: 'nav.png',
  icon: 'icon.png',
  navSmall: 'nav_small.png',
  auth: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    project: import.meta.env.VITE_PROJECT,
    urls: ['https://fireauth.100m.io/'],
  },
  commandr: import.meta.env.VITE_COMMANDR,
  exporter: import.meta.env.VITE_EXPORTER,
  project: import.meta.env.VITE_PROJECT,
  db: import.meta.env.VITE_DB || 'firebase',
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    name: import.meta.env.VITE_FIREBASE_NAME,
    project: import.meta.env.VITE_FIREBASE_PROJECT,
    root: import.meta.env.VITE_FIREBASE_ROOT || import.meta.env.VITE_FIREBASE_PROJECT,
  },
  graphqlEndpoint: import.meta.env.VITE_GRAPHQL_ENDPOINT || '/graphql',
  mode: import.meta.env.MODE,
  defaultTemplate: {
    theme: {
      font: 'Lato',
      style: '',
      assets: [],
      prefix: 'lgi',
      primary: '#20487c',
      spacing: '0',
      page_size: 'A4',
      secondary: '#b20f5f',
      text_size: '3.25',
      title_size: '4.25',
      page_orientation: 'landscape',
    },
  },
}
