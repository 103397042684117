<template>
  <!-- story-template -->
  <div
    class="relative flex h-full w-full overflow-hidden rounded-b bg-white"
    v-if="$root.$route.path.split('/').length <= 3"
  >
    <slot></slot>
  </div>
  <div class="relative h-fit w-full rounded-b bg-white" v-else>
    <div class="top-0 z-40 h-4 w-full bg-white" :class="[isSticky ? 'sticky' : '']"></div>
    <div
      class="top-3 z-50 rounded-t border border-b-0 border-solid border-gray-400/80 bg-white"
      :class="[isSticky ? 'sticky' : '']"
      ref="headerRef"
    >
      <div class="flex w-full items-center border-b border-solid border-gray-300">
        <div class="w-1/3 order-3">
          <div class="flex items-center p-2 text-sm">
            <div class="grow"></div>
            <div
              class="ml-4 mr-2 flex cursor-pointer content-center items-center"
              :class="[
                isSticky
                  ? 'i-lucide/pin text-gray-600 hover:text-gray-600'
                  : 'i-lucide/pin-off text-gray-400 hover:text-gray-500',
              ]"
              @click="() => setSticky(!isSticky)"
            ></div>
            <div
              class="ml-1 mr-4 flex cursor-pointer content-center items-center text-gray-600 i-lucide/expand"
              @click="() => window.$('.resize').requestFullscreen()"
            ></div>
            <div class="flex text-sm">
              <div
                v-for="(component, i) in componentNames"
                :key="component"
                :class="[
                  toggleButtonClass,
                  i === 0 ? 'rounded-l border-l' : '',
                  i === componentNames.length - 1 ? 'rounded-r' : '',
                  componentNames?.[i+1] === selectedComponent ? 'border-r-gray-400' : '',
                  component === selectedComponent ? 'border-gray-400 bg-gray-100' : '',
                ]"
                @click="toggleSelectComponent(component)"
              >
                {{ component }}
              </div>
            </div>
            <!-- <div
              v-for="(size, i) in sizes"
              :key="size.title"
              class="flex h-7 w-10 cursor-pointer items-center justify-center border-b border-r border-t border-solid border-gray-300 px-2 py-1 font-medium text-gray-600 hover:bg-gray-50"
              :class="[
                i === 0 ? 'rounded-l border-l' : '',
                i === sizes.length - 1 ? 'rounded-r' : '',
                i === selectedSizeIndex - 1 ? 'border-r-gray-400' : '',
                i === selectedSizeIndex ? 'border-gray-400 bg-gray-100' : '',
              ]"
              @click="setSize(size)"
            >
              {{ size.title }}
            </div> -->
          </div>
        </div>
        <div class="order-2 flex w-1/3 justify-center p-3 text-sm font-normal text-gray-600">
          {{ width - 24 }} x {{ height - 24 }}
        </div>
        <div class="w-1/3 order-1">
          <div class="flex p-2 text-sm">
            <div
              v-for="(example, i) in examplesShown"
              :key="example.name"
              :class="[
                toggleButtonClass,
                i === 0 ? 'rounded-l border-l' : '',
                i === examplesShown.length - 1 && examplesHidden.length === 0 ? 'rounded-r' : '',
                i === stateStory.exampleIndex - 1 ? 'border-r-gray-400' : '',
                i === stateStory.exampleIndex ? 'border-gray-400 bg-gray-100' : '',
              ]"
              @click="selectedStory.setExample(example.name, i)"
            >
              {{ example.name }}
            </div>
            <nx-dropdown class="shrink-0" align="right" class-dropdown="!w-40" v-if="examplesHidden.length">
              <template #button>
                <div
                  class="flex h-7 shrink-0 cursor-pointer items-center justify-center rounded-r border-b border-r border-t border-solid border-gray-300 px-1 font-medium text-gray-600 hover:bg-gray-50"
                >
                  <div class="i-[ic/baseline-more-vert] block !transform-none"></div>
                </div>
              </template>
              <nx-dropdown-select-list
                :list="examplesHidden"
                :toggle="(ex: IStoryBase<any>) => selectedStory.setExample(ex.name)"
                :checked="false"
                label-key="name"
                :is-toggleable="false"
                :is-draggable="false"
              />
            </nx-dropdown>
            <div class="grow"></div>
          </div>
        </div>
      </div>
      <div class="flex w-full items-center justify-center p-3">
        <div
          class="flex w-full resize overflow-hidden border border-solid border-gray-300 bg-gray-50"
          v-bind="$attrs"
          ref="el"
        >
          <div class="flex h-full w-full p-3">
            <!-- @slot Contains the component that will be shown inside a black box -->
            <slot></slot>
          </div>
        </div>
      </div>
      <div
        v-if="props.showDoc && selectedComponent"
        class="flex w-full items-center gap-1 border-t border-solid border-gray-300 p-2"
      >
        <nx-button :class="[buttonClass, selected === 'props' ? selectedClass : '']" @click="toggleSelect('props')">Props</nx-button>
        <nx-button :class="[buttonClass, selected === 'slots' ? selectedClass : '']" @click="toggleSelect('slots')">Slots</nx-button>
        <nx-button :class="[buttonClass, selected === 'events' ? selectedClass : '']" @click="toggleSelect('events')">Events</nx-button>
        <div class="grow"></div>
        <nx-button
          v-for="pane of Object.keys(selectedStory.states)"
          :key="pane"
          :class="[buttonClass, selected === pane ? selectedClass : '']"
          @click="toggleSelect(pane)"
        >
          {{ pane }}
        </nx-button>
        <div v-if="Object.keys(selectedStory.states).length" class="m-1 h-6 w-px bg-gray-200"></div>
        <nx-button :class="[buttonClass, selected === 'data' ? selectedClass : '']" @click="toggleSelect('data')">Data</nx-button>
        <nx-button :class="[buttonClass, selected === 'code' ? selectedClass : '']" @click="toggleSelect('code')">Code</nx-button>
        <nx-button :class="[buttonClass, selected === 'html' ? selectedClass : '']" @click="toggleSelect('html')">HTML</nx-button>
        <nx-button :class="[buttonClass, selected === 'css' ? selectedClass : '']" @click="toggleSelect('css')">CSS</nx-button>
      </div>
    </div>
    <div
      class="w-full rounded-b border border-t-0 border-solid border-gray-400/80"
      :class="[isSticky ? 'vsg-make-children-header-sticky' : '']"
    >
      <templatev v-if="props.showDoc && selectedComponent">
        <template v-if="['props', 'slots', 'events', ...Object.keys(selectedStory.states)].includes(selected)">
          <story-doc
            :key="selectedComponent"
            :component="selectedComponent"
            :story="selectedStory"
            :pane="selected"
            class="!mt-0 !border-none"
          />
        </template>
        <template v-else-if="selected === 'data'">
          <div class="h-[300px] overflow-auto rounded">
            <codemirror
              :model-value="
                JSON.stringify(
                  selectedStory.props,
                  (key, val) => {
                    if (typeof val === `function`) {
                      return val.toString().replaceAll(`          `, `    `) // implicitly `toString` it
                    }
                    return val
                  },
                  2,
                )
                  .replaceAll(`\\n`, `\r\n`)
                  .replaceAll(`\\&quot;`, `&quot;`)
              "
              @update:modelValue="Object.assign(selectedStory.props, JSON.parse($event))"
              :autofocus="true"
              :extensions="extensions_js"
            />
          </div>
        </template>
        <template v-else-if="selected === 'code'">
          <div class="h-[300px] overflow-auto rounded">
            <codemirror v-model="componentCode" :autofocus="true" :extensions="extensions_html" />
          </div>
        </template>
        <template v-else-if="selected === 'html'">
          <div class="h-[300px] overflow-auto rounded">
            <codemirror v-model="extractedCode" :autofocus="true" :disabled="true" :extensions="extensions_html" />
          </div>
        </template>
        <template v-else-if="selected === 'css'">
          <div class="h-[300px] overflow-auto rounded">
            <codemirror v-model="stories[0].css" :autofocus="true" :extensions="extensions_css" />
          </div>
        </template>
        <div v-html="'<style>' + stories[0].css + '</style>'"></div>
      </templatev>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCode } from '@hauru/common'
import { Codemirror } from 'vue-codemirror'
import { oneDark } from '@codemirror/theme-one-dark'
import { html } from '@codemirror/lang-html'
import { css } from '@codemirror/lang-css'
import { javascript } from '@codemirror/lang-javascript'
import { computed, onMounted, ref } from 'vue'
import { useElementSize } from '@vueuse/core'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { IStoryBase, IStoryState } from '@storytime'

interface IProps {
  /**
   * Code string of the component example we are presenting
   */
  code?: string
  /**
   * Object mapping component names to their respective states
   */
  story?: IStoryState<any> | IStoryState<any>[]
  /**
   * Number of examples to show before putting the rest in a dropdown
   */
  maxExamples?: number
  /**
   * Boolean indicating whether to show the documentation or not
   */
  showDoc?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  showDoc: true,
  maxExamples: 3,
})

const toggleButtonClass = 'flex h-7 shrink-0 cursor-pointer items-center justify-center border-b border-r border-t border-solid border-gray-300 px-2 py-1 font-medium text-gray-600 hover:bg-gray-50'
const buttonClass = '!h-7 shrink-0 px-2 py-1 font-medium !text-gray-600 hover:bg-gray-50 hover:!text-gray-600 border border-solid border-white hover:!border-gray-300'
const selectedClass = '!border-gray-400 bg-gray-100 hover:!border-gray-400'

const isSticky = ref(false)

const headerRef = ref<HTMLElement>()
const { height: headerHeight } = useElementSize(headerRef)
const headerCalc = computed(() => `${headerHeight.value + 12 + 1}px`)

const stories = computed(() => {
  return !props.story ? [{} as IStoryState<unknown>] : Array.isArray(props.story) ? props.story : [props.story]
})

const componentNames = computed(() => stories.value.map(s => s.name))
const selectedComponent = ref()
const stateStory = computed(() => {
  return stories.value[0]
})
const selectedStory = computed(() => {
  return stories.value.find(s => s.name === selectedComponent.value) ?? stories.value[0]
})

const examplesShown = computed(() => {
  return selectedStory.value.examples.slice(0, props.maxExamples)
})

const examplesHidden = computed(() => {
  return selectedStory.value.examples.slice(props.maxExamples)
})

const el = ref()
const { width, height } = useElementSize(el)

const extractedCode = computed(() => extractCode(props.code ?? stories.value[0].code))
const selectedSizeIndex = computed(() => {
  return sizes.findIndex(s => s.width === width.value)
})

const extensions_html = [html(), oneDark]
const extensions_css = [css(), oneDark]
const extensions_js = [javascript(), oneDark]

const selected = ref('props')
const componentCode = computed(() => getCode(selectedComponent.value + '.vue'))

const sizes = [
  {
    title: 'sm',
    height: 300,
    width: 300,
  },
  {
    title: 'md',
    height: 450,
    width: 450,
  },
  {
    title: 'lg',
    height: 600,
    width: 600,
  },
]

function extractCode(code: string) {
  const extractedCode = code.match(/<story-template[^>]*>([^]+)<\/story-template>/)?.[1]
  const extraWhitespaces = extractedCode?.match(/\s*/)?.[0]
  const prettierCode = extraWhitespaces
    ? extractedCode?.replaceAll(extraWhitespaces, '\n').trim()
    : extractedCode?.trim()
  return prettierCode
}

function setSize(size: any) {
  console.log(el, size)
  el.value!.style.width = `${size.width + 2}px`
  el.value!.style.height = `${size.height + 2}px`
}

function setSticky(val: boolean) {
  isSticky.value = val
  localStorage.setItem('isStickyDesignDoc', val ? 'true' : 'false')
}

onMounted(() => {
  if ((el.value?.clientHeight ?? 0) > (window.innerHeight * 70) / 100) el.value!.style.height = '70vh'

  isSticky.value = Boolean(localStorage.getItem('isStickyDesignDoc'))
})

function toggleSelect(str: string) {
  if (selected.value === str) selected.value = ''
  else selected.value = str
}

function toggleSelectComponent(value: any) {
  if (selectedComponent.value === value) selectedComponent.value = null
  else selectedComponent.value = value
}
</script>

<style lang="postcss">
.vsg-make-children-header-sticky .vsg-sticky-top {
  position: sticky;
  z-index: 30;
}

.vsg-sticky-top {
  /* position: v-bind(isSticky ? 'sticky' : 'initial') */
  top: v-bind(headerCalc) !important;
}

.cm-editor {
  min-height: 100%;
  max-height: 500px;
}
input,
textarea {
  @apply block w-full rounded border border-gray-300 bg-gray-50 p-1 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500;
}
select {
  @apply block w-full rounded border border-gray-300 bg-gray-50 p-1 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500;
}
</style>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
