import config from "../../../config";

export function useDetails() {
  const getDetailsData = async params => {
    console.log('getDetailsData')
    const domain = params.domain.split('|')
    let resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: { ...params, domainEnd: `${domain[1]}|${domain[1]}`, domainEvol: params.domain },
        query: `query DetailsQuery(
          $isinShare: String!
          $asof: Date
          $domainEnd: Domain!
          $domainEvol: Domain!
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              asof
              axis_of_analysis
              slug
              benchmark {
                id
                label
              }
              allocEnd: allocation(domain: $domainEnd) {
                date
                asof
                fuid
                name
                axis
                weight_fund
                weight_benchmark
                weight_diff_benchmark
                weight_active_share_benchmark
                quantity
                price
              }
              allocEvol: allocation(domain: $domainEvol) {
                fuid
                price_diff: price
                axis
              }
              contribution(domain: $domainEvol) {
                fuid
                name
                contribution_fund
                contribution_benchmark
                pnlOrFeesPerFund_fund
                pnlOrFeesPerFund_benchmark
                contribution_diff_benchmark
                axis
              }
            }
          }
        }`,
      },
    })
    const newShare = resp.data.data.businessData.share
    const alloc = newShare.allocEvol.reduce((acc, a) => {
      acc[a.fuid] = {
        ...a,
        ...newShare.allocEnd.find(el => el.fuid === a.fuid),
        ...newShare.contribution.find(el => el.fuid === a.fuid),
      }
      // the evol query returns price_diff which is the diffrence between starting price and ending price
      // we calculate price_change which is the percentage of the evolution
      acc[a.fuid].price_change = acc[a.fuid].price_diff / (acc[a.fuid].price - acc[a.fuid].price_diff)
      return acc
    }, {})
    newShare.allocation = Object.values(alloc)
    delete newShare.allocEnd
    delete newShare.allocEvol
    delete newShare.contribution
    //! should i keep ? could be useful ?
    return { ...resp.data.data.businessData, share: newShare }
  }
  const getTimeSeries = async params => {
    console.log('getTimeSeries')
    let resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query TimeSeriesQuery(
          $domain: Domain!
          $isinShare: String!
          $asof: Date
          $fuid: String!
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              timeSeries {
                priceSeries(domain: $domain, fuid: $fuid) {
                  date
                  price
                  quantity
                }
                weightSeries(domain: $domain, fuid: $fuid) {
                  date
                  weight_fund
                  weight_benchmark
                }
                contributionSeries(domain: $domain, fuid: $fuid) {
                  date
                  contribution_fund
                  contribution_benchmark
                  pnlOrFeesPerFund_fund
                  pnlOrFeesPerFund_benchmark
                }
              }
            }
          }
        }
        `,
      },
    })
    return resp.data.data.businessData.share.timeSeries
  }
  return {
    getDetailsData,
    getTimeSeries,
  }
}
