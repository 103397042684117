import config from "../../../config";

export function useAllocation() {
  const getAllocationData = async params => {
    console.log('getAllocationData')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query AllocationQuery(
          $isinShare: String!
          $asof: Date
          $domain: Domain!
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              ${$root.config.characteristicsSchema || ''}
              asof
              axis_of_analysis
              slug
              benchmark {
                id
                label
              }
              allocation(domain: $domain) {
                date
                fundId
                shareId
                name
                asset_class
                asset_currency
                quantity
                price
                fixing
                valuation
                fuid
                asof
                date_d_echeance
                date_d_echeance_with_call
                weight_fund
                weight_benchmark
                weight_diff_benchmark
                weight_active_share_benchmark
                axis
                exposure_fund
                contrib_sensi_fund
                contrib_ytm_fund
                contrib_deltaaction_fund
                contrib_spreadobl_fund
                exposure_benchmark
                contrib_sensi_benchmark
                contrib_ytm_benchmark
                contrib_deltaaction_benchmark
                contrib_spreadobl_benchmark
              }
            }
          }
        }`,
      },
    })
    return resp.data.data.businessData
  }
  return {
    getAllocationData,
  }
}
