<template>
  <lgi-block :data="data" :options="options">
    <table class="table-auto bottom-0 h-full w-full border-separate border-spacing-[2px]" v-if="data.constructor === Array">
      <tr :style="{ 'background': options.layout.theme.primary }">
        <td class="font-bold" v-html="options.data.attribution.table_title"></td>
        <td colspan="4" class="text-center font-bold">Port</td>
      </tr>
      <tr
        :class="{
          'bg-yellow-50': highlight_yellow.includes(line[0]),
          'bg-blue-50': highlight_blue.includes(line[0]),
        }"
        class="first:font-bold"
        v-for="line in data.slice(0, options.limit || Infinity)"
      >
        <td
          :class="{
            'text-red-600': parseFloat(cell) < 0,
            'indent-4': i == 0 && indent_4.includes(line[0]),
            'indent-6': i == 0 && indent_6.includes(line[0]),
            'indent-8': i == 0 && indent_8.includes(line[0]),
          }"
          class="first:font-bold first:w- first:text-left px-2 py-0 whitespace-nowrap text-right"
          v-for="(cell, i) in line"
        >
          {{ typeof cell === 'number' ? format(cell, options.digit + options.unit) : cell }}
        </td>
      </tr>
    </table>
  </lgi-block>
</template>

<style scoped>
td {
  font-variant-numeric: tabular-nums;
}
tr:first-child {
  color: white;
}
.attribution-data table td:not(:first-child) {
  width: 180px;
}
</style>

<script setup>
import format from '../../format.js'
const { data, options } = defineProps(['data', 'options'])
const highlight_blue = ['Net Total']
const highlight_yellow = ['Expenses', 'Gross Total', 'Cash & Cash Equivalents', 'Fixed Income', 'Currency Forwards - Long', 'Currency Forwards - Short']
const indent_4 = ['Expenses', 'Gross Total']
const indent_6 = ['Cash & Cash Equivalents', 'Fixed Income', 'Currency Forwards - Long', 'Currency Forwards - Short']
const indent_8 = ['AUD', 'HKD', 'SGD', 'USD']
</script>

<script>
export default {
  api: {
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 1,
      attrs: {
        type: 'number',
      },
    },
    limit: {
      label: 'Limit',
      attrs: {
        type: 'number',
      },
    },
  },
}
</script>
