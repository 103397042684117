import { computed, ref } from 'vue'

export function useXfilter() {
  const _dimensions = ref()
  const _dataset = ref()
  const _axis = ref()

  const initXfilter = (dataset, dimensions, axis = 'weight') => {
    // allowedDimensions: Arr with dimensions expected in autocomplete (what you want filter)
    // dataSet: DataSet with following format: [obj, obj, obj]
    _dimensions.value = dimensions
    _dataset.value = dataset
    _axis.value = axis
  }

  const metric = computed(() =>
    $root.query.metric?.includes('benchmark') ? 'benchmark' : $root.query.metric ? $root.query.metric : 'fund',
  )
  const axis = computed(() => $root.query.axis || _axis.value)
  const axisFormat = computed(() => $root.config.formats[axis.value] || '.2%')
  const axisMetric = computed(() => `${axis.value}_${metric.value}`)

  const filteredDataset = computed(() => {
    const filters = $root.$route.query
    if (!filters) return _dataset.value
    let result = _dataset.value
    Object.keys(filters)
      .filter(d => _dimensions.value.includes(d)) //Prevent unwanted filter from dirty query
      .forEach(dimension => {
        const arrCtriteria = filters[dimension].split('|')
        result = result.filter(
          d => arrCtriteria.includes('' + d.axis?.[dimension]) || arrCtriteria.includes('' + d[dimension]),
        ) // force null to str
      })
    return result
  })

  const datasetByDimensions = dim => {
    const _goupingDimensions = _dimensions.value.filter(d => d !== dim)
    const allFilters = $root.$route.query.filter((d, k) => _goupingDimensions.includes(k))
    // const allFilters = this.$route.query.filter((d, k) => this.availableDimensions.includes(k))
    let filtereDataset = _dataset.value
    Object.keys(allFilters).forEach(dimension => {
      const arrCriteria = allFilters[dimension].split('|')
      filtereDataset = filtereDataset.filter(d => arrCriteria.includes('' + d.axis[dimension]))
    })
    const groupedData = filtereDataset
      .group(d =>
        ['date_d_echeance', 'date_d_echeance_with_call'].includes(dim) ? d.axis[dim].split('/')[2] : d.axis[dim],
      )
      .map(group =>
        group
          .map(el =>
            metric.value === 'diff'
              ? el[axisMetric.value] || el[`${axis.value}_fund`] - el[`${axis.value}_benchmark`]
              : el[axisMetric.value],
          )
          .sum(),
      )
    return groupedData
  }

  return {
    initXfilter,
    filteredDataset, //old xf.data
    datasetByDimensions,
    metric,
    axis,
    axisFormat,
    axisMetric,
  }
}
