<template>
  <!-- grid-header -->
  <div
    qa-grid-header
    class="sticky left-0 top-0 flex w-full"
    :class="[gridZIndex.header, gridTheme?.header?.container]"
  >
    <!-- freezed headers -->
    <div
      qa-freezed-headers
      class="relative grid"
      :class="[
        props.state.scroll.throttled.left > 0 && props.state.columns.visibleFreezed.length > 0
          ? gridTheme?.freezed?.scrolled
          : '',
        gridZIndex.header,
      ]"
      :style="{
        gridTemplateColumns: gridColumnsFreezed,
        gridTemplateRows: `repeat(${columnLevelsFreezed.length}, 30px)`,
      }"
    >
      <template v-for="(columnLevel, level) in columnLevelsFreezed" :key="level">
        <template v-for="(header, i) in columnLevel" :key="header.label">
          <nx-grid-header-column
            :state="props.state"
            :header="header"
            :level="level"
            :is-first-column="i === 0 && level <= firstColumnLevels"
            :is-first-column-level="i === 0"
            :is-last-column-level="false"
            :is-freezed="true"
          >
            <template v-if="$slots.header" #header="{ header }">
              <slot name="header" :header="header" />
            </template>
          </nx-grid-header-column>
        </template>
      </template>
      <!-- applies background border for freezed columns -->
      <div class="pointer-events-none absolute inset-0" :class="gridZIndex.header">
        <div
          v-if="props.state.columns.visibleFreezed.length > 0"
          class="absolute h-full"
          :class="gridTheme?.freezed?.initial"
          :style="{
            transform: `translateX(${
              props.state.columns.visibleFreezedWidth.value + props.state.nodes.maxLeftOffset - 1
            }px)`,
          }"
        ></div>
      </div>
    </div>
    <!-- unfreezed headers -->
    <div
      qa-unfreezed-headers
      class="grid"
      :style="{
        gridTemplateColumns: gridColumnsUnfreezed,
        gridTemplateRows: `repeat(${columnLevelsUnfreezed.length}, 30px)`,
        transform: `translate(${-props.state.scroll.throttled.left}px)`,
      }"
    >
      <template v-for="(columnLevel, level) in columnLevelsUnfreezed" :key="level">
        <template v-for="(header, i) in columnLevel" :key="header.label">
          <nx-grid-header-column
            :nx-header-column="i + state.columns.visibleFreezed.length + (state.columns.depth > 1 ? '-' + level : '')"
            :state="props.state"
            :header="header"
            :level="level"
            :is-first-column="i === 0 && level <= firstColumnLevels && props.state.columns.visibleFreezed.length === 0"
            :is-first-column-level="i === 0"
            :is-last-column-level="i === columnLevel.length - 1"
            :is-freezed="false"
          >
            <template v-if="$slots.header" #header="{ header }">
              <slot name="header" :header="header" />
            </template>
          </nx-grid-header-column>
        </template>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * @component Headers of grid, where each column may have sub-columns.
 * The header has a fixed height and a dynamic width, depending on the number and width of its columns.
 * The grid may have some columns on the left side (freezedColumns) that are fixed in position and not scrolled with the rest of the grid.
 */
import { IGridState, useTheme, gridZIndex } from '@hauru/common'
import { computed } from 'vue'

interface IProps {
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the grid among the types defined in the theme config
   */
  type?: string
  /*
   * The state of the grid
   */
  state: IGridState
}

const props = withDefaults(defineProps<IProps>(), {})

const themeConfig = useTheme()
const gridTheme = themeConfig.computedThemeType('grid', props)

const columnLevelsFreezed = computed(() => {
  return Array.from({ length: props.state.columns.depth }, (_, i) => [
    ...props.state.columns.yieldColumnsLevel(i, 0, props.state.columns.visibleFreezed.length),
  ])
})

const columnLevelsUnfreezed = computed(() => {
  return Array.from({ length: props.state.columns.depth }, (_, i) => [
    ...props.state.columns.yieldColumnsLevel(i, props.state.columns.visibleFreezed.length),
  ])
})

const firstColumnLevels = computed(() => {
  let i = 0

  let header = props.state.columns.headers.list?.find(header => header.show)
  if (!header) return 0

  while (header?.visibleOrDefaultCollapsed?.length) {
    header = header?.visibleOrDefaultCollapsed?.[0]
    i++
  }
  return i
})

const gridColumnsFreezed = computed(() => {
  return props.state.columns.visibleFreezed
    .map((column, index) => {
      return (
        column.width +
        (index === 0 ? props.state.nodes.maxLeftOffset : 0) +
        (index === props.state.columns.visibleAll.length - 1 ? props.state.nodes.maxRightOffset : 0) +
        'px'
      )
    })
    .join(' ')
})

const gridColumnsUnfreezed = computed(() => {
  const countFreezed = props.state.columns.visibleFreezed.length
  return props.state.columns.visibleUnfreezed
    .map((column, index) => {
      return (
        column.width +
        (index + countFreezed === 0 ? props.state.nodes.maxLeftOffset : 0) +
        (index + countFreezed === props.state.columns.visibleAll.length - 1 ? props.state.nodes.maxRightOffset : 0) +
        'px'
      )
    })
    .join(' ')
})
</script>
