<style scoped>
header {
  display: flex;
  justify-items: center;
}

header select {
  margin-left: auto;
  margin-right: 20px;
  max-width: 10rem;

  background: #fff no-repeat right 8px center / 20px var(--chevron-down) !important;

  border: 1px solid #bababa;
  box-sizing: border-box;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

header .autocomplete {
  background: #fff no-repeat right 8px center / 20px var(--chevron-down);

  border: 1px solid #bababa;
  box-sizing: border-box;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.main-content {
  height: calc(90vh - 17px); /* hack scroll on HDPI scrren maybe ? */
  margin: 8px;
  display: flex;
}

.main-content > * {
  margin: 16px;
  width: 50%;
  height: 100%;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.preview iframe {
  width: 100%;
  height: 100%;
}

.preview iframe.generated {
  width: 100%;
  height: 100%;
  box-shadow: var(--box-shadow);
}

.tab-list {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tab-item {
  width: 50%;
  min-width: max-content;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tab-item.selected-tab {
  box-shadow: 0px 5px 0px 0px var(--primary-light);
}

.tab-item:not(last-of-type) {
  border-right: 2px solid var(--inactive);
}

.tab-item:last-of-type {
  border-right: none;
}

.step-total {
  display: flex;
  justify-content: space-between;
  margin: 20px 13px 20px 10px;
}

.step-total h3 {
  font-weight: bold;
  max-width: 60%;
}

.step-list {
  overflow-y: scroll;
  min-height: 0;
}

.step {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: var(--primary-light);
  border-radius: 3px;
  margin: 2px 5px;
}

.step-actions {
  display: flex;
  justify-content: space-between;
}

.step-actions * {
  margin: 0 10px;
}
.step-actions a svg {
  width: 20px;
}

.svg-icon {
  cursor: pointer;
}

.accept-icon {
  fill: rgb(16, 147, 125);
}
.reject-icon {
  fill: rgb(202, 28, 57);
}
.rerun-icon {
  fill: var(--cat9);
}

.accept-icon:hover {
  fill: rgb(16, 147, 125);
}
.reject-icon:hover {
  fill: rgb(202, 28, 57);
}

.step-info {
  max-width: 75%;
  padding: 5px 12px 5px 10px;
}

.step.selected-step {
  background: var(--primary);
}

.comment {
  transition: height 0.3s ease-in-out;
  margin: 2px 5px 7px;
}

/* override default styles */
.comment.closed * {
  display: block;
  min-height: 0;
  height: 0;
  padding: 0;
  margin: 0;
}
.comment.closed .btns button {
  display: none;
}

.comment textarea {
  margin-top: 3px;
  max-width: 100%;
  min-height: 15ex;
  resize: none;
}

.btns {
  text-align: right;
}

.btns * {
  margin: 0 3px;
}

.action-title {
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.action-title .owner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 9px;
  font-weight: bold;
  background: var(--cat1);
  margin-left: 20px;
}

.action-text {
  font-style: italic;
}

.action-tags {
  font-style: italic;
  color: #676767;
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
header
  h1 {{ t.quick_actions }}
  select.right(
    :placeholder="t.select_campaign"
    @change="update_query({domain: $event.target.value === 'all' ? null : $event.target.value})"
  )
    option(default value="all") {{ t.all }}
    option(v-for="domain in available_domains") {{ domain }}
  autocomplete.right(
    :data="autocomplete_data"
    :options="{ placeholder: t.search, forced_filters: ['domain'] }"
    :modelValue="activated_filters"
    @update:modelValue="autocomplete_input")
.main-content
  .block
    .tab-list
      h2(
        :class="['tab-item', tab === 'validation' ?  'selected-tab' : '']"
        @click="switchTab('validation')"
      ) {{ t.validation_steps }}: {{ Object.keys(my_actions_data.validation).length }}
      h2(
        :class="['tab-item', tab === 'error' ?  'selected-tab' : '']"
        @click="switchTab('error')"
      ) {{ t.error_steps }}: {{ Object.keys(my_actions_data.error).length }}
      //- h2(
      //-   :class="['tab-item', tab === 'input' ?  'selected-tab' : '']"
      //-   @click="switchTab('input')"
      //- ) Input step: {{ Object.keys(my_actions_data.input).length }}
    .step-total
      template(v-if="Object.keys(my_actions_data[tab]).length > 0")
        h3 {{ tab.titleize() }}: {{ Object.keys(my_actions_data[tab]).length }} {{ t.doc_to_review }}
        .step-actions(v-if="tab === 'error'")
          span(@click.stop="rerun_all()" :title="t.rerun_all")
            svg-icon.rerun-icon(name="pt-icon-play")
        .step-actions(v-else)
          span(@click="onClick(-2, 'reject')" @tooltip="tooltip = $event" :title="t.reject")
            svg-icon.reject-icon(name="nx-cancel")
          span(@click="onClick(-2, 'accept')")
            svg-icon.accept-icon(name="nx-check" @tooltip="tooltip = $event" :title="t.accept")
      h3(v-else) {{ t.no_documents }}
    .comment(:class="(selected === -2 && commentOpen) ? '' : 'closed'")
      textarea(:placeholder="t.feedback")
      //- .btns
      //-   button(
      //-     :style="'background: var(--' + this.action + '-color); color: white; font-weight: bold'"
      //-     @click="submitAll()"
      //-   ) {{ action }}
      //-   button(@click="commentOpen = false") Cancel
    .step-list
      div(v-for="item in my_actions_data[tab]" :key="item.runId")
        .step(
          :class="selected === item.runId ? 'selected-step' : ''"
          @click="switchSelected(item.runId)"
        )
          .step-info
            .action-title {{ item.runId + ' : ' + item.action.name }}
              span.owner {{ initials(item.action.user) }}
            .action-text {{ item.action.instruction }}
            .action-tags {{ tags(item) }}
          .step-actions(v-if="tab === 'error'")
            span(@click.stop="rerun(item)" :title="t.rerun")
              svg-icon.rerun-icon(name="pt-icon-play")
          .step-actions(v-else)
            a(
              v-if="selected === item.runId && item.docs.length === 0"
              :href="pdfUrl"
              target="_blank"
              @tooltip="tooltip = $event"
              :title="t.new_tab"
            )
              svg-icon(name="nx-new-tab")
            span(@click.stop="submit('comment-' + item.runId, 'reject')" @tooltip="tooltip = $event" :title="t.reject")
              svg-icon.reject-icon(name="pt-icon-cross")
            span(@click.stop="submit('comment-' + item.runId, 'accept')" @tooltip="tooltip = $event" :title="t.accept")
              svg-icon.accept-icon(name="pt-icon-tick")
        .comment(:class="(selected === item.runId && commentOpen) ? '' : 'closed'")
          textarea(:placeholder="t.feedback" :ref="'comment-' + item.runId")
          //- .btns
          //-   button(
          //-     :style="'background: var(--' + action + '-color); color: white; font-wieght: bold'"
          //-     @click="submit('comment-' + item.runId)"
          //-   ) {{ action.titleize() }}
          //-   button(@click="commentOpen = false") Cancel
  .preview
    h2(v-if="selected < 0") {{ t.select_action }}
    iframe(v-if="selected >= 0 && docs.length > 0" :src="docs[0]")
    iframe.generated(v-if="selected >= 0 && docs.length == 0" :src="pdfUrl")
    //- h2(v-if="selected >= 0 && docs.length == 0") No PDF found
</template>

<script>
import { useRuns } from '../composables/useRuns'
import useAuth from '../../../features/auth'
import config from '../../../config'
import { useImpressions } from '../composables/useImpressions'
import { useConfig } from '../composables/useConfig'
import { useProgress } from '../composables/useProgress'
import { computed } from 'vue'
export const additions = { icon: 'ic_history', mixins: 'global' }
export default {
  setup() {
    const { runs, loaded: rLoaded } = useRuns()
    const { profile } = useAuth()
    const { impressions, loaded: iLoaded } = useImpressions()
    const { define_scope_fn, loaded: confLoaded } = useConfig()
    const { progress } = useProgress([rLoaded, iLoaded, confLoaded])
    // Initial loading
    const isLoading = computed(() => progress.value !== 1)
    return { runs, profile, impressions, define_scope_fn, isLoading }
  },
  data() {
    return {
      tab: 'validation',
      selected: -1, // -1: nothing, -2: everything, >= 0: id
      commentOpen: false,
      action: 'accept', // possible values: 'accept', 'reject', 'rerun' (in case of tab 'error')
    }
  },
  methods: {
    rerun_all() {
      for (let item of Object.values(this.my_actions_data.error)) {
        this.rerun(item)
      }
    },
    rerun(item) {
      this.rerun_step(item.run, item.action.id)
    },
    obj2param(obj) {
      return Object.entries(obj)
        .filter(([k, v]) => v)
        .map(([k, v]) => `${k}=${String(v)}`)
        .join('&')
    },
    tags({ run }) {
      const { fund_id, isin, date, language } = run.context
      return `${fund_id}-${isin}-${date}-${language}`
    },
    submit(commentRef, action) {
      if (this.tab === 'validation') {
        const step = this.my_actions_data.validation[this.selected]
        if (action === 'accept') this.validateStep(step.runId, step.run.logs.last().id)
        else if (action === 'reject') this.rejectStep(step.runId, step.run.logs.last().id)
        else throw 'Not Such Action for Validation: ' + action
      } else if (this.tab === 'input') {
        const comment = this.$refs[commentRef][0].value
      } else {
        // NOT REACHABLE
        throw 'No such step type : ' + tab
      }

      this.selected = -1
    },
    validateStep(runId, logId) {
      if ($root.query.action_id) update_query({ action_id: $root.query.action_id + 1 })
      set(['data', 'runs', runId, 'logs', logId, 'user'].join('.'), this.email)
      set(['data', 'runs', runId, 'logs', logId, 'status'].join('.'), 'success')
    },
    rejectStep(runId, logId) {
      set(['data', 'runs', runId, 'logs', logId, 'status'].join('.'), 'error')
    },
    switchTab(newTab) {
      this.tab = newTab
      this.selected = -1
      this.commentOpen = false
    },
    switchSelected(index) {
      if (this.tab === 'error') {
        this.selected = index
        this.commentOpen = false
        return
      }
      if (this.selected !== index) this.commentOpen = true
      else this.commentOpen = !this.commentOpen
      this.selected = index

      const commentEl = this.$refs['comment-' + index][0]
      const { run: selectedRun, action: selectedAction } = this.my_actions_data[this.tab][this.selected]
      let url = ''
      if (this.tab === 'input') {
        url = selectedAction.url
          .replace('[fund]', 'userflows.' + selectedRun.context.fund_id)
          .replace('[date]', selectedRun.domain.split('|').slice(-1)[0].slice(0, 7))
          .replace('[language]', selectedRun.language)
          .replace('comment', selectedRun.period === 'monthly' ? 'comment' : 'comment_' + selectedRun.period) // period_comments (global.js)
      } else {
        url = `userflows.${selectedRun.context.fund_id}.comment.${selectedRun.domain
          .split('|')
          .slice(-1)[0]
          .slice(0, 7)}.en`
      }
      const comment = this.parse(get(url))
      commentEl.value = comment
      console.log({ comment, url })
    },
    parse(comment = '') {
      if (!comment) return ''
      let doc = new DOMParser().parseFromString(`<div>${comment}</div>`, 'text/html')
      return doc.body.textContent || ''
    },
    find_email(run) {
      const {
        logs,
        context: { fund_id, isin },
      } = run
      const last_wf_email = run.workflow.actions[logs.last().action_id].user
      // if email doesnt exist in step, return owner of run
      if (!last_wf_email) return run.context.owner
      // email not alias, return it
      if (last_wf_email.includes('@')) return last_wf_email
      return last_wf_email
    },
    build_my_actions_data(user) {
      let data = this.filtered_production
        .filter(run => {
          const email = this.find_email(run)
          if (!email) return false
          return (
            !run.disabled &&
            run.logs.last().status === 'running' &&
            [$root.profile.email, $root.profile.allowed_mailing_list || []].flat().includes(email)
          )
        })
        .map(run => ({
          runId: run.id,
          run,
          action: run.workflow.actions[run.logs.last().action_id], // last action
          docs: this.getDocuments(run.id),
        }))

      let cancelled_steps = this.filtered_production
        .filter(
          run => run.logs.last().status === 'error' && run.context.owner === $root.profile.email, // is it email or it can be an alias ?
        )
        .map(run => ({
          runId: run.id,
          run,
          action: run.workflow.actions[run.logs.last().action_id], // last action
          docs: this.getDocuments(run.id),
        }))
      const results = {}
      // group by step type and runId (runId is unique per step)
      results.input = data
        .filter(item => item.action.type === 'user_input')
        .reduce((acc, cur) => {
          acc[cur.runId] = cur
          return acc
        }, {})
      results.validation = data
        .filter(item => item.action.type === 'user_validation')
        .reduce((acc, cur) => {
          acc[cur.runId] = cur
          return acc
        }, {})
      results.error = cancelled_steps.reduce((acc, cur) => {
        acc[cur.runId] = cur
        return acc
      }, {})

      return results
    },
    initials(email) {
      const username = (email && email.split('@')[0]) || ''
      return username
        .split('.')
        .map(word => word[0])
        .join('')
        .upper()
    },
    getDocuments(runId) {
      // TODO: Maybe we need to add the runs here for the autocomplete data
      const docs = this.impressions
      if (!docs) return []
      const results = Object.keys(docs)
        .filter(key => key.split('-')[0] == runId) // related indeces
        .map(index => docs[index]) // related impressions
        .map(doc => config.commandr + '/' + doc.alias) // related documents
      return results
    },
    autocomplete_input(event) {
      let event_array = event.map(v => [v.slice(0, v.indexOf('.')), v.slice(v.indexOf('.') + 1, v.length)])
      let grouped_events = event_array.group('0')
      let selected_filters = grouped_events.map(g => g.map('1').join('|'))
      let new_filter_selected = this.$route.query.filter((v, k) => !this.$route.query.keys().includes(k))
      let query = { ...selected_filters, ...new_filter_selected }
      this.$router.push({ query })
    },
    format_filter_data(name, value) {
      return name + '.' + value
    },
  },
  computed: {
    production() {
      return (this.runs || {})
        .v()
        .map(d => {
          if (!d || d.disabled) return
          const report = d.press
          if (!report) return
          if (!d.context) d.context = {}
          const workflow = d.workflow
          const workflow_name = workflow.name
          const sid = workflow.id + '|' + d.id
          const log = d.logs && d.logs.v().last()
          const status = log.status
          const last_action_id = log.action_id
          const step = last_action_id ? workflow.actions[last_action_id].name : 'N/A'

          let timeline = 'running_task'
          if (new Date(d.context.run_time) > new Date()) timeline = 'future_task'
          if (log.status === 'success' && log.action_id === workflow.actions.v().last().id) timeline = 'past_task'
          if (this.filtered_timelines.includes(timeline)) return
          return {
            ...d,
            ...d.context,
            report,
            sid,
            workflow,
            workflow_name,
            status,
            step,
            timeline,
            scope: this.define_scope_fn(report),
            assignee: log.action_id ? workflow.actions[log.action_id].user : 'N/A',
            //stamp: `${d.context.isin}-${d.context.language}-${d.context.domain.slice(0, 7)}`, //TODO remove it
            start_date: log.start,
            last_update_date:
              (
                d.logs
                  .filter(d => d && !['running'].includes(d.status))
                  .v()
                  .last() || {}
              ).end ||
              (
                d.logs
                  .filter(d => d && !['running'].includes(d.status))
                  .v()
                  .last() || {}
              ).start ||
              (
                d.logs
                  .filter(d => d && !['running'].includes(d.status))
                  .v()
                  .last() || {}
              ).killed,
          }
        })
        .filter()
    },
    filtered_production() {
      let production = this.production
      if (!$root.query.scheduled)
        production = production
          .group(d =>
            ['template', 'isin', 'language', 'domain', 'workflow']
              .map(c => (typeof d[c] === 'object' ? ['wid', d[c].id].join('-') : d[c]))
              .join('-'),
          )
          .map(v => v.last())
          .v()
      if ($root.query.scheduled === 'assignee')
        production = production.filter(r => r.workflow.actions[r.logs.last().action_id].user === $root.profile.email)
      if ($root.query.scheduled === 'owner')
        production = production.filter(r => r.context.owner === $root.profile.email)
      if ($root.query.scheduled === 'month')
        production = production.filter(r => r.start_date >= new Date().minus('1 month').format())
      if ($root.query.scheduled === 'active')
        production = production.filter(
          r => !(r.workflow.actions.last().id === r.logs.last().action_id && r.logs.last().status === 'success'),
        )

      const filters = Object.entries($root.query)
        .filter(([k, v]) => !['search', 'selected', 'scheduled', 'year'].includes(k))
        .map(([k, v]) => [k, v.split('|')])
      return production.filter(d =>
        filters.every(([k, vs]) =>
          vs.some(v => {
            return (
              (d[k] && typeof d[k] === 'string' && d[k].replace('.', '') == v) ||
              (/^>/.test(v) && d[k] > v.slice(1)) ||
              (/^</.test(v) && d[k] < v.slice(1))
            )
          }),
        ),
      )
    },
    my_actions_data() {
      return this.build_my_actions_data(this.email)
    },
    available_domains() {
      return this.runs
        .v()
        .filter(r => r && !r.disabled)
        .map(run => run.context.domain)
        .unique()
        .sort()
    },
    email() {
      return this.profile.mailing_list ?? this.profile.email
    },
    docs() {
      if (!this.my_actions_data[this.tab][this.selected]) return []
      return this.my_actions_data[this.tab][this.selected].docs
    },
    pdfUrl() {
      const step = this.my_actions_data[this.tab][this.selected]
      if (!step || step.docs.length > 0) return '#'
      const { run } = step
      const userflow = run.context.fund_id + '-' + run.context.isin
      const template = run.context.template

      const paramsFactsheet = this.obj2param({
        asof: run.context.asof, // TODO: verify that we need this
        domain: run.context.domain,
        lang: run.context.language,
        app: 'default',
        cache: false,
      })
      return `${location.origin}/digital-edition/${userflow}/${template}?${paramsFactsheet}`
    },
    autocomplete_data() {
      let filters = ['fund_id', 'isin', 'language']
      let data = this.production.reduce((acc, v) => {
        filters.forEach(k => {
          if (typeof v[k] === 'string') {
            let filter_criteria = v[k].replace('.', '')
            acc[k] = acc[k] || {}
            acc[k][filter_criteria] = filter_criteria
          }
        })
        return acc
      }, {})
      return data
    },
    activated_filters() {
      let query_params = this.$route.query
      let active_filters = query_params.map(filter_value => filter_value.split('|'))
      const filters = []
      Object.entries(active_filters).forEach(([filter_name, filter_values]) => {
        if (filter_name === 'year') return
        filter_values.forEach(value => {
          filters.push(this.format_filter_data(filter_name, value))
        })
      })
      return filters
    },
  },
}
</script>
