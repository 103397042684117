import { useRoute } from 'vue-router'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { commandrSDK } from '../../../features/commandr'

export function useWorkflow() {
  const route = useRoute()
  const id = ref(+route.query.id)
  const actionId = ref(+route.query.action_id)

  const loaded = ref(false)
  const workflow = ref({
    name: 'Workflow',
    actions: { 1: { id: 1, name: 'Step', step: 'Group', type: '', duration: 3 } },
    id: id.value,
  })

  const onUpdate = snap => {
    workflow.value = snap
    loaded.value = true
  }

  watch(
    () => route.query.id,
    () => {
      commandrSDK.off(`/data/workflows/${id.value}`, 'value')
      id.value = +route.query.id
      commandrSDK.on(`/data/workflows/${id.value}`, 'value', onUpdate)
    },
  )
  watch(
    () => route.query.action_id,
    () => (actionId.value = +route.query.action_id),
  )

  onMounted(() => commandrSDK.on(`/data/workflows/${id.value}`, 'value', onUpdate))
  onUnmounted(() => commandrSDK.off(`/data/workflows/${id.value}`, 'value'))

  const action = computed(() => workflow.value?.actions[actionId.value] || {})
  return {
    workflow,
    id,
    action,
    actionId,
    loaded,
  }
}
