<template lang="pug">
section.builder(:class="{ expert: $root.query.expert, history, scroll }")
  section.builder-preview(@mousedown="page_activity" @scroll="scroll = $event.target.scrollTop > 20")
    page-generic.page

  section.code-panel(v-if="$route.query.expert")
    section.tabs
      .tab(:class="{ active: !$route.query.code }" @click="update_query({ code: null })") JADE
      .tab(:class="{ active: $route.query.code === 'css' }" @click="update_query({ code: 'css' })") CSS
      .tab(:class="{ active: $route.query.code === 'js' }" @click="update_query({ code: 'js' })") JS
    codemirror.jade(:value="jade_" :options="{ mode: 'pug' }" v-show="!$route.query.code")
    codemirror.css(:value="css_" :options="{ mode: 'css' }" v-show="$route.query.code === 'css'")
    codemirror.js(:value="js_" :options="{ mode: 'javascript' }" v-show="$route.query.code === 'js'")

  section.info-panel
    h1
      button.ghost(aria-label="back" style="margin: 0 20px 0 0;" @click="$router.push('/client-edition/templates')")
        svg-icon(style="transform: rotate(180deg);" name="nx-chevron")
      | {{ t[$root.params.screen] || $root.params.screen.titleize() }}
      //select(:value="$root.params.screen" @change="$router.push({ params: { screen: $event.target.value }})")
        option(:value="name" v-for="screen, name in $root.config.screens.__.filter(s => s.theme)") {{ t[name] || name.titleize() }}
      button.ghost(style="margin: 0 0 0 auto;" @click="update_query({ expert: $root.query.expert ? null : 'on' })") Developper Mode

  section.tool-panel.dark
    section.tabs
      .tab(:class="{ active: !$route.query.tab }" @click="update_query({ tab: null })") Theme
      .tab(:class="{ active: $route.query.tab === 'layout' }" @click="update_query({ tab: 'layout' })") Layout
      .tab(:class="{ active: $route.query.tab === 'content' }" @click="update_query({ tab: 'content' })") Content
    section(v-if="!$root.query.tab")
      label.field-url(style="pointer-events: none;") Assets
      label.field-color(style="pointer-events: none;") Color
      field(:value="v" :label="k" v-for="v, k in $root.cssvar.__.filter((v, k) => !cssignore.includes(k))" @input="field_save($event, k)")
      label.field-url
        input(style="flex: 1;margin-right: 10px;" v-model="field_new")
        button(style="margin-right: 20px;" @click="field_save('new')") ADD ASSET
      hr.field-url
      hr.field-color
    section(v-if="$root.query.tab === 'layout'")
      template(v-if="block")
        label Block
          select(:value="block.classList.contains('expand') ? 'Autosize' : 'Fit to content'" @change="brick_save({ class: 'expand' })")
            option Autosize
            option Fit to content
        label Size
          .size
            input(type="number" :value="block.style.maxWidth.replace('px', '')" :placeholder="block.getBoundingClientRect().width.toFixed()" @input="block.style.maxWidth = block.style.minWidth = $event.target.value ? $event.target.value + 'px' : null;resize()" @change="brick_save({ style: $event.target.value ? 'max-width: ' + $event.target.value + 'px;' + 'min-width: ' + $event.target.value + 'px;' : '' })")
            span x
            input(type="number" :value="block.style.maxHeight.replace('px', '')" :placeholder="block.getBoundingClientRect().height.toFixed()" @input="block.style.maxHeight = block.style.minHeight = $event.target.value ? $event.target.value + 'px' : null;resize()" @change="brick_save({ style: $event.target.value ? 'max-height: ' + $event.target.value + 'px;' + 'min-height: ' + $event.target.value + 'px;' : '' })")
        label.textarea Style
          textarea(:value="block.getAttribute('style')" @change="brick_save({ style: $event.target.value })")
        hr
        label Container
          select(:value="block.parentNode.classList.contains('expand') ? 'Autosize' : 'Fit to content'" @change="brick_save({ class: 'expand' }, true)")
            option Autosize
            option Fit to content
        label Size
          .size
            input(type="number" :value="block.parentNode.style.maxWidth.replace('px', '')" :placeholder="block.parentNode.getBoundingClientRect().width.toFixed()" @input="block.parentNode.style.maxWidth = block.parentNode.style.minWidth = $event.target.value ? $event.target.value + 'px' : null;resize()" @change="brick_save({ style: $event.target.value ? 'max-width: ' + $event.target.value + 'px;' + 'min-width: ' + $event.target.value + 'px;' : '' }, true)")
            span x
            input(type="number" :value="block.parentNode.style.maxHeight.replace('px', '')" :placeholder="block.parentNode.getBoundingClientRect().height.toFixed()" @input="block.parentNode.style.maxHeight = block.parentNode.style.minHeight = $event.target.value ? $event.target.value + 'px' : null;resize()" @change="brick_save({ style: $event.target.value ? 'max-height: ' + $event.target.value + 'px;' + 'min-height: ' + $event.target.value + 'px;' : '' }, true)")
        label.textarea Style
          textarea(:value="block.parentNode.getAttribute('style')" @change="brick_save({ style: $event.target.value }, true)")
    section(v-if="$root.query.tab === 'content'")
      div(style="overflow: auto;")
        label Lang
          select(:value="$root.lang" @change="$root.lang = $event.target.value")
            option(:value="lang" v-for="lang in $root.config.translation.__.keys()") {{ lang.toUpperCase() }}
        label ISIN
          select(:value="$root.params.userflow" @change="$root.$router.push({ params: { userflow: $event.target.value }, query: $root.query })")
            option(:value="userflow" v-for="userflow in $root.db.userflows.__.map((v, k) => v.shares.__.keys().__.map(s => [k, s].join('-'))).__.v().flat()") {{ userflow.split('-')[1] }}
        label Period
          input(:value="$root.query.domain" @change="update_query({ domain: $event.target.value })")
        hr
        template(v-if="block")
          label Data source
            select(:value="brick.data" @change="brick_save({ data: $event.target.value, title: titles[$event.target.value], type: types[$event.target.value] })")
              option
              option(v-for="source in sources") {{ source }}
          label Visualization
            select(:value="brick.type" @change="brick_save({ type: $event.target.value })" v-if="!/^block/.test(brick.data)")
              option
              option(:value="type" v-for="name, type in { table: 'Table', vbar: 'Bar Chart - Vertical', hbar: 'Bar Chart - Horizontal', pie: 'Pie Chart', line: 'Line Chart', ...(localStorage.PROJECT === 'moa' && { 'bar-percentile': 'Bar Percentile' }) }") {{ name }}
          label Title
            input(:value="brick.title" @change="brick_save({ title: $event.target.value })")
          label Subtitle
            input(:value="brick.subtitle" @change="brick_save({ subtitle: $event.target.value })")
          label Disclaimer
            input(:value="brick.disclaimer" @change="brick_save({ disclaimer: $event.target.value })")
          template(v-if="['table', 'line', 'hbar', 'vbar', 'pie'].includes(brick.type)")
            hr
            label Format
              input(:value="stringify(brick.options && brick.options.format)" @change="brick_save({ ':options': { format: $event.target.value }})")
            label Limit
              input(:value="brick.options && brick.options.limit" @change="brick_save({ ':options': { limit: +$event.target.value || null }})")
            label(v-if="brick.type == 'hbar'") Direction
              input(:value="stringify(brick.options && brick.options.direction)" @change="brick_save({ ':options': { direction: $event.target.value }})")
            label(v-if="brick.type == 'hbar'") Sort
              input(:value="stringify(brick.options && brick.options.sort)" @change="brick_save({ ':options': { sort: $event.target.value }})")
            label(v-if="brick.options && brick.options.limit") Show Others
              input(type="checkbox" :checked="brick.options && brick.options.others" @change="brick_save({ ':options': { others: $event.target.checked }})")
          template(v-if="brick.type === 'table'")
            hr
            label Hide Header
              input(type="checkbox" :checked="brick.options && brick.options.hide" @change="brick_save({ ':options': { hide: $event.target.checked }})")
            //label Columns
              autocomplete(:data="brick.data__[0].__.keys()" :value="brick.options && brick.options.columns || []" @input="brick_save({ ':options': { columns: $event }})")
            //label Rows
              input(:value="stringify(brick.options && brick.options.rows)" @change="brick_save({ ':options': { rows: $event.target.value }})")
            //label Group
              autocomplete(:data="brick.data__[0].__.keys()" :value="brick.options && brick.options.group || []" @input="brick_save({ ':options': { group: $event }})")
            //label(v-if="brick.options && brick.options.group") Map
              input(:value="stringify(brick.options && brick.options.__.map !== ({}).__.map && brick.options.__.map || 'first')" @change="brick_save({ ':options': { map: $event.target.value }})")
            //label Sort
              autocomplete(:data="brick.data__[0].__.keys()" :value="brick.options && brick.options.sort || []" @input="brick_save({ ':options': { sort: $event }})")
            label Transpose
              input(type="checkbox" :checked="brick.options && brick.options.transpose" @change="brick_save({ ':options': { transpose: $event.target.checked }})")
    hr
    a(style="margin-top: auto;text-align: center;color: var(--colors-primary-default);text-decoration: underline;" @click="($root.db.table[brick.data.replace('nxpack.table.', '')] || $root.db.dataset[brick.data.replace('nxpack.dataset.', '')]).dlCSV()" v-if="brick && brick.data && /nxpack\.(dataset|table)/.test(brick.data)") Raw Data
    a(style="margin-top: auto;text-align: center;color: var(--colors-primary-default);text-decoration: underline;" href="/advanced/translations" target="_blank") Translations
    button.ghost(@click="undo = []" v-if="undo.length") Clear {{ undo.length }} local changes
    button(@click="commit") Publish this version
    button.ghost(@click="history = !history") Check past versions
    section.version-panel.dark
      h2 Past versions
        button.ghost(aria-label="Close")
          svg-icon(style="pointer-events: all;transform: rotate(45deg);" name="nx-plus" @click.native="history = !history")
      .row.center(v-for="ts in (($root.config.versions || {})[$root.params.screen] || {}).__.keys().sort(d => -d)")
        | {{ new Date(+ts).format('day, mon, year, hour, minute', 'en') }}
        .buttons
          button.ghost(aria-label="View" tt="View" @click="checkout(ts)")
            svg-icon(name="ic_remove_red_eye")
          button.ghost(aria-label="Remove" tt="Remove" @click="remove_version(ts)")
            svg-icon(name="pt-icon-trash")
</template>

<script>
import PageGeneric from './generic.vue'
export default {
  components: { PageGeneric },
  async beforeRouteEnter(to, from, next) {
    await inject([
      'https://platform.100m.io/dist/codemirror/codemirror.js',
      'https://platform.100m.io/dist/codemirror/codemirror.css',
      [
        'https://platform.100m.io/dist/codemirror/monokai.css',
        'https://platform.100m.io/dist/codemirror/sublime.js',
        'https://platform.100m.io/dist/codemirror/overlay.js',
        'https://platform.100m.io/dist/codemirror/markdown.js',
        'https://platform.100m.io/dist/codemirror/gfm.js',
        'https://platform.100m.io/dist/codemirror/yaml.js',
        'https://platform.100m.io/dist/codemirror/css.js',
        'https://platform.100m.io/dist/codemirror/javascript.js',
        'https://platform.100m.io/dist/codemirror/pug.js',
        'https://platform.100m.io/dist/codemirror/active-line.js',
        'https://platform.100m.io/dist/codemirror/search.js',
        'https://platform.100m.io/dist/codemirror/searchcursor.js',
        'https://platform.100m.io/dist/codemirror/foldgutter.css',
        'https://platform.100m.io/dist/codemirror/brace-fold.js',
        'https://platform.100m.io/dist/codemirror/comment-fold.js',
        'https://platform.100m.io/dist/codemirror/foldcode.js',
        'https://platform.100m.io/dist/codemirror/foldgutter.js',
        'https://platform.100m.io/dist/codemirror/indent-fold.js',
      ],
      'https://platform.100m.io/dist/codemirror/js-yaml.min.js',
      'https://platform.100m.io/dist/codemirror/he.min.js',
      ['https://platform.100m.io/dist/codemirror/html-to-jade.js'],
      'https://platform.100m.io/dist/codemirror/pug-lang.js',
      // 'https://platform.100m.io/dist/codemirror/lightning-fs.js',
      // 'https://platform.100m.io/dist/codemirror/isomorphic-git.js',
      'https://platform.100m.io/dist/codemirror/diff_match_patch.js',
    ])
    Function("window.pug = require('pug')")()
    next()
  },
  async created() {
    // idbuilder.get('undo.' + $root.params.screen).then(r => this.undo = r || [])
    // this.$watch(() => [this.undo, this.redo], () => {
    //   idbuilder.set('undo.' + $root.params.screen, this.undo)
    //   if (!this.undo.length) return $root.screen = { ...this.initial }
    //   this.undo.last().__.map((v, k) => $root.screen[k] = v)
    // })

    addEventListener('keydown', e => {
      if (e.key.toLowerCase() === 'z' && (e.metaKey || e.ctrlKey) && e.shiftKey && e.target.tagName !== 'TEXTAREA') {
        e.preventDefault()
        e.stopPropagation()
        if (!this.redo.length) return
        this.undo = this.undo.concat(this.redo.last())
        this.redo = this.redo.slice(0, -1)
        return
      }
      if (e.key.toLowerCase() === 'z' && (e.metaKey || e.ctrlKey) && e.target.tagName !== 'TEXTAREA') {
        e.preventDefault()
        e.stopPropagation()
        if (!this.undo.length) return
        this.redo = this.redo.concat(this.undo.last())
        this.undo = this.undo.slice(0, -1)
        return
      }
      if (e.key.toLowerCase() === 's' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        try {
          const tab = $root.query.code || 'jade'
          const code = $('.vue-codemirror.' + tab)
            .__vue__.cminstance.getValue()
            .replace(/\t/g, '  ')
          if (tab === 'jade') {
            return local_save('template', jade2html(code))
          }
          if (tab === 'css') {
            return local_save('style', code)
          }
          if (tab === 'js') {
            eval('(' + code + ')')
            return local_save('mixin', code)
          }
        } catch (e) {
          console.error(e)
        }
      }
    })
  },
  data() {
    window.get_direction = (el, e) => {
      const r = el.getBoundingClientRect()
      return [
        { k: 'top', v: Math.abs(e.pageY - r.top) / r.height },
        { k: 'bottom', v: Math.abs(e.pageY - r.bottom) / r.height },
        { k: 'left', v: Math.abs(e.pageX - r.left) / r.width },
        { k: 'right', v: Math.abs(e.pageX - r.right) / r.width },
      ].min('v').k
    }
    window.get_distance = e => {
      const r = e.target.getBoundingClientRect()
      return {
        top: Math.abs(e.pageY - r.top),
        bottom: Math.abs(e.pageY - r.bottom),
        left: Math.abs(e.pageX - r.left),
        right: Math.abs(e.pageX - r.right),
      }
    }
    window.get_block = el => {
      while (el.parentNode !== document.body) {
        if (/block(\s|$)+/.test(el.className)) return el
        el = el.parentNode
      }
      return null
    }
    window.jade2html = jade => {
      // HACK pug does not like Object .v
      let html
      delete Object.prototype.v
      try {
        html = pug.render(jade)
      } catch (e) {
        console.error(e)
      }
      Object.extend()
      return html
    }
    window.stringify = v => {
      try {
        v = eval(v)
      } catch (e) {}
      if (typeof v === 'boolean') return '' + v
      if (!v || v.length === 0) return null
      if (typeof v === 'function') return v.toString()
      if (Array.isArray(v)) return '[' + v.__.map(stringify).__.filter().join(', ') + ']'
      if (typeof v === 'object')
        return (
          '{ ' +
          Object.map(v.__.filter(), (v, k) => `${k}: ${stringify(v)}`)
            .__.v()
            .join(', ') +
          ' }'
        )
      if (typeof v === 'string') return `'${v.replace(/'/g, '\\"')}'`
      return `'${v}'`
    }
    window.local_save = (key, value) => {
      this.undo = this.undo.concat(
        { ...$root.screen, [key]: value }.__.filter((v, k) => ['template', 'style', 'mixin'].includes(k)),
      )
      setTimeout(() => (this.block = $$('.block')[block_index]), 100)
      setTimeout(this.refresh, 100)
    }
    window.template_save = template => {
      /* HACK */ Array(3)
        .fill()
        .__.map(() => {
          $$('.row, .column', template).__.map(el => {
            // if (el.children.length === 1 || el.className === el.parentNode.className) return el.outerHTML = el.innerHTML
            if (el.children.length === 0) return el.remove()
          })
        })
      return local_save('template', template.body.innerHTML)
    }
    setTimeout(this.refresh, 0)
    /* HACK */ setTimeout(() => ($root.screen = { style: '', ...$root.screen }), 100)
    return {
      history: false,
      scroll: false,
      versions: [],
      // initial: { style: '', ...$root.config.screens[$root.params.screen] },
      undo: [],
      redo: [],
      stringify,
      local_save,
      template_save,
      commit: () => {
        const ts = Date.now()
        const children = $root.config.screens.__.v().__.filter(s => s.parent === $root.params.screen)
        if (children.length) {
          if (!confirm(children.length + ' child templates will be affected.')) return
          const prev = $root.config.screens[$root.params.screen]
          const next = $root.screen
          const dmp = new diff_match_patch()
          // dmp.Patch_DeleteThreshold = 0
          // dmp.Patch_Margin = 0
          // dmp.Match_Threshold = 0
          const patch_template = dmp.patch_make(prev.template || '', next.template || '')
          const patch_style = dmp.patch_make(prev.style || '', next.style || '')
          const patch_mixin = dmp.patch_make(prev.mixin || '', next.mixin || '')
          children.__.map(c => {
            c.template = dmp.patch_apply(patch_template, c.template)[0]
            c.style = dmp.patch_apply(patch_style, c.style)[0]
            c.mixin = dmp.patch_apply(patch_mixin, c.mixin)[0]
            set('config.versions.' + c.path + '.' + ts, c)
            set('config.screens.' + c.path, c)
            // idbuilder.set('undo.' + c.path, [])
          })
        }
        set(
          'config.versions.' + $root.params.screen + '.' + ts,
          Object.assign($root.screen.__.filter(), { builder: true }),
        )
        set('config.screens.' + $root.params.screen, $root.screen.__.filter())
        this.undo = []
        $root.toast({ description: 'published_version', type: 'success', timeout: 5000 })
      },
      checkout: ts => {
        const v = $root.config.versions[$root.params.screen][ts]
        this.undo = this.undo.concat(v)
        this.history = false
        $root.toast({ description: 'retrieved_version', type: 'success', timeout: 5000 })
      },
      remove_version: ts => {
        if (confirm($root.t['delete_confirmation']))
          return set('config.versions.' + $root.params.screen + '.' + ts, null)
      },
      jade_: '',
      css_: '',
      js_: '',
      cssignore: [
        'primary-dark',
        'primary-light',
        'primary-rgb',
        'selection',
        'selection-light',
        'inactive',
        'border',
        'border-radius',
        'box-shadow',
        'transition',
        'standard-curve',
        'h1',
        'h2',
        'h3',
        'p1',
        'p2',
        'p3',
        'p4',
        'highlight',
        'background',
        'text-dark',
        'negative',
        'positive',
        'cat1',
        'cat2',
        'cat3',
        'cat4',
        'cat5',
        'cat6',
        'cat7',
        'cat8',
        'cat9',
        'cat10',
        'nav',
        'menu-top',
        'menu-left',
        'icon',
        'text',
        'chevron-down',
        'active',
        'hover',
        'input',
        'blue1',
        'blue2',
        'blue3',
        'gray02',
        'gray05',
        'gray1',
        'gray2',
        'gray3',
        'shadow',
        'calendar',
        'chevron-down',
        'external-link',
        'link',
        'lock',
        'mail',
        'phone',
        'search',
        'nav-top-background',
        'nav-top-color',
        'nav-top-calendars',
        'nav-top-shadow',
        'nav-bottom-background',
        'nav-bottom-background-accent',
        'nav-bottom-color',
        'nav-bottom-icon-fill',
        'nav-bottom-hover',
        'nav-bottom-active-background',
        'nav-bottom-active-color',
        'nav-bottom-menu-height',
        'nav-bottom-account-margin',
        'nav-retract-button-background',
        'nav-retract-button-svg',
        'nav-top-calendars-border',
      ],
      field_new: '',
      block: null,
    }
  },
  computed: {
    brick() {
      if (!this.block || !this.block.__vueParentComponent) return
      const component =
        this.block.__vueParentComponent.parent.vnode.el === this.block.__vueParentComponent.vnode.el
          ? this.block.__vueParentComponent.parent
          : this.block.__vueParentComponent
      return component.ctx
    },
    sources() {
      try {
        const dimensions = [$root.userflow.dimensions || [], $root.screen.dimensions || []].flat().unique()
        const computed_mixins = (
          $root.screen.mixins.__.map(d => eval('(' + ($root.config.mixins[d] || '{}') + ')').computed) || {}
        ).concat(eval('(' + ($root.screen.mixin || '{}') + ')').computed || {})
        const computed = computed_mixins.__.reduce((acc, k) => {
          acc = Object.assign(acc, k)
          return acc
        }, {})
        if ($root.db.data_report) return $root.db.data_report.__.keys().__.map(k => 'data_report.' + k)
        return (
          []
            .concat([...Array(10).__.keys()].__.map(k => 'allocation_dimension_pdf.' + k))
            .concat(dimensions.__.map(k => 'allocation.' + k))
            .concat(
              ($root.screen.group &&
                $root.screen.group.__.keys() &&
                $root.screen.group.__.keys()
                  .__.map(g => dimensions.__.map(k => 'allocation.' + g + '_' + k))
                  .flat(Infinity)) ||
                [],
            )
            .concat(dimensions.__.map(k => 'contribution.' + k))
            .concat(computed.__.keys().__.map(k => 'computed.' + k))
            .concat(
              $root.db.config.components.__.keys()
                .__.filter(v => v.startsWith('pdf-') && !['pdf-bar', 'pdf-image', 'pdf-table', 'pdf-text'].includes(v))
                .__.map(v => v.replace('pdf-', 'block.')),
            )
            .concat(['nxpack.orientation', 'nxpack.comment'])
            .concat(($root.db.dataset || {}).__.keys().__.map(k => 'nxpack.dataset.' + k))
            .concat(($root.db.table || {}).__.keys().__.map(k => 'nxpack.table.' + k))
            .concat(($root.config.table || {}).__.keys().__.map(k => 'nxpack.table.' + k))
            .concat(
              $root.config.translation.en.__.keys()
                .__.filter(k => k.startsWith('disclaimer'))
                .__.map(k => 'translation.' + k),
            )
            // .concat($root.cssvar.__.filter((v, k) => !this.cssignore.includes(k) && v.startsWith('url(')).__.keys().__.map(k => 'theme.' + k))
            .unique()
            .sort()
        )
      } catch (e) {
        return []
      }
    },
    titles() {
      return Object.fromEntries(
        this.sources.__.map(k => [
          k,
          k
            .replace(/^(allocation|contribution)\./, '$1,by,')
            .replace(/^(data_report|nxpack|computed)\.(dataset\.|table\.)?/, '')
            .replace(/block\.(.*)/, (m, s) => s.join('_')),
        ]),
      )
    },
    types() {
      return Object.fromEntries(
        this.sources.__.map(k => {
          if (/(translation)\./.test(k) || ['nxpack.orientation', 'nxpack.comment'].includes(k)) return [k, 'text']
          if (/(nxpack)\./.test(k)) return [k, 'table']
          if (/(allocation|contribution)\./.test(k)) return [k, 'hbar']
          if (/(block)\./.test(k)) return [k, '']
          return [k, 'text']
        }),
      )
    },
  },
  methods: {
    activate_drag() {
      window.drag = window.drop = window.drop_direction = window.drop_container = null
      $$('.pdf-page .block, .pdf-page .row, .pdf-page .column').__.map((el, i) => {
        $root.query.tab === 'layout' ? el.setAttribute('draggable', 'true') : el.removeAttribute('draggable')
        const clean = () => {
          if (!drop) return
          drop.classList.remove('drag-top')
          drop.classList.remove('drag-bottom')
          drop.classList.remove('drag-left')
          drop.classList.remove('drag-right')
        }
        el.ondragstart = e => {
          e.dataTransfer.setData('text/plain', null) // Firefox: https://stackoverflow.com/questions/3977596/how-to-make-divs-in-html5-draggable-for-firefox
          $$('[contenteditable]').__.map(el => el.removeAttribute('contenteditable')) // Firefox: preventDefault ondrop do not work, remove property works
        }
        el.ondragover = (e => {
          clean()
          drop = e.target
          drop_direction = get_direction(drop, e)
          drop_container = drop.parentNode && /row/.test(drop.parentNode.className) ? 'row' : 'column'
          drop.classList.add('drag-' + drop_direction)
        }).throttle(100)
        el.ondragend = e => {
          clean()
          drag = el
          const template = new DOMParser().parseFromString($root.screen.template, 'text/html')
          const temp = (el, indexes = []) => {
            if (el.className === 'pdf page') return indexes.__.reduce((acc, v) => acc.children[v], template.body)
            return temp(
              el.parentNode,
              [Array.from(el.parentNode.children).__.__.findIndex(e => e === el)].concat(indexes),
            )
          }
          if (!drag || !drop) return
          const drag_template_block = temp(drag)
          const drop_template_block = temp(drop)
          if (drop_container === 'column' && drop_direction === 'top')
            drop_template_block.insertAdjacentElement('beforebegin', drag_template_block)
          if (drop_container === 'column' && drop_direction === 'bottom')
            drop_template_block.insertAdjacentElement('afterend', drag_template_block)
          if (drop_container === 'column' && drop_direction === 'left') {
            const container = new DOMParser()
              .parseFromString('<div class="row expand"></div>', 'text/html')
              .querySelector('div')
            drop_template_block.insertAdjacentElement('beforebegin', container)
            container.appendChild(drag_template_block)
            container.appendChild(drop_template_block)
          }
          if (drop_container === 'column' && drop_direction === 'right') {
            const container = new DOMParser()
              .parseFromString('<div class="row expand"></div>', 'text/html')
              .querySelector('div')
            drop_template_block.insertAdjacentElement('beforebegin', container)
            container.appendChild(drop_template_block)
            container.appendChild(drag_template_block)
          }
          if (drop_container === 'row' && drop_direction === 'left')
            drop_template_block.insertAdjacentElement('beforebegin', drag_template_block)
          if (drop_container === 'row' && drop_direction === 'right')
            drop_template_block.insertAdjacentElement('afterend', drag_template_block)
          if (drop_container === 'row' && drop_direction === 'top') {
            const container = new DOMParser()
              .parseFromString('<div class="column expand"></div>', 'text/html')
              .querySelector('div')
            drop_template_block.insertAdjacentElement('beforebegin', container)
            container.appendChild(drag_template_block)
            container.appendChild(drop_template_block)
          }
          if (drop_container === 'row' && drop_direction === 'bottom') {
            const container = new DOMParser()
              .parseFromString('<div class="column expand"></div>', 'text/html')
              .querySelector('div')
            drop_template_block.insertAdjacentElement('beforebegin', container)
            container.appendChild(drop_template_block)
            container.appendChild(drag_template_block)
          }
          return template_save(template)
        }
      })
    },
    page_activity($event) {
      if ($root.query.tab === 'layout' && $event.target.className.includes('pdf-page')) {
        const distance = get_distance($event)
        const template = new DOMParser().parseFromString($root.screen.template, 'text/html')
        const page = $$('.pdf-page', template)[$$('.pdf-page').__.__.findIndex(el => el === $event.target)]
        if (distance.top < 20 && distance.right < 20) {
          if ($$('.pdf-page', template).length === 1) return console.log('Last page')
          page.remove()
          return template_save(template)
        }
        if (distance.bottom < 20 && distance.right < 20) {
          page.insertAdjacentElement(
            'afterend',
            new DOMParser().parseFromString($root.screen.theme, 'text/html').querySelector('.pdf-page'),
          )
          // $$('brick', template).__.filter(el => !el.getAttribute('type') && !el.getAttribute('title') && !el.getAttribute(':title')).__.map(el => el.setAttribute('title', `block_${$root.params.screen}_${$$('brick', template).__.map(el => +el.title.split('_').last()).max() + 1}`))
          return template_save(template)
        }
        return
      }
      if (
        $root.query.tab === 'layout' &&
        $event.target.className.includes('block') &&
        $event.target.hasAttribute('selected')
      ) {
        const distance = get_distance($event)
        const container = /row/.test($event.target.parentNode.className) ? 'row' : 'column'
        const template = new DOMParser().parseFromString($root.screen.template, 'text/html')
        const block = $$('brick', template)[block_index]
        if (
          (container === 'column' && distance.top < 20 && distance.right < 20) ||
          (container === 'row' && distance.top < 20 && distance.left < 20)
        ) {
          if (
            $$('.pdf-page', template)
              .__.find(el => el.contains(block))
              .querySelectorAll('brick').length === 1
          )
            return console.log('Last block')
          block.remove()
          $$('.row, .column', template).__.map(el => el.children.length === 0 && el.remove())
          block_index--
          return template_save(template)
        }
        if (
          (container === 'column' && distance.bottom < 20 && distance.right < 20) ||
          (container === 'row' && distance.top < 20 && distance.right < 20)
        ) {
          block.insertAdjacentElement(
            'afterend',
            new DOMParser().parseFromString(`<brick class="expand"></brick>`, 'text/html').querySelector('brick'),
          )
          // $$('brick', template).__.filter(el => !el.getAttribute('type') && !el.getAttribute('title') && !el.getAttribute(':title')).__.map(el => el.setAttribute('title', `block_${$root.params.screen}_${$$('brick', template).__.map(el => +el.title.split('_').last()).max() + 1}`))
          block_index++
          return template_save(template)
        }
      }
      this.block = get_block($event.target)
    },
    brick_save(attributes, parent) {
      const template = new DOMParser().parseFromString($root.screen.template, 'text/html')
      let el = $$('brick', template)[block_index]
      if (parent) el = el.parentNode
      attributes.__.map((v, k) => {
        if (k === 'class') return el.setAttribute(k, (el.getAttribute(k) || '').split(' ').toggle(v).join(' '))
        if (k === ':options') {
          v = v.__.map(v => {
            try {
              return eval('(' + v + ')')
            } catch (e) {
              return v
            }
          })
          return el.setAttribute(k, stringify(Object.assign(eval('(' + (el.getAttribute(k) || '{}') + ')'), v)))
        }
        el.setAttribute(k, v)
      })
      return template_save(template)
    },
    field_save($event, k) {
      const base = ($root.screen.style || '').replace(new RegExp(`.pdf { --${k}: .+ }\n`, 'g'), '').trim()
      if (typeof $event === 'string' && $event === 'new')
        return local_save(
          'style',
          `${base}\n.pdf { --${this.field_new.toLowerCase()}: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=); }`.trim(),
        )
      if (typeof $event === 'string') return local_save('style', `${base}\n.pdf { --${k}: ${$event}; }`.trim())
      return local_save('style', `${base}\n.pdf { --${k}: ${$event.target.value}; }`.trim())
    },
    download_source() {
      console.log('download source....')
      console.log(
        Object.entries(this.brick.$children[0].data)
          .__.map(d => ({ id: d[0], ...d[1] }))
          .toCSV(),
      )
      return ''
    },
    refresh() {
      Html2Jade.convertHtml(
        $root.screen.template.replace(/<template/g, '<tttemplate'),
        { bodyless: true, donotencode: true, double: true, noattrcomma: true, noemptypipe: true },
        (err, jade) => (this.jade_ = jade.replace(/^head\n/, '').replace(/tttemplate/g, 'template')),
      )
      this.css_ = $root.screen.style
      this.js_ = $root.screen.mixin
      this.activate_drag()
    },
  },
  watch: {
    '$root.screen': 'refresh',
    block() {
      $$('.block').__.map(el => el.removeAttribute('selected'))
      if (this.block) this.block.setAttribute('selected', '')
      if (this.block) window.block_index = $$('.block').__.__.findIndex(el => el === this.block)
    },
  },
  updated() {
    this.$nextTick(this.activate_drag)
  },
  mounted() {
    setTimeout(this.activate_drag, 1000)
  },
}
</script>
