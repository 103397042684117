<template>
  <div v-if="imageFileName === 'error'" class="text-red-600">Error with image</div>
  <img v-else class="nx-image w-[80%] m-auto" :src="imageFileName" />
</template>
<script setup>
import { computed } from 'vue'
const { data, options } = defineProps(['data', 'options'])

const imageFileName = computed(() => {
  if (options.layout.theme.assets?.[+data - 1]) {
    // legacy image management with ID (Keep to prevent breaking existing reports)
    return options.layout.theme.assets?.[+data - 1]?.src
  }
  return options.layout.theme.assets.find(d => d.name === data.name)?.src || 'error'
})
</script>
