<script setup>
import * as d3 from 'd3'
import { ref, watch } from 'vue'
const element = ref(null)
const { data, options = {} } = defineProps(['data', 'options'])
const { x, y, x_step, x_format } = options
const draw = (width, height, margin) => {
  const formatInt = (value, digit) => Math.round(value * Math.pow(10, digit)) / Math.pow(10, digit)
  const ticks = (data, { num = 5, digit = 0, flag = true } = {}) => {
    const min = formatInt(Math.min(...data), digit)
    const max = formatInt(Math.max(...data), digit)
    const gap = formatInt((max - min) / (num - 1), digit)
    const ticks = Array(num)
      .fill()
      .map((_, i) => formatInt(min + i * gap, digit))
    if (flag && gap === max - ticks.at(-1)) return ticks.concat(max)
    return ticks
  }
  const xData = data.map(v => v[x])
  const yData = data.flatMap(v => y.map(k => +v[k]))
  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([margin.left + 5, width - margin.right])
  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(yData))
    .range([height - margin.bottom, margin.top])
  const xTicks = xData.filter((v, i) => i % x_step === 0).map(v => d3.timeFormat(x_format)(v))
  const yTicks = ticks(yData, { num: Math.min(5, Math.round(height / 30)) })
  const line = k =>
    d3
      .line()
      .x(v => xScale(v[x]))
      .y(v => yScale(+v[k]))

  return { xScale, yScale, xTicks, yTicks, line }
}

const width = ref(300)
const height = ref(150)
const margin = { top: 10, bottom: 20, right: 0, left: 20 }
const xScale = ref(() => null)
const yScale = ref(() => null)
const xTicks = ref([])
const yTicks = ref([])
const line = ref(() => () => null)
watch(
  element,
  () => {
    width.value = element.value.parentElement.clientWidth
    height.value = element.value.parentElement.clientHeight
    const x = draw(width.value, height.value, margin)
    xScale.value = x.xScale
    yScale.value = x.yScale
    xTicks.value = x.xTicks
    yTicks.value = x.yTicks
    line.value = x.line
  },
  { deep: true },
)
</script>

<template>
  <svg class="nx-line" xmlns="http://www.w3.org/2000/svg" ref="element" :width="width" :height="height">
    <g class="axis y" :transform="'translate(0,' + yScale(v) + ')'" v-for="v in yTicks">
      <line :x1="margin.left" :x2="width - margin.right" />
      <text alignment-baseline="middle">{{ v }}{{ options.unit }}</text>
    </g>
    <g class="axis x" :transform="'translate(' + xScale(new Date(v.toString())) + ',' + height + ')'" v-for="v in xTicks">
      <text text-anchor="start">{{ v }}</text>
    </g>
    <path :d="line(k)(data)" :class="k" stroke="currentColor" stroke-width="2" fill="transparent" v-for="k in options.y" />
  </svg>
</template>

<style scoped>
.nx-line .axis text {
  font-size: var(--text_size, 12px);
  fill: rgba(0, 0, 0, 0.6);
}
.nx-line .axis line {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
}
.nx-line .axis.x line {
  stroke: black;
  stroke-width: 2px;
}
.nx-line path:first-of-type {
  stroke: rgb(var(--primary))!important;
}
.nx-line path:nth-of-type(2) {
  stroke: rgb(var(--secondary))!important;
}
.nx-line path:nth-of-type(3) {
  stroke: green;
}
.nx-line path:nth-of-type(4) {
  stroke: orange;
}
.nx-line path:nth-of-type(5) {
  stroke: brown;
}
</style>
