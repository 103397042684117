<style>
.board .line .cell {
  align-items: flex-start;
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1 {{ t[$root.screen.path] + ' (' + workflows.filter(d => !d.disabled).length + ')' }}
block.expand
  spreadsheet.stripped.expand(:data="workflows.filter(w => w && !w.disabled)" :options="{ columns: ['name', 'steps', 'usage', 'actions'], sort: ['name'], editable: false }")
    template(v-slot:cell-steps="s")
      div {{ s.line.actions.v().filter().length }} {{ t.steps }}
    template(v-slot:cell-usage="s")
      div {{ usage(s.line.id) }}
    template(v-slot:cell-actions="s")
      .row
        button.ghost(tt="Edit" @click="$router.push({ path: $root.appath + 'workflow', query: { id: s.line.id, action_id: s.line.actions.v().first().id } })")
          svg-icon(name="pt-icon-edit")
        button.ghost(tt="Duplicate" @click="duplicate_workflow(s.line.id)")
          svg-icon(name="ic_file_copy")
        button.ghost(tt="Delete" @click="confirmRemoveId=s.line.id" v-if="usage(s.line.id) === 0")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(@click="create_workflow") Create workflow
  popup(:show="confirmRemoveId")
    template(v-slot:header)
      | {{t["confirmation_remove"]}}
    template(v-slot:content)
      | {{t["text_remove"]}}
    template(v-slot:action)
      button.secondary-action(@click="confirmRemoveId = null") {{t['confirmation_btn_close']}}
      button.main-action(@click="delete_workflow(confirmRemoveId)") {{t["confirmation_btn_delete"]}}
</template>

<script>
import { useWorkflows } from '../composables/useWorkflows'
import { usePresses } from '../composables/usePresses'
import { useProgress } from '../composables/useProgress'
import { computed, ref } from 'vue'
import Popup from '../../../components/ui/popup.vue'

export const additions = { 
  icon: 'ic_settings',
  components: { Popup }
}
export default {
  setup() {
    const confirmRemoveId = ref(null)
    const { workflows, loaded: wfLoaded, initialized } = useWorkflows()
    const { presses, loaded: pLoaded } = usePresses()
    const { progress } = useProgress([wfLoaded, pLoaded])
    const isLoading = computed(() => progress.value !== 1 && !initialized.value)
    return { workflows, presses, isLoading, confirmRemoveId }
  },
  methods: {
    async create_workflow() {
      const id = +(this.workflows || {}).v().map('id').max() + 1 || 0
      await set('data.workflows.' + id, { ...this.empty_workflow, id })
      await this.$router.push({ path: $root.appath + 'workflow', query: { id, action_id: 1 } })
    },
    async duplicate_workflow(wid) {
      // TODO move to workflow.vue
      const id = (this.workflows || {}).v().map('id').max() + 1 || 0
      const duplicateName = this.workflows[wid].name + ' (copy)'
      await set('data.workflows.' + id, { ...this.workflows[wid], name: duplicateName, id })
      await this.$router.push($root.appath + 'workflow?id=' + id)
    },
    async delete_workflow(wid) {
      update_query({ id: null })
      await set('data.workflows.' + wid + '.disabled', true)
      this.confirmRemoveId = null
    },
    usage(wid) {
      return (this.presses || []).filter(d => !d.disabled && d.workflow_id === wid).length 
    },
  },
}
</script>
