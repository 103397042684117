<template>
  <div class="flex h-fit w-[calc(100%+var(--spacing)*2)] px-8 py-4 flex-col items-center justify-between">
    <div class="flex h-fit w-full flex-row items-center justify-between bg-transparent">
      <div class="items start flex flex-col justify-between space-y-1">
        <div class="flex text-[10px]">
          {{ typeof data === 'string' ? data : '' }}
        </div>
      </div>
      <div class="text-primary text-xs font-medium">{{ pagenumber }}</div>
    </div>
  </div>
</template>

<style>
.lgi-footer {
  margin-top: auto !important;
}
</style>

<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue'
const { data, options } = defineProps(['data', 'options'])
const pagenumber = ref(0)
onMounted(() => {
  const el = getCurrentInstance().vnode.el
  const pages = Array.from(document.querySelectorAll('.pdf-page'))
  pagenumber.value = pages.findIndex(page => page.contains(el)) + 1
})
</script>
