<style scoped>
.tag {
  display: inline-flex;
  justify-content: center;
  max-width: fit-content;
  padding: 2px 4px;
  /* color: white;
  background: var(--primary); */
  border-radius: var(--border-radius);
  font: var(--p2);
  max-height: 25px;
  align-items: center;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border: 4px solid transparent;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  padding: 12px;
  width: calc(45% - 50px);
  min-width: 550px;
  max-width: 90%;
  cursor: pointer;
}
.card .title {
  font: var(--h3);
  font-weight: 600;
  line-height: 1.5;
}
.card .benchmark {
  color: var(--inactive);
}
.card .value {
  display: inline-flex;
  align-items: baseline;
  margin: 2px 0;
}

a.card:hover {
  border-color: var(--highlight) !important;
}
.access_btn {
  display: flex;
  justify-content: flex-end;
}
.access_btn select {
  margin-right: 15px;
  width: 250px;
  text-align: left;
  overflow: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.logos {
  width: 30px;
  margin-left: 10px;
  transition: width 0.3s ease-in-out;
  animation: fadeIn 2s;
}
.logos:hover {
  width: 60px;
}
.card .row:nth-child(2),
.card .row:nth-child(3),
.card .row:nth-child(4) {
  padding: 4px 0px;
}
.card .row:nth-child(2) {
  align-items: center;
}
.positive {
  color: green;
}
.negative {
  color: red;
}
.card .kpis {
  gap: 2px;
  text-align: center;
  justify-content: space-between;
}
.card .kpis:has(> :first-child:nth-last-child(2)) {
  justify-content: flex-start;
  gap: 20px;
}

.card .kpis .column {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  animation: fadeIn 2s;
  max-width: 110px;
}
.card .kpis .column > div:first-child {
  font-weight: 600;
}
.card .calendar {
  display: flex;
  font-size: 8px;
  align-items: flex-end;
  transition: font-size 0.3s ease-in-out;
}
.card .calendar:hover {
  font-size: 14px;
}
</style>

<template lang="pug">
router-link.card(:tag="data.link ? 'a' : 'div'" :to="data.link || ''")
  .row
    .column
      .title {{ t[data.title] || data.title }}
      .benchmark {{ t[selected_share?.benchmark?.id] || selected_share?.benchmark?.id || ""}}
  .row
    .tag(:class="tag" v-if="tag") {{ t[tag] || tag }}
    img.logos(:src="img" v-for="img in selected_share?.labels")
  .row.kpis
    .row 
    .column(v-for="kpi in kpi_list")
      div(v-if="kpi=='risk_scale'") {{ t['risk_scale_kpi'] || 'risk_scale_kpi' }}
      div(v-if="kpi!='risk_scale'")  {{ t[kpi] || kpi}}
      div.value.positive( v-if="kpi.includes('perf') && selected_share?.indicators?.[kpi]>=0 ") {{ '↑' + format('+.2%')( data?.[kpi] || selected_share?.indicators?.[kpi]) }}  
      div.value.negative( v-else-if="kpi.includes('perf') && selected_share?.indicators?.[kpi]<0 ") {{ '↓' + format('.2%')( data?.[kpi] || selected_share?.indicators?.[kpi]) }}  
      div(v-else) 
        .value(v-if="kpi=='nav'") {{ format('nav')((selected_share?.indicators?.nav || 'NA')) + ' ' + selected_share?.share_currency }}
        .value(v-else-if="kpi=='risk_scale'") {{ format('risk_scale')(selected_share?.characteristics?.risk_scale) +'/7' }}
        .value(v-else-if="kpi=='aum'") {{ format($root.config.formats['aum'] || 'aum')(selected_share?.indicators?.fund_aum || 'NA') + ' ' + selected_share?.share_currency }}
        .value(v-else) {{ format(kpi)(data?.[kpi] || selected_share?.indicators?.[kpi] || 'NA') }}
  .row.between
    div.calendar(tt='last_update') 📅 {{ selected_share?.indicators?.date || 'NA' }}
    .access_btn
      select(@change="share_path = $event.target.value" v-if="data.links.length > 1")
        option(:value="link.link" v-for="link in data.links") {{ link.name }}
      router-link.button.primary(:to="share_path || data.links[0].link") {{ t.access }}
</template>

<script>
export const additions = {}
export default {
  props: ['data'],
  data() {
    return {
      share_path: 0,
      selected_share: this.data.shares[0],
      tag:
        this.data.shares[0].settings?.[$root.config.tagField] ||
        this.data.shares[0].characteristics?.[$root.config.tagField] ||
        null,
    }
  },
  watch: {
    share_path: function (val) {
      this.selected_share = this.data.shares[this.data.links.findIndex(el => el.link === val)]
    },
  },
  computed: {
    kpi_list() {
      if (this.data.shares[0].mandat_fictif) return ['perf_ytd']
      if (this.selected_share?.characteristics?.risk_scale) {
        if (this.selected_share?.indicators?.perf_ytd_benchmark)
          return ['aum', 'nav', 'perf_ytd', 'perf_ytd_benchmark', 'risk_scale']
        return ['aum', 'nav', 'perf_ytd', 'risk_scale']
      }
      if (this.selected_share?.indicators?.perf_ytd_benchmark) return ['aum', 'nav', 'perf_ytd', 'perf_ytd_benchmark']
      return ['aum', 'nav', 'perf_ytd']
    },
  },
}
</script>
