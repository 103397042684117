/**
 * Generate chartjs chartOptions according options and chartOptions
 * @param options
 * @param tooltip
 * @param legend
 * @param xType type of x axis ('time', 'category')
 * @return {{chartOptions: {plugins: {legend: {align: string, labels: {usePointStyle: boolean}}, tooltip: *}, elements: {line: {tension: number}, point: {pointStyle: string, radius: number}}, scales: {x: {grid: {display: boolean}, time: {unit: string}, type: string}, y: {ticks: {callback: function(*=, *, *): (*|undefined)}}}, interaction: {intersect: boolean, axis: string}}}}
 */

export default function (options, { tooltip, legends, xType, legendPlugin } = {}) {
  const unit = options.value.unit || undefined
  let callback = options.value.floor ? label => (Math.floor(label) === label ? label : undefined) : undefined
  if (options.value.formatY) {
    callback = value => options.value.formatY(value)
  }
  const datalabels =
    options.value.datalabels === true
      ? {
          align: 'top',
          anchor: 'end',
          formatter: options.value.labelFormatter,
        }
      : undefined

  const chartOptions = {
    plugins: {
      datalabels,
      tooltip,
      customLegend: {
        foo: 'bar',
        legends,
      },
      legend: { display: false },
    },
    elements: {
      line: {
        tension: 0,
      },
      bar: {},
      point: {
        pointStyle: 'point',
        radius: 0,
      },
    },
    /**
     * Find nearest values for x (used for tooltip data)
     */
    interaction: {
      intersect: false,
      axis: 'x',
    },
    scales: {
      /**
       * Scale x by month
       */
      x: {
        grid: { display: false },
        type: xType || 'category',
        time: {
          unit,
        },
        ticks: {
          font: {
            size: options.value.textSize
          }
        }
      },
      /**
       * Integer y
       */
      y: {
        ticks: {
          callback,
          font: {
            size: options.value.textSize
          }
        },
      },
    },
    animation: options.value.animation,
  }
  return {
    chartOptions,
  }
}
