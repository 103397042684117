<style scoped>
h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}
h1 .search-filters .autocomplete {
  min-width: 90% !important;
}
.cell-url .link { width: calc(100% - 30px);overflow: hidden;text-overflow: ellipsis;padding-left: 0 0 0 8px; }
.cell-url .ghost { position: absolute;right: 0;max-width: 30px; }
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="loading")
h1 
  span {{ t[$root.screen.path] }}
  .search-filters
    autocomplete.right(
      :data="autocompleteData" 
      :options="{ placeholder: t.search }" 
      :modelValue="activated_filters"
      @update:modelValue="autocomplete_input"
      @keyup.native="search = $event.target.value"
      @focusout.native="search = ''"
    )
  
.block.expand.data-reports
  spreadsheet.stripped(:data="filtered_queries" :options="{columns: ['name', 'url', 'usage', 'actions'], editable: false}")
    template(v-slot:cell-name="s")
      div {{ s.line.name }}
    template(v-slot:cell-url="s")
      a.link(:href="s.line.url" target="_blank") {{ s.line.url.replace(/.token=.*/, '') }}
      button.ghost(tt="Copy" @click="copy(s.line.url)")
        svg-icon(name="ic_file_copy")
    template(v-slot:cell-actions="s")
      .row
        button.ghost(tt="Edit" @click="$router.push({ path: $root.appath +  'data-report', query: { id: s.line.id } })")
          svg-icon(name="pt-icon-edit")
        button.ghost(tt="Run" @click="$router.push({ path: $root.appath + 'data-report-run', query: { id: s.line.id } })")
          svg-icon(name="pt-icon-play")
        button.ghost(tt="Delete" @click="confirmRemoveId=s.line.id" v-if="s.line.usage === 0")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(@click="createDr") {{ t.create_data_report }}
  popup(:show="confirmRemoveId")
    template(v-slot:header)
      | {{t["confirmation_remove"]}}
    template(v-slot:content)
      | {{t["text_remove"]}}
    template(v-slot:action)
      button.secondary-action(@click="confirmRemoveId = null") {{t['confirmation_btn_close']}}
      button.main-action(@click="deleteDr(confirmRemoveId)") {{t["confirmation_btn_delete"]}}
</template>

<script>
import Popup from '../../../components/ui/popup.vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import templateService from '../../../services/TemplateService'
export const additions = { icon: 'pt-icon-graph' }

export default {
  data() {
    return {
      config, // so that it is also available from the vue template
      loading: true,
      queries: [],
      search: '',
      confirmRemoveId: null
      
    }
  },
  computed: {
    filtered_queries() {
      const {domain, ...filters} = $root.filters
      return this.queries
        .filter(f => Object.entries(filters).every(([k, vs]) => vs.some(v => f[k] === v)))
    },

    activated_filters() {
      const params = this.$route.query
      const activeFilters = params.map(filter_value => filter_value.split('|'))
      const filters = []
      Object.entries(activeFilters).forEach(([filterName, filterValues]) => {
        if (filterName === 'year') return
        filterValues.forEach(value => {
          filters.push(this.formatFilterData(filterName, value))
        })
      })
      return filters
    },

    autocompleteData() {
      const filterCriteria = ['name']
      return this.queries.reduce((groupedQueries, q) => {
        filterCriteria.forEach((criterium) => {
          if (typeof q[criterium] === 'string') {
            const filterValue = q[criterium].split('.')[0]
            groupedQueries[criterium] = groupedQueries[criterium] || {}
            groupedQueries[criterium][filterValue] = filterValue
          }
        })
        return groupedQueries
      }, {})
    },
  },

  async mounted() {
    this.$router.replace(this.$route.fullPath.split('?')[0])
    await this.init()
  },

  methods: {
    async init() {
      this.loading = true
      this.queries = await this.getQueries()
      this.loading = false
    },
    autocomplete_input(event) {
      let events = event.map(v => v.split('.'))
      let groupedEvents = events.group('0')
      let selected_filters = groupedEvents.map(g => g.map('1').join('|'))
      let new_filter_selected = this.$route.query.filter((v, k) => !this.$route.query.keys().includes(k))
      let query = { ...selected_filters, ...new_filter_selected }
      this.$router.push({ query })
    },
    formatFilterData(name, value) {
      return name + '.' + value
    },
    async getQueries() {
      const r = await dataReportService.list()
      const templates = await templateService.listWithTemplateContext()
      return r.map(q => {
        const varUrl = Object.entries({ ...q.variables, token: $root.profile.idToken })
          .reduce((a, [k, v]) => {
            return `${a}${a ? '&' : '?'}${k}=${encodeURI(v)}`
          }, '')
        const baseUrl = config.graphqlEndpoint.includes('localhost') ? '' : `${window.location.protocol}//${window.location.host}`
        // TODO: Remove hardcoded builder-context
        // Add to protect against delation of a data report that is used by product [builder-context])
        return {
          ...q,
          url: `${baseUrl}${config.graphqlEndpoint}/dr/${q.id}/run${varUrl}`, // actually here we use the REST 'dr' route of our GraphQL API...
          usage: q.name === 'builder-context' ? 1 : templates.filter(t => t.dataReportId === q.id).length,
        }
      })
    },
    createDr() {
      this.$router.push({ path: $root.appath + 'data-report' })
    },
    async deleteDr(id) {
        try {
          await dataReportService.remove(id)
        } catch (e) {
          $root.toast({ description: e.message, type: 'error', timeout: 5000 })
          return
        }
        this.queries.splice(
          this.queries.findIndex(q => q.id === id),
          1,
        )
        this.confirmRemoveId = null
        await this.init()
    },
    async copy(url) {
      await navigator.clipboard.writeText(url)
    },
  },
}
</script>
