import { commandrSDK, update } from '../../../features/commandr'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { mapRun } from '../utils/runs'
import { useRoute } from 'vue-router'

const production = ref([])
const years = ref([])
const initialized = ref(false)

const enforceTable = data => {
  if (Array.isArray(data)) return data
  return Object.entries(data || {}).reduce((acc, [k, v]) => {
    acc[+k] = v
    return acc
  }, [])
}
export function useProduction() {
  const loaded = ref(false)
  const year = ref(null)
  const route = useRoute()
  const deleteRun = async id => {
    return await update(`data/runs/${id}/disabled`, {date: new Date().toISOString(), user: $root.profile.name})
  }

  const deleteRuns = async ids => {
    const promisesDelete = ids.map(id => deleteRun(id))
    return Promise.all(promisesDelete)
  }

  const onUpdate = snap => {
    production.value = (enforceTable(snap) || []).map(mapRun).filter()
    loaded.value = true
    initialized.value = true
  }

  const initYear = async () => {
    const pref = route.query.year || localStorage.ce_year
    if (pref) return (year.value = pref)
    const years = await commandrSDK.get('/data/runs_years')
    if (typeof years !== 'object') return (year.value = 'all')
    year.value = Object.keys(years).sort().pop()
  }

  const clearCallbacks = () => {
    commandrSDK.unSyncRunsForYear(year.value, 'value')
    commandrSDK.off('/data/runs_years', 'value')
  }
  watch(year, () => {
    loaded.value = false
    commandrSDK.syncRunsForYear(year.value, 'value', onUpdate)
    localStorage.setItem('ce_year', year.value)
    uquery({ year: year.value })
  })
  onMounted(async () => {
    loaded.value = false
    await initYear()
    commandrSDK.on('/data/runs_years', 'value', snap => {
      years.value = Object.keys(snap).sort((a, b) => b.localeCompare(a))
      if (year.value === null && years.value.length) year.value = years.value[0]
    })
  })
  onUnmounted(() => {
    clearCallbacks()
  })
  return {
    loaded,
    deleteRun,
    deleteRuns,
    production,
    initialized,
    years,
    year,
  }
}
