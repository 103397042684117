<script>
import config from "../../../config";

export const additions = { icon: 'ic_account_balance_wallet' }

export default {
  data() {
    return {
      loaded: false,
      fundSearch: {},
      groupingOptions: $root.config.groupingOptions,
      groupedBy: $root.config.groupingOptions?.[0],
      details: true,
      params: {
        lang: this.$route.query.lang || $root.lang,
      },
      data: {},
    }
  },
  computed: {
    filtered_card() {
      const filters = Object.entries(this.fundSearch).map(([k, v]) => [k, v.split('|')])
      return this?.data?.funds.filter(
        d =>
          ($root.profile.role === 'admin' ||
            !$root.profile.role ||
            ($root.profile.role === 'user' &&
              (($root.profile.funds && $root.profile.funds.includes(d.name)) || !$root.profile.funds))) &&
          filters.every(
            ([k, vs]) =>
              vs.some(v => `${d[k]}`.replace('.', '') === v || (d[k] instanceof Array && d[k].includes(v))) ||
              vs.some(v => `${d.shares[Object.keys(d.shares)[0]][k]}`.replace('.', '') === v) ||
              vs.some(v => `${d.shares[Object.keys(d.shares)[0]].characteristics?.[k]}`.replace('.', '') === v) ||
              vs.some(v => `${d.shares[Object.keys(d.shares)[0]].settings?.[k]}`.replace('.', '') === v),
          ),
      )
    },
    grouped_cards() {
      return this?.filtered_card
        .v()
        .group(
          el =>
            el[this.groupedBy] ||
            el.shares[0][this.groupedBy] ||
            el.shares[0]?.characteristics?.[this.groupedBy] ||
            el.shares[0]?.settings?.[this.groupedBy],
        )
        .map(group =>
          group.sort((a, b) =>
            a.slug.toLowerCase() > b.slug.toLowerCase() ? 1 : a.slug.toLowerCase() < b.slug.toLowerCase() ? -1 : 0,
          ),
        )
    },
    activated_filters() {
      let query_param = this.fundSearch
      let active_filters = query_param.map(filter_value => filter_value.split('|'))
      const filters = []
      Object.entries(active_filters).forEach(([filter_name, filter_values]) => {
        if (filter_name === 'year') return
        filter_values.forEach(value => {
          filters.push(this.format_filter_data(filter_name, value))
        })
      })
      return filters
    },
    autocomplete_data() {
      const selection = $root.config.fundSelection || ['fund_name', 'fund_currency', 'dedicated_fund']
      let data = this.data.funds
        .map(el => {
          el.isin_list = el.shares.map(el => el.shareId)
          return el
        })
        .reduce((acc, v) => {
          selection.forEach(k => {
            if (v[k] instanceof Array) {
              for (let i of v[k]) {
                let filter_criteria = i.replace('.', '')
                acc[k] = acc[k] || {}
                if (filter_criteria) acc[k][filter_criteria] = filter_criteria
              }
            } else {
              let filter_criteria = v[k]
                ? String(v[k]).replace('.', '')
                : v.shares.v().first()[k] ||
                  v.shares.v().first().characteristics?.[k] ||
                  v.shares.v().first().settings?.[k]
              acc[k] = acc[k] || {}
              if (filter_criteria) acc[k][filter_criteria] = filter_criteria
            }
          })
          return acc
        }, {})
      return data
    },
  },
  async mounted() {
    console.log('mounted', this.params)
    await this.getFundListData()
  },

  methods: {
    async getFundListData() {
      const resp = await axios({
        url: config.graphqlEndpoint,
        method: 'post',
        data: {
          variables: this.params,
          query: `
          query SlashQuery {
            businessData {
              funds {
                fund_name
                slug
                shares {
                  shareId
                  dedicated_fund
                  share_currency
                  benchmark {
                    label
                    id
                  }
                  indicators {
                    shareId
                    perf_ytd
                    date
                    nav
                    fund_aum
                    perf_ytd_benchmark
                  }
                  ${$root.config.characteristicsSchema || ''}
                  ${$root.config.settingsSchema || ''}
                  ${$root.config.customShareSchema || ''}
                }
              }
            }
          }`,
        },
      })
      this.data = resp.data.data.businessData
      this.loaded = true
    },
    card_data(userflow) {
      const base = this.$route?.meta?.app ? '' : '/'
      const router_link = userflow.shares.map(share => {
        const name = share.characteristics?.share_letter
          ? `${share.shareId} - ${$root.t['share']} ${share.characteristics?.share_letter}`
          : `${share.shareId}`
        return {
          isin: share.shareId,
          name,
          link: `${base + userflow.slug}-${share.shareId}/overview`,
        }
      })
      return {
        ...userflow,
        links: router_link,
        title: userflow.fund_name || userflow.slug,
      }
    },
    format_filter_data(name, value) {
      return name + '.' + value
    },
    autocomplete_input(event) {
      // Remove ALL:
      if (event.length === 0) return (this.fundSearch = {})
      const event_array = event.map(v => [v.slice(0, v.indexOf('.')), v.slice(v.indexOf('.') + 1, v.length)])
      const grouped_events = event_array.group('0')
      const selected_filters = grouped_events.map(g => g.map('1').join('|'))
      const new_filter_selected = this.fundSearch.filter((v, k) => !this.fundSearch.keys().includes(k))
      this.fundSearch = { ...selected_filters, ...new_filter_selected }
    },
  },
}
</script>
<template lang="pug">
transition(@leave='leave')
  loader(v-if="!this.loaded")
template(v-if="this.loaded")
  .row(v-if="loaded")
    h1 {{ t.slash }} ({{ filtered_card.v().length }})
    .row.controls
      .row.right
        autocomplete(
          :data='autocomplete_data', 
          :options='{ placeholder: t.search }', 
          :modelValue='activated_filters', 
          @update:modelValue='autocomplete_input'
        )
      .row
        select(v-model="groupedBy" v-if="groupingOptions")
          option(v-for="g in groupingOptions" :value="g" :key="g") {{$root.t[g] || g.titleize()}}
  template(v-for="group in Object.keys(grouped_cards).sort()" :key="group" v-if="loaded")
    h3(v-if="grouped_cards[group].length && groupingOptions") {{ $root.t[group] || group || $root.t['not_specified'] || 'not_specified' }} ({{grouped_cards[group].length}})
    .cards_list
      .card_container(v-for='userflow in grouped_cards[group]' :key="userflow.slug")
        card(:class='userflow.category', :data='card_data(userflow)')
</template>

<style scoped>
.row {
  flex-wrap: wrap;
}
.row.right {
  flex: 1;
}
.row.right input {
  min-width: 400px;
}
.cards_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.card_container {
  display: flex;
  justify-content: center;
  height: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 550px;
}

.row:first-of-type {
  position: sticky;
  top: 60px;
  /* background-color: #cfd3db; */ /* couleur lfra */
}
@media screen and (max-width: 1427px) {
  .cards_list {
    justify-content: center;
  }
}
@media screen and (min-width: 700px) {
  .controls {
    flex: 1;
  }
}
.controls {
  width: 100%;
  min-width: 489px;
}
</style>
