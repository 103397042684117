<template>
  <div class="flex">
    <div class="flex max-w-[calc(100%-400px)] flex-1 flex-col print:max-w-[unset]">
      <!-- TOPBAR -->
      <div class="sticky top-0 z-10 bg-white shadow-lg print:hidden" v-if="!isPreviewMode">
        <div class="flex h-16 w-full items-center space-x-6 border-b px-12">
          <button class="rounded p-2 hover:bg-[#00d9b8]/20" @click="$root.$router.push('/client-edition/templates')">
            <div class="text-3xl" style="--un-icon: url(https://api.iconify.design/heroicons-outline:home.svg)"></div>
          </button>
          <label class="flex flex-col">
            <div class="w-40 text-xs font-semibold text-[#00d9b8]">Template</div>
            <select class="flex rounded border-transparent bg-transparent py-0 pl-0" v-model="templateName">
              <option v-for="v in templates" :value="v">{{ v }}</option>
            </select>
            <!-- <input type="url" v-model="dataReportId" /> -->
          </label>
          <label class="relative flex flex-col">
            <a
              class="absolute -top-1 right-1 flex rounded bg-transparent px-2 py-1 hover:bg-[#00d9b8]/10"
              :href="studioURL"
              target="_blank"
            >
              <div style="--un-icon: url(https://api.iconify.design/heroicons-outline:link.svg)"></div>
            </a>
            <div class="w-40 text-xs font-semibold text-[#00d9b8]">Data Report</div>
            <select class="flex rounded border-transparent bg-transparent py-0 pl-0" v-model="dataReportId">
              <option v-for="dr in dataReports" :value="dr.id">{{ dr.name }}</option>
            </select>
            <!-- <input type="url" v-model="dataReportId" /> -->
          </label>

          <div v-if="isBuilderReady" class="flex flex-1 justify-end gap-2">
            <button
              class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10"
              @click="window.print()"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/heroicons-outline:printer.svg)"
              ></div>
              <div class="hidden font-semibold 2xl:block">Print</div>
            </button>
            <button
              class="relative flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10"
              :class="{ 'cursor-not-allowed': store.undo.length === 1 }"
              @click="undo()"
            >
              <div class="text-xl" style="--un-icon: url(https://api.iconify.design/mdi:undo-variant.svg)"></div>
              <div class="hidden font-semibold 2xl:block">Undo</div>
              <div
                class="absolute -right-1 -top-1 h-4 w-4 truncate rounded-full bg-[#00d9b8]/75 text-[10px] leading-[16px]"
                v-if="store.undo.length > 1"
              >
                {{ store.undo.length - 1 }}
              </div>
            </button>
            <button
              class="relative flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10"
              :class="{ 'cursor-not-allowed': store.redo.length === 0 }"
              @click="redo()"
              :disabled="store.redo.length === 0"
            >
              <div class="text-xl" style="--un-icon: url(https://api.iconify.design/mdi:redo-variant.svg)"></div>
              <div class="hidden font-semibold 2xl:block">Redo</div>
              <div
                class="absolute -right-1 -top-1 h-4 w-4 truncate rounded-full bg-[#00d9b8]/75 text-[10px] leading-[16px]"
                v-if="store.redo.length"
              >
                {{ store.redo.length }}
              </div>
            </button>
            <button
              class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10"
              @click="saveVersion()"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/material-symbols:save.svg?color=%2302c5a4)"
              ></div>
              <div class="hidden font-semibold 2xl:block">Save</div>
            </button>
            <button
              class="relative flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10"
              @click="store.versions.open = !store.versions.open"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/material-symbols:history.svg?color=%2302c5a4)"
              ></div>
              <div class="hidden font-semibold 2xl:block">History</div>
              <!-- <div>{{ store.versions.length }}</div> -->
              <div class="fixed inset-0 bg-black/10" v-if="store.versions.open"></div>
              <div
                class="absolute right-0 top-0 translate-y-12 border bg-white shadow"
                @click.stop
                v-if="store.versions.open"
              >
                <div class="m-2 flex gap-2">
                  <button
                    class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
                    @click="downloadVersion"
                  >
                    Download
                  </button>
                  <label
                    class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
                    ><input type="file" class="hidden" accept="application/json" @input="uploadVersion" />Upload</label
                  >
                </div>
                <div class="m-2 flex gap-2" v-for="version in store.versions">
                  <div class="flex w-24 truncate">{{ new Date(version.createdAt).format('MM/DD hh:mm') }}</div>
                  <div class="flex w-60 truncate">{{ version.user }}</div>
                  <button
                    class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
                    @click="restoreVersion(version)"
                  >
                    Restore
                  </button>
                  <button
                    class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
                    @click="deleteVersion(version)"
                    :disabled="store.versions.length === 1"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="flex h-16 w-full items-center space-x-6 pl-[120px]">
          <label
            class="flex flex-col items-center text-sm"
            :class="{ fund: 'w-[300px]', share: 'w-40', date: 'w-unset' }[variable] || 'w-24'"
            v-for="variable in ['fund', 'share', 'lang', 'period', 'date']"
          >
            <div class="w-full text-xs font-semibold text-[#00d9b8]">{{ variable.format('title') }}</div>
            <template v-if="variables.period === 'Custom' && variable === 'date'">
              <div class="flex">
                <input
                  type="date"
                  class="flex w-28 truncate rounded border-transparent bg-transparent p-0 [font-size:inherit]"
                  :value="variables.date.split('|')[0]"
                  @change="
                    variables.date = [new Date($event.target.value).format(), variables.date.split('|')[1]]
                      .filter()
                      .sort()
                      .join('|')
                  "
                />
                <input
                  type="date"
                  class="flex w-28 truncate rounded border-transparent bg-transparent p-0 [font-size:inherit]"
                  :value="variables.date.split('|')[1]"
                  @change="
                    variables.date = [variables.date.split('|')[0], new Date($event.target.value).format()]
                      .filter()
                      .sort()
                      .join('|')
                  "
                />
              </div>
            </template>
            <select
              class="flex w-full truncate rounded border-transparent bg-transparent p-0 pr-6 [font-size:inherit]"
              v-model="variables[variable]"
              v-else
            >
              <option v-for="option in variablesOptions[variable]?.[variables.fund] || variablesOptions[variable]">
                {{ option }}
              </option>
            </select>
          </label>
        </div>
      </div>

      <!-- PREVIEW -->
      <div v-if="isBuilderReady" v-html="'<style>' + template.theme?.style + '</style>'"></div>
      <div v-if="isBuilderReady" class="[font-size:--text_size]" :style="theme.variables">
        <builder
          data-builder
          class="isolate flex flex-wrap justify-center gap-8 p-8"
          :class="[theme.class, { blueprint: blueprint && tab !== 'theme' }]"
          :style="{ zoom }"
          :report="builderReport"
          :data="dataReport"
          :layout="template"
          :store="store"
          @active="storeActive"
          @end="blockMove"
        />
      
      </div>
    </div>

    <!-- TABS -->
    <div
      class="sticky z-[100] top-0 flex h-screen min-w-[400px] max-w-[400px] flex-col gap-8 overflow-auto overscroll-y-contain bg-neutral-900 text-white print:hidden"
    >
      <!-- Helper - Nav Top Empty -->
      <div v-if="!isNavTopFilled" class="flex flex-1 flex-col justify-center items-center">
          <div class="font-regular relative mb-4 block w-2/3  rounded-lg !bg-[#00d9b8]/75 p-4 text-base leading-5 text-white opacity-100">
              Complete the Nav Top form before building a template.
          </div>
      </div>

      <!-- Loader -->
      <div v-if="isDataReportLoading" class="flex flex-1 flex-col justify-center items-center">
        <div class="font-regular relative mb-4 block w-2/3  rounded-lg !bg-[#00d9b8]/75 p-4 text-base leading-5 text-white opacity-100">
          Template initialization, please wait..
        </div>
      </div>

      <!-- Error -->
      <div v-if="isDataReportError" class="flex flex-1 flex-col justify-center items-center">
        <div class="font-regular relative mb-4 block w-2/3  rounded-lg bg-red-600 p-4 text-base leading-5 text-white opacity-100">
          {{  errorMessage }}
          <br/><br/>
          Please contact Impress support for assistance.
        </div>
      </div>

      <!-- Error -->
      <div v-if="isPreviewMode" class="flex flex-1 flex-col justify-center items-center">
        <div class="font-regular relative mb-4 block w-2/3  rounded-lg !bg-[#00d9b8]/75 p-4 text-base leading-5 text-white opacity-100">
          Preview mode
        </div>
      </div>


      <div v-if="isBuilderReady && !isPreviewMode" class="flex h-16 items-center border-b border-gray-500">
        <button
          class="h-full w-1/3 text-lg font-bold hover:bg-neutral-600"
          :class="tab == 'theme' ? '!bg-[#00d9b8]/75' : ''"
          @click="tab = 'theme'"
        >
          Theme
        </button>
        <button
          class="h-full w-1/3 text-lg font-bold hover:bg-neutral-600"
          :class="tab == 'layout' ? '!bg-[#00d9b8]/75' : ''"
          @click="tab = 'layout'"
        >
          Layout
        </button>
        <button
          class="h-full w-1/3 text-lg font-bold hover:bg-neutral-600"
          :class="tab == 'component' ? '!bg-[#00d9b8]/75' : ''"
          @click="tab = 'component'"
        >
          Component
        </button>
      </div>

      <template v-if="tab == 'theme' && template.theme && isBuilderReady && !isPreviewMode">
        <section class="flex flex-col gap-4 px-8">
          <div class="flex items-center gap-4">
            <div class="w-40 text-sm">Page Size</div>
            <div class="flex w-48 gap-4">
              <button
                class="flex-1 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
                :class="template.theme.page_size === 'A4' ? '!bg-[#00d9b8]/75' : ''"
                @click="template.theme.page_size = 'A4'"
              >
                A4
              </button>
              <button
                class="flex-1 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
                :class="template.theme.page_size === 'letter' ? '!bg-[#00d9b8]/75' : ''"
                @click="template.theme.page_size = 'letter'"
              >
                Letter
              </button>
            </div>
          </div>
          <div class="flex items-center gap-4">
            <div class="w-40 text-sm">Page Orientation</div>
            <div class="flex w-48 gap-4">
              <button
                class="flex flex-1 justify-center rounded bg-neutral-600 px-3 py-1 hover:bg-neutral-400"
                :class="template.theme.page_orientation === 'portrait' ? '!bg-[#00d9b8]/75' : ''"
                @click="template.theme.page_orientation = 'portrait'"
              >
                <div
                  class="text-xl"
                  style="--un-icon: url(https://api.iconify.design/icon-park-outline:rectangle.svg)"
                ></div>
              </button>
              <button
                class="flex flex-1 justify-center rounded bg-neutral-600 px-3 py-1 hover:bg-neutral-400"
                :class="template.theme.page_orientation === 'landscape' ? '!bg-[#00d9b8]/75' : ''"
                @click="template.theme.page_orientation = 'landscape'"
              >
                <div
                  class="text-xl"
                  style="--un-icon: url(https://api.iconify.design/icon-park-outline:rectangle-one.svg)"
                ></div>
              </button>
            </div>
          </div>
          <div class="flex items-center gap-4">
            <div class="flex w-40 justify-between text-sm">
              <span>Spacing</span>
              <pre class="text-neutral-400">{{ template.theme.spacing * 4 }}px</pre>
            </div>
            <input
              class="w-48 border-transparent bg-transparent"
              type="range"
              min="0"
              max="12"
              step="0.5"
              v-model="template.theme.spacing"
            />
          </div>
          <div class="flex items-center gap-4">
            <div class="flex w-40 justify-between text-sm">
              <span>Text Size</span>
              <pre class="text-neutral-400">{{ template.theme.text_size * 4 }}px</pre>
            </div>
            <input
              class="w-48 border-transparent bg-transparent"
              type="range"
              min="0"
              max="6"
              step="0.25"
              v-model="template.theme.text_size"
            />
          </div>
          <div class="flex items-center gap-4">
            <div class="flex w-40 justify-between text-sm">
              <span>Title Size</span>
              <pre class="text-neutral-400">{{ template.theme.title_size * 4 }}px</pre>
            </div>
            <input
              class="w-48 border-transparent bg-transparent"
              type="range"
              min="0"
              max="12"
              step="0.25"
              v-model="template.theme.title_size"
            />
          </div>
          <label class="flex cursor-pointer items-center">
            <div class="flex w-40 justify-between text-sm">Primary color</div>
            <input
              class="!h-7 !w-7 border-transparent bg-transparent"
              type="color"
              :value="template.theme.primary"
              @input="window.requestAnimationFrame(() => (template.theme.primary = $event.target.value))"
            />
          </label>
          <label class="flex cursor-pointer items-center">
            <div class="flex w-40 justify-between text-sm">Secondary color</div>
            <input
              class="!h-7 !w-7 border-transparent bg-transparent"
              type="color"
              :value="template.theme.secondary"
              @input="window.requestAnimationFrame(() => (template.theme.secondary = $event.target.value))"
            />
          </label>
          <label class="flex cursor-pointer items-center gap-4 text-sm" v-for="(asset, i) in template.theme?.assets">
            <div class="flex w-40 justify-between">
              <input class="hidden" type="file" accept="image/*" @change="uploadAsset($event, i)" />
              <span>{{ ((asset?.name + ' (' + (i + 1)) + ')') || ('Image ' + (i + 1)) }}</span>
              <div class="text-neutral-400">{{ asset.size }}</div>
            </div>
            <div class="flex w-48 items-center">
              <img :src="asset.src" class="h-7 max-w-[120px] rounded object-contain" />
              <button
                class="ml-auto flex flex-col items-center rounded bg-transparent p-2 text-black hover:bg-neutral-700"
                @click="removeAsset(i)"
              >
                <div
                  class="text-2xl text-neutral-400"
                  style="--un-icon: url(https://api.iconify.design/heroicons-outline:trash.svg)"
                ></div>
              </button>
            </div>
          </label>
          <div class="flex items-center gap-4 pt-2">
            <label
              class="flex cursor-pointer justify-center gap-1 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/material-symbols:add-box.svg?color=%236f7675)"
              ></div>
              <div class="text-sm">Add image</div>
              <input class="hidden" type="file" accept="image/*" @change="uploadAsset" multiple />
            </label>
          </div>
        </section>

        <section class="!mt-auto flex flex-col border-t border-t-gray-500 p-8 text-xs">
          <div class="text-lg font-bold">CSS</div>
          <textarea
            class="h-28 w-full resize-none rounded-lg bg-black p-2 text-sm text-white"
            v-model="template.theme.style"
          ></textarea>
        </section>
      </template>

      <template v-if="tab == 'layout' && isBuilderReady && !isPreviewMode">
        <section class="px-8">
          <div class="flex flex-col items-center gap-4">
            <button
              class="flex h-8 w-36 items-center justify-center gap-1 rounded bg-neutral-600 py-2 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
              @click="pageAdd(0)"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/heroicons-outline:plus-circle.svg)"
              ></div>
              <div>Page Top</div>
            </button>
            <button
              class="flex h-8 w-36 items-center justify-center gap-1 rounded bg-red-600 py-2 text-sm hover:bg-red-900"
              :class="{ 'pointer-events-none opacity-50': template.pages?.length < 2 }"
              @click="pageDel()"
            >
              <div class="text-xl" style="--un-icon: url(https://api.iconify.design/heroicons-outline:trash.svg)"></div>
              <div>Delete Page</div>
            </button>
            <button
              class="flex h-8 w-36 items-center justify-center gap-1 rounded bg-neutral-600 py-2 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
              @click="pageAdd()"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/heroicons-outline:plus-circle.svg)"
              ></div>
              <div>Page Bottom</div>
            </button>
          </div>
        </section>

        <section class="border-t border-gray-500 px-8 pt-8">
          <div class="flex justify-center">
            <button
              class="flex h-8 w-24 items-center justify-center rounded bg-neutral-600 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
              @click="blockAdd('top')"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/heroicons-outline:plus-circle.svg)"
              ></div>
              <div>Top</div>
            </button>
          </div>
          <div class="flex items-center justify-center gap-4 py-4">
            <button
              class="flex h-8 w-24 items-center justify-center gap-1 rounded bg-neutral-600 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
              @click="blockAdd('left')"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/heroicons-outline:plus-circle.svg)"
              ></div>
              <div>Left</div>
            </button>
            <button
              class="flex h-8 w-24 items-center justify-center gap-1 rounded bg-red-600 text-sm hover:bg-red-900"
              @click="blockDel()"
            >
              <div class="text-xl" style="--un-icon: url(https://api.iconify.design/heroicons-outline:trash.svg)"></div>
              <div>Delete</div>
            </button>
            <button
              class="flex h-8 w-24 items-center justify-center gap-1 rounded bg-neutral-600 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
              @click="blockAdd('right')"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/heroicons-outline:plus-circle.svg)"
              ></div>
              <div>Right</div>
            </button>
          </div>
          <div class="flex justify-center">
            <button
              class="flex h-8 w-24 items-center justify-center rounded bg-neutral-600 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
              @click="blockAdd('bottom')"
            >
              <div
                class="text-xl"
                style="--un-icon: url(https://api.iconify.design/heroicons-outline:plus-circle.svg)"
              ></div>
              <div>Bottom</div>
            </button>
          </div>
        </section>

        <section class="border-t border-gray-500 px-8 pt-8">
          <div class="my-3 flex flex-col space-y-5 text-sm" v-if="containerActive.lines">
            <div class="flex items-center gap-2">
              <div class="w-32 shrink-0">Block Width</div>
              <button class="h-8 w-16 cursor-not-allowed rounded bg-neutral-800 px-3 py-1 text-sm">Fit</button>
              <button
                class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
                :class="!containerActive.width ? '!bg-[#00d9b8]/75' : ''"
                @click="containerActive.width = ''"
              >
                Auto
              </button>
              <input
                type="text"
                class="flex h-8 w-16 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
                v-model="containerActive.width"
              />
            </div>
            <div class="flex items-center gap-2">
              <div class="w-32 shrink-0">Block Height</div>
              <button
                class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
                :class="blockActive.height === 'fit' ? '!bg-[#00d9b8]/75' : ''"
                @click="blockActive.height = 'fit'"
              >
                Fit
              </button>
              <button
                class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
                :class="!blockActive.height ? '!bg-[#00d9b8]/75' : ''"
                @click="blockActive.height = ''"
              >
                Auto
              </button>
              <input
                type="text"
                class="flex h-8 w-16 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
                v-model="blockActive.height"
              />
            </div>
          </div>
          <div class="my-3 flex flex-col space-y-5 text-sm" v-else>
            <div class="flex items-center gap-2">
              <div class="w-32 shrink-0">Block Width</div>
              <button class="h-8 w-16 cursor-not-allowed rounded bg-neutral-800 px-3 py-1 text-sm">Fit</button>
              <button
                class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
                :class="!blockActive.width ? '!bg-[#00d9b8]/75' : ''"
                @click="blockActive.width = ''"
              >
                Auto
              </button>
              <input
                type="text"
                class="flex h-8 w-16 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
                v-model="blockActive.width"
              />
            </div>
            <div class="flex items-center gap-2">
              <div class="w-32 shrink-0">Block Height</div>
              <button
                class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
                :class="containerActive.height === 'fit' ? '!bg-[#00d9b8]/75' : ''"
                @click="containerActive.height = 'fit'"
              >
                Fit
              </button>
              <button
                class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
                :class="!containerActive.height ? '!bg-[#00d9b8]/75' : ''"
                @click="containerActive.height = ''"
              >
                Auto
              </button>
              <input
                type="text"
                class="flex h-8 w-16 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
                v-model="containerActive.height"
              />
            </div>
          </div>
        </section>

        <section class="!mt-auto flex flex-col gap-4 border-t border-t-gray-500 p-8 text-xs">
          <div class="flex items-center gap-4">
            <div class="flex w-40 justify-between text-sm">
              <span>Blueprint</span>
            </div>
            <button
              class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
              :class="blueprint ? '!bg-[#00d9b8]/75' : ''"
              @click="blueprint = true"
            >
              ON
            </button>
            <button
              class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
              :class="!blueprint ? '!bg-[#00d9b8]/75' : ''"
              @click="blueprint = false"
            >
              OFF
            </button>
          </div>
          <div class="flex items-center gap-4">
            <div class="flex w-40 justify-between text-sm">
              <span>Zoom</span>
              <pre class="text-neutral-400">{{ Math.round(zoom * 100) }}%</pre>
            </div>
            <input
              class="w-48 border-transparent bg-transparent"
              type="range"
              min="0.5"
              max="1.5"
              step="0.1"
              v-model="zoom"
            />
          </div>
          <!-- <div class="text-lg font-bold">BLOCK CSS</div>
          <textarea class="h-28 w-full resize-none rounded-lg bg-black p-2 text-sm text-white" v-model="blockActive.style"></textarea> -->
        </section>
      </template>

      <template v-if="tab == 'component' && isBuilderReady && !isPreviewMode">
        <section class="px-8">
          <div class="text-lg font-bold">Data & Options</div>
          <label class="my-3 flex-col items-center text-sm">
            <div class="w-32 shrink-0">Data point</div>
            <input
              type="text"
              class="w-82 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              list="datapoints"
              v-model="blockActive.data"
            />
            <datalist id="datapoints">
              <option></option>
              <template v-for="(v1, k1) in dataReport">
                <!-- <option>{{ k1 }}</option> -->
                <option v-for="(v2, k2) in v1">{{ k1 }}.{{ k2 }}</option>
              </template>
            </datalist>
          </label>
          <label class="my-3 flex-col items-center text-sm">
            <div class="mt-2 w-32 shrink-0">Visualisation type</div>
            <select
              class="w-82 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              v-model="blockActive.component"
            >
              <template v-if="!blockActive.component || blockActive.component.startsWith('page')">
                <option></option>
                <optgroup label="Page">
                  <option :value="component" v-for="component in componentList.filter(k => k.startsWith('page'))">
                    {{ component.slice(5) }}
                  </option>
                </optgroup>
              </template>
              <template v-else>
                <option value="block"></option>
                <option
                  :value="component"
                  v-for="component in componentList.filter(k => ['text', 'table', 'line', 'bar', 'pie', 'image'].includes(k))"
                >
                  {{ component }}
                </option>
                <optgroup label="Custom" v-if="blockActive.component && !blockActive.component.startsWith('page')">
                  <option
                    :value="component"
                    v-for="component in componentList.filter(
                      k => !['block', 'text', 'table', 'line', 'bar', 'pie'].includes(k) && !k.startsWith('page'),
                    )"
                  >
                    {{ component }}
                  </option>
                </optgroup>
              </template>
            </select>
          </label>
          
          <template v-if="blockAPI">
            <label class="my-3 flex items-center text-sm" v-for="(api, name) in blockAPI">
              <div class="w-32 shrink-0">{{ api.label }}</div>
              <template v-if="api.attrs?.multiple">
                <input
                  v-bind="{
                    class:
                      'w-48 [font-size:inherit] rounded bg-neutral-600 p-1 focus:outline-none focus-visible:ring-1',
                    type: 'email',
                    ...api.attrs,
                  }"
                  :list="name"
                  :value="blockActive.options[name]?.join?.(',')"
                  @change="blockActive.options[name] = $event.target.value.split(',')"
                />
                <datalist :id="name">
                  <option v-for="option in api.autocomplete?.(blockReport)">{{ option }}</option>
                </datalist>
              </template>
              <template v-else-if="api.autocomplete">
                <select
                  v-bind="{
                    class:
                      'w-48 [font-size:inherit] rounded bg-neutral-600 p-1 focus:outline-none focus-visible:ring-1',
                    type: 'text',
                    ...api.attrs,
                  }"
                  :list="name"
                  v-model="blockActive.options[name]"
                >
                  <option v-for="option in api.autocomplete?.(blockReport)">{{ option }}</option>
                </select>
              </template>
              <template v-else>
                <input
                  v-bind="{
                    class:
                      'w-48 [font-size:inherit] rounded bg-neutral-600 p-1 focus:outline-none focus-visible:ring-1',
                    type: 'text',
                    ...api.attrs,
                  }"
                  :list="name"
                  v-model="blockActive.options[name]"
                />
              </template>
            </label>
          </template>
        </section>

        <section class="border-t border-gray-500 px-8 pt-8" v-if="blockActive?.options">
          <datalist id="datapointsTranslation">
              <option></option>
              <template v-for="(v1, k1) in {translation: dataReport['translation']}">
                <!-- <option>{{ k1 }}</option> -->
                <option v-for="(v2, k2) in v1">{{ k1 }}.{{ k2 }}</option>
              </template>
            </datalist>
          <div class="text-lg font-bold">Title & Note</div>
          <label class="my-3 flex items-center text-sm">
            <div class="w-32 shrink-0">Title</div>
            <input
              type="text"
              class="w-48 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              list="datapointsTranslation"
              v-model="blockActive.options.title"
            />
          </label>
          <label class="my-3 flex items-center text-sm">
            <div class="w-32 shrink-0">Subtitle</div>
            <input
              type="text"
              class="w-48 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              list="datapointsTranslation"
              v-model="blockActive.options.subtitle"
            />
          </label>
          <label class="my-3 flex items-center text-sm">
            <div class="w-32 shrink-0">Disclaimer</div>
            <input
              type="text"
              class="w-48 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              list="datapointsTranslation"
              v-model="blockActive.options.disclaimer"
            />
          </label>
        </section>

        <!-- <section>
          <div class="flex">
            <div class="text-lg font-bold">Content</div>
            <button
              class="ml-auto rounded bg-neutral-600 px-2 text-sm hover:bg-neutral-400"
              @click="delete blockActive.data_override"
              v-if="blockActive.data_override"
            >
              Delete Data Override
            </button>
          </div>
          <textarea
            class="my-3 h-40 w-full rounded bg-neutral-600 p-3 text-xs"
            :value="blockReport.data_component"
            @input="blockActive.data_override = $event.target.value"
            v-if="blockActive.component === 'text'"
          ></textarea>
          <div class="relative flex-grow overflow-y-auto">
            <div class="inline-block min-w-full align-middle">
              <table class="min-w-full" v-if="blockActive.component === 'table'">
                <tr v-for="(line, x) in blockReport.data_component">
                  <td v-for="(cell, y) in line">
                    <input
                      class="bg-neutral-500 p-1"
                      :value="cell"
                      @keydown="blockActive.data_override = blockActive.data_override || JSON.parse(JSON.stringify(blockReport.data_component))"
                      @input="blockActive.data_override[x][y] = $event.target.value"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section> -->
      </template>
    </div>
  </div>
</template>

<style>
  /* TODO: check with I nedd this dirty hack for val */
  /* .pdf-block { min-height: 200px;} */
</style>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import templateService from '../../services/TemplateService'
import dataReportService from '../../services/DataReportService'

Object.equal = (a, b) => {
  if (a === b) return true
  const ta = Object.prototype.toString.call(a)
  if (ta !== Object.prototype.toString.call(b)) return false
  if (!['[object Object]', '[object Array]'].includes(ta)) return a.toString() === b.toString()
  if (Object.keys(a).length !== Object.keys(b).length) return false
  return Object.keys(a).every(k => Object.equal(a[k], b[k]))
}
Object.traverse = (obj, fnLeaf, fnNode, path = [], root) => {
  if (!obj) return
  if (obj instanceof Object) {
    fnNode(obj, path, root)
    return Object.keys(obj).forEach((k, i) => Object.traverse(obj[k], fnLeaf, fnNode, path.concat(k), root || obj))
  }
  return fnLeaf(obj, path, root)
}
//! Variables
// TODO: switch to reactive instead of ref
const isNavTopFilled = ref(false)
const isDataReportLoading = ref(false)
const isDataReportError = ref(false)
const isPreviewMode = ref(false)
const errorMessage=ref('')
const components = Object.assign(...window.platform.apps.flatMap(v => v.components)) || {}
const dataReports = ref({})
const templates = ref({})
const dataReport = ref({})
const template = ref({})
const variables = ref({})
const variablesOptions = ref({})
const studioURL = ref('')
const dataReportId = ref('')
const templateName = ref('')
const builderReport = ref({})
const tab = ref(new URLSearchParams(location.search).get('tab') || 'theme')
const zoom = ref(+localStorage.zoom || 1)
const blueprint = ref(localStorage.blueprint !== 'false')
watch(zoom, () => (localStorage.zoom = zoom.value))
watch(blueprint, () => (localStorage.blueprint = blueprint.value))
const store = ref({ active: [], undo: [], redo: [], undo_flag: false, versions: [] })
const blockActive = computed(() => store.value.active.reduce((acc, p) => acc?.[p], template.value) || template.value)
const containerActive = computed(
  () => store.value.active.slice(0, -2).reduce((acc, p) => acc?.[p], template.value) || template.value,
)
const blockReport = computed(() => store.value.active.reduce((acc, p) => acc?.[p], builderReport.value))
const blockAPI = computed(() => {
  const api = components[template.value.theme?.prefix + '-' + blockActive.value.component]?.api
  if (api)
    Object.entries(api).forEach(([k, v]) => {
      if (!v.default || blockActive.value.options.hasOwnProperty(k)) return
      blockActive.value.options[k] = v.default(blockReport.value)
    })
  return api
})
const componentList = computed(() => {
  return Object.keys(components)
    .filter(k => k.startsWith(template.value.theme?.prefix + '-') && !k.endsWith('-story') && !k.endsWith('-error'))
    .map(k => k.replace(template.value.theme?.prefix + '-', ''))
  })
const theme = computed(() => {
  if (!template.value.theme) return {}
  const { page_size, page_orientation, ...variables } = template.value.theme
  const map = v => {
    if (v instanceof Object) return
    if (!isNaN(+v)) return v * 0.25 + 'rem'
    if (v.startsWith('#'))
      return v
        .slice(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16))
        .join(',')
    return v
  }
  return {
    class: ['pdf', page_size, page_orientation],
    variables: Object.fromEntries(Object.entries(variables).map(([k, v]) => [`--${k}`, map(v)])),
  }
})
const isBuilderReady = computed(() => {
  return isNavTopFilled.value && !isDataReportLoading.value && !isDataReportError.value
})
const blockAdd = (dir, path = store.value.active) => {
  const isLine = path.at(-2) === 'lines'
  const isColumn = path.at(-2) === 'columns'
  const layoutNode = path.slice(0, -1).reduce((acc, p) => acc[p], template.value)
  // CASE 1: Add new block
  if ((isColumn && dir === 'right') || (isLine && dir === 'bottom')) {
    const i = +path.at(-1)
    layoutNode.splice(i + 1, 0, { component: 'block' })
    store.value.active = path.slice(0, -1).concat('' + (i + 1))
  }
  if ((isColumn && dir === 'left') || (isLine && dir === 'top')) {
    const i = +path.at(-1)
    layoutNode.splice(i, 0, { component: 'block' })
    store.value.active = path.slice(0, -1).concat('' + i)
  }
  // CASE 2: Create new container
  if (isColumn && dir === 'bottom') {
    const element = path.reduce((acc, p) => acc[p], template.value)
    layoutNode[path.at(-1)] = { lines: [element, { component: 'block' }] }
    store.value.active = path.concat(['lines', '1'])
  }
  if (isColumn && dir === 'top') {
    const element = path.reduce((acc, p) => acc[p], template.value)
    layoutNode[path.at(-1)] = { lines: [{ component: 'block' }, element] }
    store.value.active = path.concat(['lines', '0'])
  }
  if (isLine && dir === 'right') {
    const element = path.reduce((acc, p) => acc[p], template.value)
    layoutNode[path.at(-1)] = { columns: [element, { component: 'block' }] }
    store.value.active = path.concat(['columns', '1'])
  }
  if (isLine && dir === 'left') {
    const element = path.reduce((acc, p) => acc[p], template.value)
    layoutNode[path.at(-1)] = { columns: [{ component: 'block' }, element] }
    store.value.active = path.concat(['columns', '0'])
  }
}
const blockDel = (path = store.value.active) => {
  const containerPreviousNode = path.slice(0, -3).reduce((acc, p) => acc[p], template.value)
  const containerNode = containerPreviousNode[path.at(-3)]
  const builderNode = containerNode[path.at(-2)]
  if (builderNode?.length === 1) containerPreviousNode.splice(+path.at(-3), 1)
  else builderNode?.splice(+path.slice(-1), 1)
  store.value.active = []
}
const blockMove = $event => {
  const report = JSON.parse(JSON.stringify(builderReport.value))
  Object.traverse(
    report,
    (v, path) => null,
    (obj, path, root) => {
      delete obj.path
      delete obj.tag_component
      delete obj.data_component
      if (
        (obj.columns?.length === 1 && obj.columns[0]?.component) ||
        (obj.lines?.length === 1 && obj.lines[0]?.component)
      ) {
        const singleNode = path.slice(0, -1).reduce((acc, p) => acc[p], root)
        singleNode.splice(path.at(-1), 1, (obj.columns || obj.lines)[0])
      }
      if (obj.pages?.length === 0 || obj.columns?.length === 0 || obj.lines?.length === 0) {
        const emptyNode = path.slice(0, -1).reduce((acc, p) => acc[p], root)
        emptyNode.splice(path.at(-1), 1)
      }
    },
  )
  template.value = report
  // const from = $event.from.__draggable_context.element.path.concat($event.from.__draggable_context.element.lines ? 'lines' : 'columns', '' + $event.oldIndex)
  const to = $event.to.__draggable_context.element.path.concat(
    $event.to.__draggable_context.element.lines ? 'lines' : 'columns',
    '' + $event.newIndex,
  )
  store.value.active = to
}
const storeActive = $event => {
  if (tab.value === 'theme') return
  store.value.active = $event
}
const pageAdd = (offset = 1) => {
  const pages = template.value.pages
  pages.splice(+store.value.active[1] + offset, 0, {
    lines: [{ component: 'header' }, { component: 'block' }, { component: 'footer' }],
  })
  store.value.active = ['pages', `${+store.value.active[1] + offset}`]
}
const pageDel = (path = store.value.active) => {
  const builderNode = template.value.pages
  builderNode.splice(path[1], 1)
}
const undo = () => {
  if (store.value.undo.length < 2) return
  store.value.redo = store.value.redo.concat(store.value.undo.at(-1))
  store.value.undo = store.value.undo.slice(0, -1)
  store.value.undo_skip = true
}
const undoAll = () => {
  if (store.value.undo.length < 2) return
  store.value.redo = store.value.redo.concat(store.value.undo.slice(1))
  store.value.undo = store.value.undo.slice(0, 1)
  store.value.undo_skip = true
}
const redo = () => {
  if (store.value.redo.length < 1) return
  store.value.undo = store.value.undo.concat(store.value.redo.at(-1))
  store.value.redo = store.value.redo.slice(0, -1)
  store.value.undo_skip = true
}
const redoAll = () => {
  if (store.value.redo.length < 1) return
  store.value.undo = store.value.undo.concat(store.value.redo)
  store.value.redo = []
  store.value.undo_skip = true
}
const saveVersion = async () => {
  if (Object.equal(store.value.versions.at(-1)?.layout, template.value)) return console.log('skip save')
  const version = {
    name: store.value.versions.at(-1)?.name,
    createdAt: new Date().toISOString(),
    user: $root.profile.email || $root.profile['x-hasura-user-id'],
    layout: JSON.parse(JSON.stringify(template.value)),
  }
  const { id } = await templateService.create(version.name, version.layout, dataReportId.value)
  version.id = id
  store.value.versions = [version].concat(store.value.versions)
  store.value.undo = [version.layout]
  store.value.redo = []
  store.value.undo_skip = true
}
const deleteVersion = async version => {
  if (store.value.versions.length === 1) return
  store.value.versions = store.value.versions.filter(v => v !== version)
  await templateService.remove(version.id)
}
const restoreVersion = async version => {
  if (Object.equal(version.layout, template.value)) return
  template.value = version.layout
}
const downloadVersion = () => {
  JSON.stringify(template.value).download(
    `${new URLSearchParams(location.search).get('templateName')}-${new Date().format('YYYY-MM-DD.hhhmmm')}.json`,
  )
}
const uploadVersion = ev => {
  const file = ev.target.files[0]
  const reader = new FileReader()
  reader.onload = e => (template.value = JSON.parse(e.target.result))
  reader.readAsText(file)
}

const refreshAssetInDataPoint = () => {
  const assets = template.value.theme?.assets?.reduce((acc, v) => {
      acc[v.name]=v
      return acc}, {})
  dataReport.value = {...dataReport.value, assets}
}

const removeAsset = (assetIndex) => {
  template.value.theme?.assets.splice(assetIndex, 1)
}

const uploadAsset = (ev, i) => {
  ev.target.files.forEach(file => {
    if (!template.value.theme.assets) template.value.theme.assets = []
    if (i === undefined) i = template.value.theme.assets.length
    const reader = new FileReader()
    reader.onload = e =>
      (template.value.theme.assets[i] = {name: file.name, src: e.target.result, size: Math.round(file.size / 1000) + ' ko' })
    reader.readAsDataURL(file)
  })
}
//! Effects
onMounted(async () => {
  dataReports.value = (await dataReportService.list()).filter(dr => 'builder-context' !== dr.name)
  templates.value = await templateService.list()
  const runId = new URLSearchParams(location.search).get('runId')
  if (runId) {
    isPreviewMode.value = true
    const run = await get(`data.runs.${runId}`)
    templateName.value = run.context.template
    variables.value = {
      fund: run.context.fund_name,
      share: run.context.fund_id,
      lang: run.context.language.toUpperCase(),
      period: run.context.period.charAt(0).toUpperCase() + run.context.period.slice(1),
      date: run.context.date,
    }
    zoom.value = 0.8 // for small screen
  } else {
    dataReportId.value = new URLSearchParams(location.search).get('dataReportId')
    templateName.value = templates.value.find(v => v === new URLSearchParams(location.search).get('templateName')) || templates.value[0]
  }
})
watch(
  templateName,
  async () => {
    if (!templateName.value) return
    const layout = templateName.value
    const url = new URL(window.location)
    url.searchParams.set('templateName', layout)
    window.history.pushState({}, '', url)
    const data = await templateService.all(layout) // await (await fetch(templateName.value)).json()

    // todo: choice version here
    const json = data[0].layout
    dataReportId.value = data[0].dataReportId || dataReports.value[0].id

    const undo = (await idb.get('undo')) || []
    store.value.undo = Object.equal(undo[0], json) ? undo : [json]
    store.value.undo_skip = true
    store.value.versions = data
  },
  { immediate: true },
)
watch(
  [dataReportId, variables],
  async () => {
    isNavTopFilled.value = false
    isDataReportLoading.value = false
    isDataReportError.value = false
    errorMessage.value = ''
    if (!dataReportId.value) return
    if (!Object.keys(variablesOptions.value).length) {
      const builderContextDr = await dataReportService.getByName('builder-context',{})
      if(!builderContextDr){
      errorMessage.value = 'Missing configuration: Data Report \'builder-context\' is missing.'
      isDataReportError.value = true
      isDataReportLoading.value = false
      isNavTopFilled.value = true
      return
      }
      const { data, error } = await dataReportService.run(builderContextDr.id, {}, { preProcess: false, postProcess:false })
      // LEGACY API
      // const { data, errors } = await legacyAPI.query(`query {
      //     funds {
      //       fundId: fund_id
      //       fund_name
      //       shares {
      //         shareId: share_id
      //       }
      //     }
      // }`)
      // NEW API
      // const { data, errors } = await dataReportService.query(`query {
      //   businessData {
      //     funds {
      //       fundId
      //       fund_name
      //       shares {
      //         shareId
      //       }
      //     }
      //   }
      // }`)
      const fundData = data?.businessData?.funds || data?.funds
      const fundMap = fundData.reduce((acc, fund) => {
        fund.shares.forEach(share => (acc[share.shareId] = fund.fund_name))
        return acc
      }, {})
      const search = new URLSearchParams(location.search)
      const metadata = dataReports.value.find(v => v.id === dataReportId.value)
      variablesOptions.value = {
        fund: fundData.map(fund => fund.fund_name).sort(),
        share: fundData.reduce((acc, fund) => {
          acc[fund.fund_name] = fund.shares.map(share => share.shareId).sort()
          return acc
        }, {}),
        lang: ['FR', 'DE', 'EN', 'ES', 'FR', 'FR_BE', 'IT', 'NL', 'NL_BE', 'EN_HK'], //TODO get from API
        period: ['Monthly', 'Quarterly', 'Yearly', 'Custom'],
        date: Array.from({ length: new Date().getMonth() + 1 }, (_, i) =>
          new Date(new Date().getFullYear(), new Date().getMonth() - i, 1).toISOString().slice(0, 7),
        ),
      }
      
      variables.value = {
        fund: variables.value.fund || search.get('fund') || fundMap[metadata.variables?.isinShare],
        share: variables.value.share || search.get('share') || metadata.variables?.isinShare,
        lang: variables.value.lang || search.get('lang') || metadata.variables?.lang?.toUpperCase(),
        period: variables.value.period || search.get('period') || metadata.variables?.period?.capitalize(),
        date: variables.value.date || search.get('date') || metadata.variables?.domain,
      }
      return variables.value.fund = variablesOptions.value.fund[0]
    }
    if (!variablesOptions.value.share[variables.value.fund].includes(variables.value.share))
      return (variables.value.share = variablesOptions.value.share[variables.value.fund][0])
    if (!variables.value.lang)
      return (variables.value.lang = variablesOptions.value.lang[0])
    if (!variables.value.period)
      return (variables.value.period = variablesOptions.value.period[0])
    if (variables.value.period === 'Monthly')
      variablesOptions.value.date = Array.from({ length: new Date().getMonth() + 1 }, (_, i) =>
        new Date(new Date().getFullYear(), new Date().getMonth() - i, 1).toISOString().slice(0, 7),
      )
    if (variables.value.period === 'Quarterly')
      variablesOptions.value.date = Array.from({ length: 4 }, (_, i) =>
        new Date(new Date().getFullYear(), new Date().getMonth() - i * 3, 1).format('YYYY-QQ'),
      )
    if (variables.value.period === 'Yearly')
      variablesOptions.value.date = Array.from({ length: 4 }, (_, i) =>
        new Date(new Date().getFullYear() - i, new Date().getMonth(), 1).format('YYYY'),
      )
    if (variables.value.period !== 'Custom' && !variablesOptions.value.date.includes(variables.value.date))
      return (variables.value.date = variablesOptions.value.date[0])
    const vars = {
      isinShare: variables.value.share,
      lang: variables.value.lang.toLowerCase(),
      period: variables.value.period.toLowerCase(),
      domain: variables.value.date,
      endDate: variables.value.date,
      shareId: variables.value.share,
    }
    const url = new URL(window.location)
    url.searchParams.set('dataReportId', dataReportId.value)
    url.searchParams.set('fund', variables.value.fund)
    url.searchParams.set('share', variables.value.share)
    url.searchParams.set('lang', variables.value.lang)
    url.searchParams.set('period', variables.value.period)
    url.searchParams.set('date', variables.value.date)
    window.history.pushState({}, '', url)
    dataReport.value = {}
    isNavTopFilled.value = true
    isDataReportLoading.value = true
    const { data, error } = await dataReportService.run(dataReportId.value, vars, { postProcess: true, preProcess:true })
    if(error){
      errorMessage.value = error
      isDataReportError.value = true
      isDataReportLoading.value = false
      return
    } 
    dataReport.value = data
    refreshAssetInDataPoint()
    const query = dataReports.value.find(v => v.id === dataReportId.value).query
    const q =
      query.length > 5000
        ? query
            .replace(/\s+/g, ' ')
            .replace(/\s?{\s?/g, '{')
            .replace(/\s?}\s?/g, '}')
            .replace(/\s?:\s?/g, ':')
        : query
    const endpoint = config.graphqlEndpoint
    studioURL.value = `https://studio.apollographql.com/sandbox?endpoint=${encodeURI(
      new URL(endpoint, location.origin).href,
    )}&document=${encodeURI(q)}&variables=${encodeURI(JSON.stringify(vars, null, 2))}&headers=${encodeURI(
      JSON.stringify({ Authorization: `Bearer ${$root.profile.idToken}` }),
    )}`
    isDataReportLoading.value = false
  },
  { immediate: true, deep: true },
)
watch(
  tab,
  async () => {
    const tabHeader = tab.value
    const url = new URL(window.location)
    url.searchParams.set('tab', tabHeader)
    window.history.pushState({}, '', url)
    if (tab.value === 'component') refreshAssetInDataPoint() // ensure refresh asset when switching to component tab
    if (tab.value === 'theme' && !store.value.active.length) return
    if (tab.value !== 'theme' && store.value.active.length) return
    if (tab.value === 'theme') return (store.value.active = [])
    Object.traverse(
      template.value,
      () => null,
      (node, path) => {
        if (store.value.active.length || !node.component || node.component === 'header') return
        store.value.active = path
      },
    )
  },
  { immediate: true },
)
watch(
  [template, dataReport],
  () => {
    const report = JSON.parse(JSON.stringify(template.value))
    const missing = []
    Object.traverse(
      report,
      () => null,
      (obj, path) => {
        if (!obj.component || obj.options) return
        obj.options = {}
        missing.push(path)
      },
    )
    if (missing.length) return (template.value = report)
    Object.traverse(
      report,
      (v, path) => {
        if (!v) return
        const k = path.at(-1)
        // if (k === 'if') {}
        // if (k === 'for') {}
        if (k === 'component') {
          const builderNode = path.slice(0, -1).reduce((acc, p) => acc[p], report)
          builderNode.tag_component = [report.theme.prefix, builderNode.component].filter().join('-')
          builderNode.data_component = builderNode.data_component || builderNode.data_override || dataReport.value
          // builderNode.options = JSON.parse(JSON.stringify(Object.fromEntries(Object.entries(builderNode.options || {}).map(([k, v]) => [k, (v?.split && v.split(".").reduce((acc, p) => acc?.[p], dataReport.value)) || v]))))
          builderNode.options = JSON.parse(
            JSON.stringify(
              Object.fromEntries(
                Object.entries(builderNode.options || {}).map(([k, v]) => [
                  k,
                  dataReport.value?.[v?.split?.('.')[0]]?.[v?.split?.('.').slice(1).join('.')] || v,
                ]),
              ),
            ),
          )
        }
        if (k === 'data') {
          const dataPath = path.reduce((acc, p) => acc[p], report).split('.')
          // const dataValue = dataPath.reduce((acc, p) => acc[p] || {}, dataReport.value)
          const dataValue = dataReport.value?.[dataPath[0]]?.[dataPath.slice(1).join('.')] || v
          const builderNode = path.slice(0, -1).reduce((acc, p) => acc[p], report)
          // builderNode.options = builderNode.options || {}
          builderNode.data_component = builderNode.data_override || dataValue
          builderNode.tag_component = [report.theme.prefix, builderNode.component].filter().join('-')
        }
      },
      (obj, path) => {
        if (obj instanceof Array) return
        obj.path = path
      },
    )
    builderReport.value = report
  }, // .debounce(300),
  { deep: true },
)
watch(
  template,
  () => {
    if (store.value.undo_skip) return (store.value.undo_skip = false)
    store.value.undo = store.value.undo.concat(JSON.parse(JSON.stringify(template.value)))
    store.value.redo = []
  },
  { deep: true },
)
watch(
  () => [store.value.undo, store.value.redo, store.value.versions],
  () => {
    idb.set('undo', JSON.parse(JSON.stringify(store.value.undo)))
    idb.set('versions', JSON.parse(JSON.stringify(store.value.versions)))
    if (store.value.undo.length === 0 || Object.equal(template.value, store.value.undo.at(-1))) return
    template.value = JSON.parse(JSON.stringify(store.value.undo.at(-1)))
  },
  { deep: true },
)
addEventListener('keydown', e => {
  if (e.target.tagName === 'INPUT') return
  if (e.target.tagName === 'TEXTAREA') return
  const fn = () => {
    // if (e.key === 'Backspace') return blockDel()
    // if (e.key === 'ArrowRight') return blockAdd('right')
    // if (e.key === 'ArrowLeft') return blockAdd('left')
    // if (e.key === 'ArrowUp') return blockAdd('top')
    // if (e.key === 'ArrowDown') return blockAdd('bottom')
    if (!(e.metaKey || e.ctrlKey)) return false
    const key = e.key.toLowerCase()
    if (key === '?') return console.log('Help')
    if (key === 'z' && e.shiftKey) return redo()
    if (key === 'z') return undo()
    if (key === 's') return saveVersion()
    return false
  }
  if (fn() === false) return
  e.preventDefault()
  e.stopPropagation()
})
addEventListener('click', e => {
  const href = e.target.getAttribute('href')
  if (href?.startsWith('#')) {
    e.preventDefault()
    e.stopPropagation()
    document.querySelector(href).scrollIntoView()
  }
  if (!e.path.find(el => el.dataset?.builder !== undefined)) return
  const el = document.querySelector('.ring-yellow-400')
  scrollTo({ top: el.getBoundingClientRect().top + window.pageYOffset - 160, behavior: 'smooth' })
})
</script>
