import { GraphQlService } from './GraphQlService'

export class FundService extends GraphQlService {
  async list() {
    return (
      await this.query(`
      query Funds {
        funds {
          shares {
            fund_name
            asof
            fund_id
            responsable
            share_id
            share_letter
            slug
          }
          fund_name
          slug
          fund_id
        }
      }`)
    ).data.funds.sort((a, b) => a.fund_name.localeCompare(b.fund_name))
  }
}

const fundsService = new FundService()
export default fundsService
