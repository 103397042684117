import config from "../../../config";

export function useAttribution() {
  const getShareInfo = async params => {
    console.log('getShareInfo')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query ShareInfoQuery(
          $isinShare: String!
          $asof: Date
          $domain: Domain!
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              ${$root.config.characteristicsSchema || ''}
              axis_of_analysis
              fund_currency
              asof
              slug
              benchmark {
                id
                label
              }
              excessReturn(domain: $domain) {
                excess_return
                performance
                performance_benchmark
                fees
              }
            }
          }
        }`,
      },
    })
    return resp.data.data.businessData.share
  }

  const getAttribution = async params => {
    console.log('getAttribution')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query AttributionQuery(
          $isinShare: String!
          $asof: Date
          $domain: Domain!
          $dimension: String!
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              attribution(domain: $domain, dimension: $dimension)
            }
          }
        }`,
      },
    })
    return resp.data.data.businessData.share.attribution
  }

  const getAttributionHistoryData = async params => {
    console.log('getAttributionHistoryData')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query AttributionDataQuery(
          $isinShare: String!
          $asof: Date
          $domain: Domain!
          $dimension: String!
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              attributionData(domain: $domain, dimension: $dimension)
            }
          }
        }`,
      },
    })
    return resp.data.data.businessData.share.attributionData
  }

  const getAttributionDetails = async params => {
    console.log('getAttributionDetails')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query AttributionDetailsQuery(
          $isinShare: String!
          $asof: Date
          $domain: Domain!
          $dimension: String!
          $additionalDimensions: [String!]
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              attributionDetails(domain: $domain, dimension: $dimension, additionalDimensions: $additionalDimensions)
            }
          }
        }`,
      },
    })
    return resp.data.data.businessData.share.attributionDetails
  }

  const getAttributionDetailsDeep = async params => {
    console.log('getAttributionDetailsDeep')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query AttributionDetailsQuery(
          $isinShare: String!
          $asof: Date
          $domain: Domain!
          $dimension: String!
          $additionalDimensions: [String!]
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              attributionDetailsDeep(domain: $domain, dimension: $dimension, additionalDimensions: $additionalDimensions)
            }
          }
        }`,
      },
    })
    return resp.data.data.businessData.share.attributionDetailsDeep
  }

  return {
    getShareInfo,
    getAttribution,
    getAttributionHistoryData,
    getAttributionDetails,
    getAttributionDetailsDeep,
  }
}
