import { splitFilenameToPathAndFilename } from '../utils/splitFilenameToPathAndFilename'

export default class DockerFileSystem {
  #dockerURL
  #token

  constructor(config) {
    this.#dockerURL = new URL(config.endpoint)
    this.#token = config.token
  }

  async upload({ buffer, filenameWithPath, isPrivateFile }) {
    const { filename, bucketPath } = splitFilenameToPathAndFilename(
      filenameWithPath,
      isPrivateFile
    )

    const res = await fetch(`${this.#dockerURL}filesystem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.#token
      },
      body: JSON.stringify({ buffer, bucketPath, filename })
    })

    if (res.status !== 200) {
      console.log({ resStatus: res.status, resMessage: res.statusText })
    }

    return res.status === 200
  }

  async get({ filenameWithPath }) {
    return await fetch(`${this.#dockerURL}filesystem/${filenameWithPath}`, {
      method: 'GET',
      headers: {
        authorization: this.#token
      }
    })
  }

  getPublicUrl({ filenameWithPath }) {
    return `${this.#dockerURL}filesystem/public/${filenameWithPath}`
  }
}
