import { onMounted, ref } from 'vue'
import templateService from '../../../services/TemplateService'

export function useTemplates() {
  const templates = ref([])
  const loaded = ref(false)

  onMounted(async () => {
    const templatesFoundInApp = Object.entries(window.platform.apps.find(a => a.name === 'digital-edition').pages)
      .filter(([k, v]) => v.additions?.theme)
      .reduce((acc, [k, v]) => {
        acc[k.split('/').slice(-1)] = v.additions.theme
        return acc
      }, {})

    const templatesNames = await templateService.list()

    const templatesFromBuilder = templatesNames.reduce((acc, templatesName) => {
      acc[templatesName] = templatesName
      return acc
    }, {})

    templates.value = {
      ...templatesFoundInApp,
      ...templatesFromBuilder,
    }

    loaded.value = true
  })

  return {
    templates,
    loaded,
  }
}
