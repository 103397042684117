import config from "../../../config";

export function useDates() {
  const getDatesQuery = async (params, query = 'performance') => {
    const dateFieldName = `${query}Dates`
    const queryString = `
      query ${dateFieldName}Query($isinShare: String!) {
        businessData {
          share(shareId: $isinShare) {
            ${dateFieldName}
          }
        }
      }
    `
    console.log('getDatesQuery')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: queryString,
      },
    })
    return resp.data.data.businessData.share[dateFieldName]
  }
  return {
    getDatesQuery,
  }
}
