<template>
  <lgi-block class="relative" :data="data" :options="options">
    <div class="nx-legend flex flex-row gap-2 absolute right-0 top-2 px-8 w-[200px]" v-if="options.legend">
      <div class="flex gap-1 items-center" v-for="(k, i) in options.y">
        <div class="w-3 h-1" :class="k"></div>
        <div>{{ options.data.translation[k] || k }}</div>
      </div>
    </div>
    <div class="w-full h-full mt-4">
      <nx-line :data="dataFilteredRebased" :options="options"></nx-line>
    </div>
  </lgi-block>
</template>

<script setup>
const { data, options } = defineProps(['data', 'options'])
const firsts = options.y.map(k => data.find(v => v[k]))
const dataFilteredRebased = data
  .filter(v => !options.filter || v[options.x] > options.filter)
  .map((v) => {
    const item = {}
    item[options.x] = new Date(v[options.x])
    if (options.rebase) options.y.forEach((k, i) => (item[k] = +v[k] / +firsts[i][k]))
    else options.y.forEach(k => (item[k] = +v[k]))
    if (options.unit === '%') options.y.forEach(k => (item[k] = item[k] * 100))
    return item
  })
</script>

<script>
export default {
  api: {
    x: {
      label: 'X Axis',
      autocomplete: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Date || /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/.test(v))
          .map(([k, v]) => k),
      default: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Date || /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/.test(v))
          .map(([k, v]) => k)[0],
      attrs: {
        required: true,
      },
    },
    x_format: {
      label: 'X Axis Format',
      default: () => '%Y',
    },
    x_step: {
      label: 'X Axis Step',
      default: () => 1,
    },
    y: {
      label: 'Y Axis',
      autocomplete: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Number)
          .map(([k, v]) => k),
      default: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Number)
          .map(([k, v]) => k),
      attrs: {
        required: true,
        multiple: true,
      },
    },
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    filter: {
      label: 'Filter',
      attrs: {
        placeholder: 'YYYY-MM-DD',
      },
    },
    rebase: {
      label: 'Rebase',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    legend: {
      label: 'Legend',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
}
</script>

<style scoped>
.nx-line path {
  stroke: rgb(var(--primary))!important;
}
.nx-legend > div:first-of-type > div:first-of-type {
  background: rgb(var(--primary));
}
.nx-legend > div:nth-of-type(2) > div:nth-of-type(1) {
  background: rgb(var(--secondary));
}
.nx-legend > div:nth-of-type(3) > div:nth-of-type(1)  {
  background: green;
}
.nx-legend > div:nth-of-type(4) > div:nth-of-type(1) {
  background: orange;
}
.nx-legend > div:nth-of-type(5) > div:nth-of-type(1)  {
  background: brown;
}
</style>
