<style scoped>
.page {
  justify-content: flex-start;
  place-content: center;
  place-items: center;
}
.page-content {
  max-width: 920px !important;
  width: 100%;
}
h1 {
  font-size: 56px;
}
h3 {
  margin-bottom: 32px !important;
}
.lang-menu {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>

<template lang="pug">
.page-content
  h1 {{ t.title }}
    //- input(placeholder="Search ...")
  h3 {{ t.subtitle }}
  .column 
    app-card(:data="app" v-for="app in apps")
  .lang-menu
    lang-selector(:hide-label="true")
</template>
<script>
export default {
  computed: {
    apps() {
      return Object.entries(this.$config.apps)
        .filter(
          ([k, v]) =>
            $root.profile.role === 'admin' ||
            ($root?.profile?.applications || []).includes(k) ||
            ($root?.profile?.role === 'user' && k === 'digital-edition'),
        )
        .filter(([_, v]) => v.listed)
        .map(([k]) => k)
    },
  },
}
</script>
