<template>
  <story-template :story="story" class="h-80 flex-col" :max-examples="5">
    <nx-grid :state="state" class="bg-[#f9f9f9]">
      <template #cell="{ cell, row, header }">
        <div
          class="absolute inset-0 flex h-full items-center bg-white opacity-0 focus-within:opacity-100"
          v-if="selectedCell && selectedCell.id === row.id && selectedCell.header.label === header.label"
        >
          <template v-if="selectedCell.type === 'list'">
            <nx-autocomplete
              nx-input
              class="!m-0 h-full"
              v-bind="selectedCell.autocompleteProps"
              @change="okCascader"
            />
          </template>
          <input
            nx-input
            type="checkbox"
            class="px-2"
            v-model="selectedCell.value"
            v-else-if="selectedCell.type === 'boolean'"
          />
          <input
            nx-input
            type="date"
            @keyup="okPicker"
            class="px-2"
            v-model="selectedCell.value"
            v-else-if="selectedCell.type === 'date'"
          />
          <input
            nx-input
            type="datetime-local"
            @keyup="okPicker"
            step="1"
            class="px-2"
            v-model="selectedCell.value"
            v-else-if="selectedCell.type === 'datetime'"
          />
          <input
            nx-input
            type="number"
            step="any"
            class="px-2"
            v-model="selectedCell.value"
            v-else-if="selectedCell.type === 'number'"
          />
          <input nx-input type="text" class="px-2" v-model="selectedCell.value" v-else />
        </div>
        <div class="truncate px-2" :class="[cell_class(cell, row, header, state)]">
          {{ cell_format(cell, row, header, state) }}
        </div>
      </template>
      <template #buttons-left="{ item, isItemHover }">
        <div
          class="mt-px flex h-4 w-4 cursor-pointer items-center justify-center rounded-full text-indigo-500 opacity-0 hover:bg-light-200 group-hover/row:opacity-100"
        >
          <div class="i-[ic/baseline-open-in-full] !h-3 !w-3"></div>
        </div>
      </template>
      <template #buttons-right="{ item, isItemHover }">
        <div class="flex h-full items-center opacity-0 group-hover/row:opacity-100">
          <div class="h-full w-[8px] bg-gradient-to-r from-transparent to-light-100"></div>
          <div class="flex items-center bg-light-100">
            <div class="h-full w-[4px]"></div>
            <div
              class="mt-px flex h-4 w-4 cursor-pointer items-center justify-center rounded-full text-indigo-500 hover:bg-light-200"
            >
              <div class="i-[ic/baseline-delete-outline] !h-[14px] !w-[14px]"></div>
            </div>
            <div
              class="mt-px flex h-4 w-4 cursor-pointer items-center justify-center rounded-full text-indigo-500 hover:bg-light-200"
            >
              <div class="i-[mdi/content-duplicate] !h-3 !w-3"></div>
            </div>
            <div
              class="mt-px flex h-4 w-4 cursor-pointer items-center justify-center rounded-full text-indigo-500 hover:bg-light-200"
            >
              <div class="i-[ic/baseline-add] !h-4 !w-4"></div>
            </div>
            <div class="w-[10px]"></div>
          </div>
        </div>
      </template>
    </nx-grid>
  </story-template>
</template>

<script setup lang="ts">
import {
  INxGridProps,
  useGridState,
  IGridCellData,
  IGridItemData,
  IGridHeader,
  IGridState,
  useGridAPI,
} from '@hauru/common'
import { createStory, controls } from '@storytime'
import { onMounted, watch, computed } from 'vue'
import { generateTree } from './nx-grid.story'

const props = defineProps<{ isPreview: boolean }>()
const actions = props.isPreview
  ? []
  : ['hide', 'group', 'sort', 'space', 'transpose', 'pivot', 'plot', 'find', 'filter', 'density', 'info']

function cell_class(cell: IGridCellData, row: IGridItemData, header: IGridHeader | undefined, state: IGridState) {
  if (header?.label === 'k') return
  const column = state?.metadata?.columns?.[row?.k || header?.label] || {}
  return column.type === 'number' ? 'ml-auto' : ''
}

function cell_format(cell: IGridCellData, row: IGridItemData, header: IGridHeader, state: IGridState) {
  if (header?.label === 'k') return cell
  try {
    const column = state?.metadata?.columns?.[row?.k || header?.label] || {}
    const pattern = column.pattern || (v => v)
    return pattern(cell)
  } catch (e) {
    console.error('cell_format', e)
    return cell
  }
}

let levels = 0
let columnLevels = 1
let minGroupRows = 1
let maxGroupRows = 10
let createNodeProbability = 80
let rows = props.isPreview ? 7 : 100

function okPicker(event) {
  // console.log('okPicker', event)
  if (event.key === 'Enter') onKeydownWrapper(state)({ key: 'Enter', preventDefault: () => {} })
}
function okCascader(value) {
  // console.log('okCascader', value)
  window.skip = true
  if (value[0]) selectedCell.value.value = value[0]
  onKeydownWrapper(state)({ key: 'Enter', preventDefault: () => {} })
}
function onKeydownWrapper(state: IGridState) {
  return function onKeydown(event: KeyboardEvent) {
    const { enable, range } = state.selection
    const isSelected = enable.cells && range.fromRow !== null
    const gridInput = $('[tabindex="0"]', state.ref)
    const cask = $(`[nx-cell-row="${range.fromRow}"][nx-cell-column="${range.fromColumn}"] [nx-input] input`, state.ref)
    const cellInput = $(`[nx-cell-row="${range.fromRow}"][nx-cell-column="${range.fromColumn}"] [nx-input]`, state.ref)
    const cellActive = document.activeElement === cellInput || document.activeElement?.parentNode === cellInput
    const validateKeys = ['Enter', 'Tab'].includes(event.key)
    const controlKeys = ['Control', 'Shift', 'Alt', 'Meta'].includes(event.key)
    const moveKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)
    const ok = () => {
      event.preventDefault()
      event.shiftKey ? state.selection.selectLeft() : state.selection.selectRight()
      gridInput.focus()
      setTimeout(() => gridInput.focus(), 100) // HACK for cascader onclick not focusing correctly after saving
    }
    const cancel = () => {
      selectedCell.value.value = selectedCell.value?.initial
      event.preventDefault()
      gridInput.focus()
    }
    if (event.key === 'Escape') return cancel()
    if (event.key === 'Enter' && !cellActive && window.skip) return (window.skip = false) // HACK for cascader to avoid selecting next cell in edit mode
    console.log('cellActive', cellActive, event.key)
    if (cellActive) {
      if (validateKeys && cask && event.target) {
        const firstOption = $('.ant-cascader-menu-item')?.innerText
        if (!firstOption) return ok()
        const override = selectedCell.value.autocompleteProps.data.find(obj => Object.values(obj)[0] === firstOption)
        selectedCell.value.value = Object.keys(override)[0]
        return ok()
      }
      if (validateKeys) return ok()
      return
    }
    if (!cellActive && !controlKeys && !moveKeys) {
      if (event.key === ' ') event.preventDefault()
      if (cask) return cask.focus(), cask.click()
      if (cellInput.showPicker)
        cellInput.onfocus = function () {
          this.showPicker()
        }
      cellInput.focus()
      if (cellInput.type === 'checkbox' && event.key === ' ') cellInput.click()
      return
    }
    // if (event.key === 'Enter' && !cellActive) return event.preventDefault(), cellInput.focus()
    // if (event.key === ' ' && !cellActive) return event.preventDefault(), cellInput.focus()
    const runEvent = () => {
      // if (event.key === 'Escape') return state.selection.clear()
      // if (event.key === 'Enter') return state.selection.selectDown(event.shiftKey)
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'a' || event.key === 'A') return state.selection.selectAll()
        if (event.key === 'c' || event.key === 'C') return state.emit('copy')
      }
      if (isSelected) {
        if (event.key === 'ArrowUp') return state.selection.selectUp(event.shiftKey)
        if (event.key === 'ArrowDown') return state.selection.selectDown(event.shiftKey)
        if (event.key === 'ArrowLeft') return state.selection.selectLeft(event.shiftKey)
        if (event.key === 'ArrowRight') return state.selection.selectRight(event.shiftKey)
        if (event.key === 'Tab' && event.shiftKey) return state.selection.selectLeft()
        if (event.key === 'Tab') return state.selection.selectRight()
      }
      if (event.key === 'ArrowUp') return state.scroll.offsetTop(-state.nodes.rowHeight)
      if (event.key === 'ArrowDown') return state.scroll.offsetTop(state.nodes.rowHeight)
      if (event.key === 'ArrowLeft') return state.scroll.offsetLeft(-state.columns.width)
      if (event.key === 'ArrowRight') return state.scroll.offsetLeft(state.columns.width)
      return 'DEFAULT'
    }
    const result = runEvent()
    if (result !== 'DEFAULT') event.preventDefault()
  }
}
const state = useGridAPI({
  actions,
  rowHeight: 18,
  columnWidth: 140,
  freezedColumnsCount: 0,
  sortDefault: true,
  showScrollbars: !props.isPreview,
  selectionMode: 'exclusive',
  onKeydownWrapper,
})
const selectedCell = computed(() => {
  const range = state.selection.range
  if (range.toColumn !== null || range.fromRow === null || range.fromColumn === null || !range.node?.rows) return
  const id = range.node.rows[range.fromRow]
  const header = state.columns.visibleAll[range.fromColumn]
  if (!state.data[id]) return
  const value = state.data[id][header.label]
  const column = state?.metadata?.columns?.[header.label]
  const type = column?.type
  const list = column?.list || []
  const formatFn = {
    datetime: value => new Date(value).toISOString().slice(0, -5),
    date: value => new Date(value).toISOString().slice(0, 10),
    time: value => new Date(value).toISOString().slice(11, -5),
  }[type]
  const formatted = formatFn ? formatFn(value) : value
  const autocompleteProps = {
    data: list.map(k => ({ [k]: k })),
    options: {},
    value,
    disabled: false,
    multiselect: false,
  }
  return { id, header, value: formatted, initial: formatted, formatFn, column, type, list, autocompleteProps }
})
watch(selectedCell, (next, prev) => {
  if (!prev || prev?.initial === prev?.value) return
  console.log('save', prev.value)
  const { id, header, value } = prev
  const data = JSON.parse(JSON.stringify(state.data))
  data[id][header.label] = value
  state.setGrid({ data, metadata: state.metadata })
})

onMounted(() => state.calculateNodes())

const story = createStory<INxGridProps>('nx-grid')
story.setProps({
  type: controls.select({ simple: 'simple', custom: 'custom' }),
})
story.createState('State', {
  // rowHeight: controls
  //   .number(() => state.nodes.rowHeight)
  //   .onChange(n => state.nodes.setRowHeight(n) && state.nodes.calculateDimensions())
  //   .it('Height of row in px'),
  // rowGap: controls
  //   .number(() => state.nodes.rowGap)
  //   .onChange(n => state.nodes.setRowGap(n) && state.nodes.calculateDimensions())
  //   .it('Gap between rows in px'),
  // nodeGap: controls
  //   .number(() => state.nodes.nodeGap)
  //   .onChange(n => state.nodes.setNodeGap(n) && state.nodes.calculateDimensions())
  //   .it('Gap between nodes in px'),
  // columnWidth: controls
  //   .number(() => state.columns.width, 0)
  //   .onChange(n => state.columns.setWidth(n))
  //   .it('Width of column in px'),
  // freezedColumns: controls
  //   .number(() => state.columns.freezedCount, 0)
  //   .onChange(n => state.columns.setFreezedCount(n))
  //   .it('Number of freezed columns'),
  // nodeOffset: controls
  //   .numbers({
  //     top: controls.number(18),
  //     right: controls.number(8),
  //     bottom: controls.number(8),
  //     left: controls.number(8),
  //   })
  //   .onChange(n => {
  //     state.nodes.setNodeOffset([n.top.value, n.right.value, n.bottom.value, n.left.value])
  //     state.calculateNodes()
  //   })
  //   .it('Inner offset of each grid node in px'),
  // containerOffset: controls
  //   .numbers({
  //     top: controls.number(0),
  //     right: controls.number(0),
  //     bottom: controls.number(0),
  //     left: controls.number(0),
  //   })
  //   .onChange(n => {
  //     state.nodes.setContainerOffset([n.top.value, n.right.value, n.bottom.value, n.left.value])
  //     state.calculateNodes()
  //   })
  //   .it('Inner offset of each container in px'),
  excludeIdColumn: controls
    .boolean(() => state.columns.excludeId)
    .onChange(show => state.columns.toggleExcludeId(show))
    .it('Boolean indicating whether to show id column'),
})
story.createState('Data Playground', {
  // theme: controls
  //   .select({ nx: 'nx', visage: 'visage', none: '$default' })
  //   .onChange(theme => {
  //     themeConfig.setTheme(theme)
  //     nextTick(() => state.calculateNodes())
  //   })
  //   .it('Theme to apply to all components'),
  rows: controls
    .number(100, 0, 1000000, 1)
    .onChange(n => {
      rows = n
      if (rows <= 1000) regenerateTree()
    })
    .it('Number of rows in grid'),
  levels: controls
    .number(levels, 0, 10, 1)
    .onChange(n => {
      levels = n
      if (rows <= 1000) regenerateTree()
    })
    .it('Depth of the tree in levels'),
  columnLevels: controls
    .number(columnLevels, 1, 3, 1)
    .onChange(n => {
      columnLevels = n
      if (rows <= 1000) regenerateTree()
    })
    .it('Depth of columns'),
  minGroupRows: controls
    .number(minGroupRows, 0, 1000000, 1)
    .onChange(n => {
      minGroupRows = n
      if (rows <= 1000) regenerateTree()
    })
    .it('Minimum number of rows in group'),
  maxGroupRows: controls
    .number(maxGroupRows, 0, 1000000, 1)
    .onChange(n => {
      maxGroupRows = n
      if (rows <= 1000) regenerateTree()
    })
    .it('Maximum number of rows in group'),
  createNodeProbability: controls
    .number(createNodeProbability, 0, 100, 1)
    .onChange(n => {
      createNodeProbability = n
      if (rows <= 1000) regenerateTree()
    })
    .it('Probability of creating node in group'),
})
story.addActions([controls.button('Regenerate data', () => regenerateTree())])

story.createState('Shortcuts', {
  'esc': controls.boolean(true).it('Deselects all current selections'),
  'arrows': controls.boolean(true).it('If cell is selected : Cell selection to the direction of the arrow, otherwise, navigation'),
  'pageUp': controls.boolean(true).it('Scrolls up by one page (On mac : Fn + Arrow Up)'),
  'pageDown': controls.boolean(true).it('Scrolls down by one page (On mac : Fn + Arrow Down)'),
  'home': controls.boolean(true).it('Scrolls to first column (On mac : Fn + Arrow Left)'),
  'end': controls.boolean(true).it('Scrolls to last column (On mac : Fn + Arrow Right)'),
  'tab': controls.boolean(true).it('Select next cell'),
  'shiftTab': controls.boolean(true).it('Select previous cell'),
  'ctrA': controls.boolean(true).it('Select all'),
  'shiftClick': controls.boolean(true).it('Select up to'),
  'shiftArrows': controls.boolean(true).it('Extend range selection'),
})

story.createDefaultExample('Simple')

story
  .createExample('Complex')
  // .setExampleState('State', {
  //   freezedColumns: 0, // BUG with multiple headers and freezed columns
  //   rowHeight: 22,
  //   nodeGap: 4,
  //   nodeOffset: {
  //     top: 22,
  //     right: 4,
  //     bottom: 4,
  //     left: 4,
  //   },
  //   containerOffset: {
  //     top: 8,
  //     right: 12,
  //     bottom: 8,
  //     left: 4,
  //   },
  // })
  .setExampleState('Data Playground', {
    levels: 3,
    columnLevels: 3,
  })

regenerateTree()

// setTimeout(console.clear, 1000)
window.state = state
window.story = story

// TODO: debounce this function or fix createState onChange controls
function regenerateTree() {
  console.time('generateTree')
  const tree = generateTree({
    rows,
    levels,
    columnLevels,
    minGroupRows,
    maxGroupRows,
    createNodeProbability,
  })
  console.timeEnd('generateTree')
  story.props.grid = tree
  state.setGrid(story.props.grid)
}
</script>
