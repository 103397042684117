import { onMounted, ref } from 'vue'
import fundService from '../../../services/FundService'

export function useFunds() {
  const funds = ref([])
  const loaded = ref(false)
  onMounted(async () => {
    funds.value = await fundService.list()
    loaded.value = true
  })

  return {
    funds,
    loaded,
  }
}
