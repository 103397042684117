<template>
    <lgi-block :data="data" :options="options">
      <table :class="{ '!table-auto': options.auto, 'leading-[1.5]': options.compact }" class="table-fixed bottom-0 h-full w-full border-separate border-spacing-[2px]" v-if="data.constructor === Array">
        <tr :style="{ 'background': i === 0 ? options.layout.theme.primary : 'rgb(244 244 245)'}" class="first:font-bold first:text-white bg-zinc-100" v-for="line, i in (options.header ? [Object.keys(data[0]).map(k => $root.t[k] || k)] : []).concat(data.slice(0, options.limit || Infinity))">
          <td :class="{ 'text-red-500': parseFloat(cell) < 0 }" class="first:font-bold first:text-left px-2 py-0 text-center" v-for="cell in line">
            {{ typeof cell === 'number' ? format(cell, options.digit + options.unit) : cell }}
          </td>
        </tr>
        <tr v-if="getOther" :style="{ 'background': i === 0 ? options.layout.theme.primary : 'rgb(217 217 217)'}">
          <td class="first:font-bold first:text-left px-2 py-0 text-center"> 
          Other
          </td>
          <td class="first:font-bold first:text-left px-2 py-0 text-center"> 
          {{  format(getOther, options.digit + options.unit) }}
          </td>
        </tr>
        <tr v-if="options.total" :style="{ 'background': i === 0 ? options.layout.theme.primary : 'rgb(217 217 217)'}">
          <td class="first:font-bold first:text-left px-2 py-0 text-center"> 
          Total
          </td>
          <td class="first:font-bold first:text-left px-2 py-0 text-center"> 
          {{  format(getTotal, options.digit + options.unit) }}
          </td>
        </tr>
      </table>
    </lgi-block>
  </template>
  
  <style>
  .allocation-top-10-bond-holdings table td:not(:first-child) {
    width: 120px;
  }
  </style>
  
  <script setup>
  import format from '../../format.js'
  import {computed} from "vue"
  const { data, options } = defineProps(['data', 'options'])

  const getTotal = computed(() =>{
    if(options.total){
      const displayedRows = options.other ?  data : data.slice(0, options.limit || Infinity);
      const sum = displayedRows.reduce((a,v)=>{
        a = a+v.percentNav
       return a;
      },0)
      return sum
    }
  })
  const getOther = computed(() =>{
    if(options.other && options.limit){
      const nonDisplayedRows = data.slice( options.limit );
      const sum = nonDisplayedRows.reduce((a,v)=>{
        a = a+v.percentNav
       return a;
      },0)
      return sum
    }
  })
  </script>
  
  <script>
  export default {
    api: {
      other: {
        label: 'Show Other',
        default: () => false,
        attrs: {
          type: 'checkbox',
          class: '',
        }
      },
      total: {
        label: 'Show Total',
        default: () => false,
        attrs: {
          type: 'checkbox',
          class: '',
        }
      },
      unit: {
        label: 'Unit',
        default: () => '%',
      },
      digit: {
        label: 'Decimals',
        default: () => 1,
        attrs: {
          type: 'number',
        }
      },
      limit: {
        label: 'Limit',
        attrs: {
          type: 'number',
        }
      },
      header: {
        label: 'Header',
        default: () => false,
        attrs: {
          type: 'checkbox',
          class: '',
        }
      },
      auto: {
        label: 'Autosize',
        default: () => false,
        attrs: {
          type: 'checkbox',
          class: '',
        }
      },
      compact: {
        label: 'Compact',
        default: () => false,
        attrs: {
          type: 'checkbox',
          class: '',
        }
      },
    },
  }
  </script>
