<style scoped>
.hbar-overflow { height: 180px;overflow: hidden;min-width: 100%;flex: 0 0 auto;flex-wrap: wrap;margin: 8px -8px 0px -8px; }
.hbar-block { height: 164px;min-width: 400px!important;margin: 4px 8px 8px 8px;padding: 0 16px;flex: 1 0 0; }
.hbar-block select { width: 80%!important;height: 40px;font-size: 120%;font-weight: bold;margin-left: auto;background: white no-repeat right 8px center / 20px var(--chevron-down)!important;--input: rgb(242, 243, 244);--chevron-down: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%23222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" shape-rendering="geometricPrecision"><path d="M6 9l6 6 6-6"/></svg>');--external-link: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%2305f" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" shape-rendering="geometricPrecision"><path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6"/><path d="M15 3h6v6"/><path d="M10 14L21 3"/></svg>'); }
.hbar-block input[type="checkbox"] { width: 20px;height: 20px;margin-right: 8px;pointer-events: none; }
.hbar-block .row { width: 100%;cursor: pointer; }
.hbar-block > .row:first-child { border-bottom: 1px solid var(--gray1);margin-bottom: 8px; }
.hbar { height: 160px;overflow: auto;font-size: 80%; }
.hbar > .row:not(.active) { opacity: .4; }
.hbar > .row:hover { background: var(--highlight); }
.hbar .key { flex: 1;max-width: 50%;text-align: right;white-space: pre;overflow: hidden;text-overflow: ellipsis; }
.hbar .value { font-weight: bold; }
.hbar .rect { max-width: 140px;height: 9px;margin: 0 4px;padding: 1px;background: var(--primary);border-radius: 2px; }
.hbar .not_reached .rect { background: #000000; }
.hbar .queued .rect { background: var(--inactive); }
.hbar .wait_predecessor .rect { background: #757575; }
.hbar .skipped .rect { background: var(--yellow1); }
.hbar .running .rect { background: var(--cat10); }
.hbar .wait_validation .rect { background: #8A4FAB; }
.hbar .validation_refused .rect { background: var(--negative); }
.hbar .finished .rect { background: var(--positive); }
.hbar .success .rect { background: var(--positive); }
.hbar .error .rect { background: var(--negative); }
</style>

<template lang="pug">
.row.hbar-overflow
  .block.hbar-block(v-for="dimension, i in selected_dimensions")
    .row.center.left(@click="update_query({ [dimension]: $root.query[dimension] ? null : distribution_dimensions[dimension].keys().map(k=>k.replace('|', '~')).join('|') })")
      input(:tt="t.select_all" type="checkbox" :checked="$root.query[dimension] && $root.query[dimension] === distribution_dimensions[dimension].keys().map(k => k.replace('|', '~')).join('|')" v-indeterminate="$root.query[dimension] && $root.query[dimension] !== distribution_dimensions[dimension].keys().map(k => k.replace('|', '~')).join('|')")
      select(v-model="selected_dimensions[i]" @click.stop="")
        option(:value="dimension" v-for="dimension in dimensions") {{ t[dimension] || dimension }}
    .hbar
      .row.center.left(:class="[key, { active: !filters[dimension] || filters[dimension].includes(key.replace('|', '~')) }]" @click="update_filter(dimension, key.replace('|', '~'))" v-for="[key, value] in distribution_dimensions[dimension].map((v, k) => [k, v]).v().sort(dimensions_sort[dimension] || (([k, v]) => -v)).slice(0, dimensions_slice[dimension] || Infinity) || -0")
        input(type="checkbox" :checked="filters[dimension] && filters[dimension].includes(key.replace('|', '~'))")
        .key {{ t[key] || key }}
        .rect(:style="{ width: value / distribution_dimensions[dimension].v().sum() * 240  + 'px' }")
        .value {{ value }}
</template>

<script>
import xfilter from '../../../features/xfilter'
export const additions = {"props":["options"]}
export default {
  mixins: [xfilter],
  directives: {
    indeterminate(el, binding) {
      el.indeterminate = binding.value
    },
  },
  data() {
    const dimensions = ['date', 'step', 'workflow_name'].concat(this.$parent.exported_columns.__.filter(d => !['id', 'domain', 'workflow' ].includes(d)))
    return {
      selected_dimensions: ['date', 'step', 'workflow_name', 'template'],
      dimensions,
      dimensions_slice: {},
      dimensions_sort: {
        date: (a, b) => a[0] < b[0] ? 1 : -1,
        last_update: ([k, v]) => -1 * ['last_24h', 'last_48h', 'last_7d', 'last_30d', 'more_30d', 'not_started'].reverse().indexOf(k),
      },
    }
  },
  computed: {
    distribution_dimensions() {
      return this.xfilter(this.$parent.production, {
        inc: acc => (acc || 0) + 1,
        dimensions: this.aggregate_dimensions,
        filters: this.filters,
      })
    },
    aggregate_dimensions() {
      return this.selected_dimensions.concat(this.dimensions.filter(d => !this.selected_dimensions.includes(d) && this.filters.keys().includes(d)))
    },
    filters() {
      return this.dimensions.reduce((acc, k) => {
        acc[k] = $root.filters[k]
        return acc
      }, {})
    },
  },
  created() {
    const settings = this.$root.db.settings && this.$root.db.settings[this.$root.idmail] && this.$root.db.settings[this.$root.idmail] || {}
    if (settings.dimensions) this.selected_dimensions = settings.dimensions
    this.$watch('selected_dimensions', dimensions => set('settings.' + this.$root.idmail + '.dimensions', dimensions), { deep: true })
  },
}
</script>
