<script>
import { isTrue } from '../../../../utils/characteristics'

export const additions = { calendar: 'period', icon: 'ic_dashboard' }
import { useDates } from '../../composables/useDates'
import { useProcessTable } from '../../composables/useProcessTable'
import config from "../../../../config";
const { getDatesQuery } = useDates()
const { processTable } = useProcessTable()

export default {
  data() {
    return {
      displayed_plot: 'performance_historical',
      loaded: false,
      default_domain: '0001-01-01|' + new Date().toISOString().split('T')[0],
      params: {
        isinShare: this.$route.fullPath.split('/')[2].split('-')[1],
        domain: $root.domain.join('|') || this.$route.query.domain,
        lang: this.$route.query.lang || $root.lang,
        period: 'monthly',
      },
      data: {},
      managementCommentDate: null,
      managementComment: {},
    }
  },
  computed: {
    overviewPlot() {
      //todo: check if really needed
      const title = 'de_' + this.displayed_plot
      let options = { format: v => format('.2%')(v * 0.01) }
      let data = []
      if (this.displayed_plot === 'performance_historical') {
        // options = { format: '.2%' }
        // Get the first benchmark in the list, if it's empty, use 'benchmark'
        const benchmark_multiple = this.data.share.characteristics?.benchmark_multiple?.split(',')[0]
        data = this.data.share.analytics.domainHistorical
          .filter(d => d.date >= $root.domain[0] && d.date <= $root.domain[1])
          .reduce((acc, a) => {
            let doc = {}
            if (a.fund) doc.fund = a.fund - 100
            if (a.benchmark && (!benchmark_multiple || benchmark_multiple === 'benchmark'))
              doc.benchmark = a.benchmark - 100
            if (a.benchmark2 && benchmark_multiple === 'benchmark2') doc.benchmark2 = a.benchmark2 - 100
            if (a.benchmark3 && benchmark_multiple === 'benchmark3') doc.benchmark3 = a.benchmark3 - 100
            acc[a.date] = doc
            return acc
          }, {})
      }
      if (this.displayed_plot === 'real_nav') {
        options = { format: ',' + (this.data.share.characteristics?.share_currency || '€') }
        data = this.data.share.analytics.historicalRealNav
          .filter(d => d.date >= $root.domain[0] && d.date <= $root.domain[1])
          .reduce((acc, a) => {
            acc[a.date] = { fund: a.real_nav }
            return acc
          }, {})
      }
      if (this.displayed_plot === 'aum_fund_currency') {
        options = { format: 'M' + this.data.share.characteristics?.fund_currency }
        data = this.data.share.analytics.historicalAum
          .filter(d => d.date >= $root.domain[0] && d.date <= $root.domain[1])
          .reduce((acc, a) => {
            acc[a.date] = { fund: a.aum }
            return acc
          }, {})
      }
      return { title, data, options }
    },
    rootWatcher() {
      return this.$route.query.domain || $root.query.domain
    },
    tables() {
      return {
        characteristics: processTable(this.data.nxPackTables, this.data.share, 'characteristics'),
        customer_informations: processTable(this.data.nxPackTables, this.data.share, 'customer_informations'),
      }
    },
    performanceKpis() {
      const kpis = [
        {
          title: this.performance_type_title(this.data),
          value: this.data.share.analytics.domainPerformance.fund,
        },
      ]
      if (this.data.share.benchmark.label) {
        kpis.push({
          title: 'performance_benchmark',
          value: this.data.share.analytics.domainPerformance[this.data.share.benchmark.label],
        })
      }
      return kpis
    },
    isFictiveMandate() {
      return isTrue(this.data.share.characteristics?.mandat_fictif)
    },
    managers() {
      return this.data.share.characteristics?.managers?.split(',').map(manager => ({
        name: manager,
        description: $root.t[manager],
        img: `${manager
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .trim()
          .replaceAll(' ', '_')}.jpg`,
      }))
    },
    managerIndex() {
      return +$root.query.managers || 1
    },
  },
  watch: {
    async rootWatcher(newRoot, oldRoot) {
      if (newRoot?.toString() === oldRoot?.toString()) return
      this.params.domain = newRoot?.join('|')
      if (this.$route.query.domain === undefined) {
        this.params.domain = this.default_domain
      } else {
        this.params.domain = this.$route.query.domain
      }
      await this.getOverviewData()
    },
    async managementCommentDate(newDate, old) {
      await this.fetchManagementComment(newDate)
    },
  },
  async created() {
    await this.getOverviewData()
  },
  methods: {
    leave(el, done) {
      el.style.opacity = 0
      setTimeout(() => done(), 500)
    },
    togglePlot(name) {
      this.displayed_plot = name
    },
    async fetchManagementComment(date) {
      const resp = await axios({
        url: config.graphqlEndpoint,
        method: 'post',
        data: {
          variables: { ...this.params, domain: date },
          query: `query ManagementCommentQuery(
            $domain: Domain!
            $isinShare: String!
            $lang: Lang!
            $period: ReportPeriod
          ) {
            businessData {
              share(shareId: $isinShare) {
                comment(domain: $domain, lang: $lang, period: $period) {
                  period
                  date
                  value
                }
              }
            }
          }`,
        },
      })
      
      this.managementComment = resp.data.data.businessData.share.comment
      this.managementCommentDate = resp.data.data.businessData.share.comment.date.slice(0, 7)
    },
    changeManagementCommentMonth(date, isAdd) {
      this.managementCommentDate = this.addRemoveMonth(date, isAdd)
    },
    addRemoveMonth(date, isAdd) {
      var d = new Date(date)
      if (isAdd) d.setMonth(d.getMonth() + 1)
      else d.setMonth(d.getMonth() - 1)
      var month = '' + (d.getMonth() + 1)
      var year = d.getFullYear()
      if (month.length < 2) month = '0' + month
      return [year, month].join('-')
    },
    async getOverviewData() {
      this.loaded = false
      if (!$root.dates || $root.dates.isin_share !== this.params.isinShare || $root.dates.query !== 'performance') {
        $root.dates = await getDatesQuery(this.params, 'performance')
        this.managementCommentDate = $root.dates.slice(-1)[0].slice(0, 7)
        $root.dates.isin_share = this.params.isinShare
        $root.dates.query = 'performance'
        $root.refresh = $root.refresh + 1
        this.params.domain = $root.domain.join('|')
      }
      console.log('getOverviewData')
      const resp = await axios({
        url: config.graphqlEndpoint,
        method: 'post',
        data: {
          variables: this.params,
          query: `query OverviewQuery($isinShare: String!, $domain: Domain) {
            businessData {
              share(shareId: $isinShare) {
                fund_name
                shareId
                share_currency
                benchmark {
                  label
                }
                benchmark_id
                management_objective_index
                comparison_index
                inception_date
                positionCount(domain: $domain)
                ${$root.config.characteristicsSchema || ''}
                ${$root.config.settingsSchema || ''}
                ${$root.config.customShareSchema || ''}
                analytics {
                  domainPerformance(domain: $domain) {
                    fund
                    benchmark
                    benchmark2
                    benchmark3
                  }
                  domainHistorical(domain: $domain) {
                    date
                    fund
                    benchmark
                    benchmark2
                    benchmark3
                  }
                  historicalRealNav {
                    date
                    real_nav
                  }
                  historicalAum {
                    date
                    aum
                  }
                  netAssetValue(domain: $domain)
                  aum(domain: $domain){
                    date
                    shareId
                    aum_share_in_fund_ccy
                    aum_fund_in_fund_ccy
                  }
                }
                transactions(domain: $domain) {
                  sale {
                    name
                    var
                  }
                  cutback {
                    var
                    name
                  }
                  purchase {
                    var
                    name
                  }
                  reinforcement {
                    var
                    name
                  }
                }
              }
              nxPackTables {
                name
                table
              }
            }
          }`,
        },
      })
      this.data = resp.data.data.businessData
      this.loaded = true
      this.managementCommentDate = this.params.domain.split('|').reverse()[0].slice(0, 7)
    },
    performance_type_title(data, perf_domain) {
      if (data.share.characteristics?.mandat_is_perf_net || data.share.characteristics?.mandat_is_perf_net === '')
        return 'performance_net'
      return 'performance_brut'
    },
  },
}
</script>
<template lang="pug">
transition(@leave='leave')
  loader(v-if="!this.loaded")
template(v-if="this.loaded")
  h1
    .name {{ t[data.share.fund_name] || data.share.fund_name && data.share.fund_name.titleize() || ''}}
    .share {{ t.share }} {{ data.share.characteristics?.share_letter }}
    .row
      router-link.lines(:to="{ path: 'details', query: $route.query }") {{ data.share.positionCount }} {{ t.lines }}
      .isin {{ data.share.shareId }}
  .row.stretch
    kpi(
      :data="performanceKpis"
      @click="togglePlot('performance_historical')" 
      :class="[{active: this.displayed_plot==='performance_historical'}]"
    )
    kpi(
      :data="[{ title: 'nav', value: format($root.config.formats.nav)(parseFloat(data.share.analytics.netAssetValue)) + ' ' + (data.share.share_currency || '€') }, { title: t.asof + ' ' + new Date($root.domain[1]).format('day, mon, year', $root.lang) }]"
      @click="togglePlot('real_nav')" 
      :class="[{active: this.displayed_plot==='real_nav'}]"
      v-if="!isFictiveMandate"
    )
    kpi(
      :data="[{ title: 'aum', value: format($root.config.formats.aum)(parseFloat(data.share.analytics.aum.aum_share_in_fund_ccy)) + ' ' + (data.share.share_currency || '€') }, { title: 'aum_tot_fund', value: format('M' + data.share.characteristics?.fund_currency)(parseFloat(data.share.analytics.aum.aum_fund_in_fund_ccy)) }, { title: t.asof + ' ' + new Date(data.share.analytics.aum.date).format('day, mon, year', $root.lang) }]"
      @click="togglePlot('aum_fund_currency')" 
      :class="[{active: this.displayed_plot==='aum_fund_currency'}]"
      v-if="!isFictiveMandate"
    )
  .row
    block(
      :title="performance_type_title(data)"
      type="plot-line"
      :data="overviewPlot.data"
      :metadata="{format: overviewPlot.options.format}"
      @rebind="$event.yAxis.formatter(x => format(overviewPlot.options.format)(x))"
      :enableActions="false"
    )
  .row
    .column.expand(v-if="$root.config.displayManagers")
      block.arrows
        template(v-slot:title)
          .row
            div
              svg-icon(
                @click="update_query({ managers: managerIndex - 1 })" 
                :class="{ inactive: managerIndex === 1 }" 
                name="ic_navigate_next" 
                style="transform: rotate(180deg)"
              )
            .title.row 
              div {{ t.managers }}
              div ({{ managerIndex }} / {{ managers?.length }})
            div
              svg-icon(
              @click="update_query({ managers: managerIndex + 1 })"
              :class="{ inactive: managerIndex === managers?.length }" 
              name="ic_navigate_next"
            )
        .row
          .manager(v-if='managers?.[managerIndex-1]')
            img(:src="managers?.[managerIndex-1].img")
            h2 {{ managers?.[managerIndex-1].name }}
            div {{ managers?.[managerIndex-1].description }}
    .column.expand
      block(title="management_orientation")
        div {{ t[data.share.characteristics?.orientation] }}
      block.arrows(v-if="$root.config.displayManagementComment")
        template(v-slot:title)
          .row
            div
              svg-icon(
                @click="changeManagementCommentMonth(managementCommentDate, false)" 
                :class="{ inactive: false }" 
                name="ic_navigate_next" 
                style="transform: rotate(180deg)"
              )
            .title.row 
              div {{ t.management_comment }}
              div ({{ new Date(managementCommentDate).format('month, year', $root.lang).titleize() }})
            div
              svg-icon(
                @click="changeManagementCommentMonth(managementCommentDate, true)" 
                :class="{ inactive: false }" 
                name="ic_navigate_next"
              )
        .row
          div {{managementComment?.value || t.no_data || 'No data'}}
  .row
    block(title="characteristics" v-if="tables.characteristics")
      pdf-table(:data="tables.characteristics" :metadata="{noHeader: true}")
    block.arrows.expand.mgt_bloc(title="commercial_informations" v-if="tables.customer_informations")
      pdf-table(:data="tables.customer_informations" :metadata="{noHeader: true}")
  .row.stretch
    .column
      block(title="main_in" v-if="$root.config.combine_in_out_mvt")
        kpi(:data="data.share.transactions.purchase.concat(data.share.transactions.reinforcement).map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])})).sort('-value')" )
      block(title="purchase_position" v-if="!$root.config.combine_in_out_mvt")
        kpi(:data="data.share.transactions.purchase.map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])}))" )
      block(title="reinforcement_position" v-if="!$root.config.combine_in_out_mvt")
        kpi(:data="data.share.transactions.reinforcement.map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])}))" )
    .column
      block(title="main_out" v-if="$root.config.combine_in_out_mvt")
        kpi(:data="data.share.transactions.sale.concat(data.share.transactions.cutback).map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])})).sort('-value')" )
      block(title="sale_position" v-if="!$root.config.combine_in_out_mvt")
        kpi(:data="data.share.transactions.sale.map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])}))" )
      block(title="cutback_position" v-if="!$root.config.combine_in_out_mvt")
        kpi(:data="data.share.transactions.cutback.map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])}))" )
</template>
<style scoped>
.kpi:hover,
.active {
  cursor: pointer;
  border-color: var(--highlight);
}
.management_orientation {
  text-align: justify;
}
.block-characteristics,
.block-commercial_informations,
.block-Positions {
  min-width: 370px;
}
</style>
