import { GraphQlService } from './GraphQlService'

export class ShareService extends GraphQlService {
  async listAll(expired = false, standby = false, extra_share_characteristics = []) {
    const extra = extra_share_characteristics.map(field => `${field}`).join('\n')
    return (
      await this.query(`
    query Shares {
      businessData {
        shares {
          fundId
          fund_name
          fund_id: slug
          share_id: shareId
          characteristics {
            share_letter
            ${extra}
          }
        }
      }
    }
`)
    ).data.businessData.shares
  }
}

const shareService = new ShareService()
export default shareService
