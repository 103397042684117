import {
  EOffset,
  IGridColumnsState,
  IGridNode,
  IGridNodes,
  IGridView,
  IScrollState,
  minMax,
  useHashList,
} from '@hauru/common'
import { isNil, isNull } from 'lodash'
import { DeepReadonly, WatchStopHandle, onUnmounted, reactive, readonly, watch } from 'vue'

export interface IGridSelectionArgs {
  autoScrollOffset: [number, number, number, number]
  enableCellSelection: boolean
  enableItemSelection: boolean
  /**
   * Exclusive : Only cells or rows can be selected at a time, not both. When we select a cell, it will deselect all rows, and vice versa.
   * Combined : When we select cells, it will also select the rows they belong to.
   * Independent : Cells and rows can be selected independently from one another.
   */
  selectionMode: 'exclusive' | 'combined' | 'independent'
}

/**
 * Enum for representing select directions
 */
export enum EDirection {
  up,
  right,
  down,
  left,
}

export interface IGridRangeSelectState {
  trackChanges: number
  node: IGridNode | null
  row: number | null
  fromColumn: number | null
  fromRow: number | null
  toColumn: number | null
  toRow: number | null
}

export interface IGridSelectOptions {
  autoscroll: boolean
  singleSelectItem?: boolean
}

interface IGridStateLimited {
  nodes: IGridNodes
  columns: IGridColumnsState
  scroll: IScrollState
  views: IGridView[]
}

export type IGridSelectionState = ReturnType<typeof useSelection>
export function useSelection({
  autoScrollOffset = [10, 20, 20, 20],
  enableCellSelection = true,
  enableItemSelection = true,
  selectionMode = 'independent',
}: Partial<IGridSelectionArgs> = {}) {
  let state: IGridStateLimited | undefined

  const items = useHashList<string | number>({
    allowMultiple: true,
  })

  const selection = reactive({
    enable: {
      cells: enableCellSelection,
      items: enableItemSelection,
      selectionMode,
    },
    /**
     * Determines current range selection
     *
     * fromRow > Row / Node selected
     * fromColumn > Column selected
     * fromRow, fromColumn > Cell selected
     * fromRow, fromColumn, toRow, toColumn > Range selected
     */
    range: {
      trackChanges: 0,
      node: null,
      row: null,
      fromRow: null,
      fromColumn: null,
      toRow: null,
      toColumn: null,
    } as IGridRangeSelectState,
    items: {
      lastSelected: { value: null as string | number | null },
      list: items.list,
      is: items.is,
      toggleSelect: (id: string | number, value?: boolean) => {
        selection.items.lastSelected.value = value === undefined || value ? id : null
        if (selectionMode === 'exclusive') selection.select()
        items.toggle(id, value)
      },
      selectAll: () => {
        selection.items.lastSelected.value = null
        if (selectionMode === 'exclusive') selection.select()
        if (!state) return

        for (let x = 0; x < state.nodes.root.total_rows; x++) {
          const id = state.nodes.mapByIndexRow[x]?.id
          if (!isNil(id)) items.add(id)
        }
        selection.items.toggleSelectNode(state.nodes.root, true)
        items.add('##ALL##')
      },
      deselectAll: () => {
        selection.items.lastSelected.value = null
        items.empty()
      },
      selectUntil(to: string | number) {
        const fromId = selection.items.lastSelected.value !== null ? selection.items.lastSelected.value : to
        const fromRow = state?.nodes.mapById[fromId]?.index_row ?? 0
        const toRow = state?.nodes.mapById[to]?.index_row ?? 0
        const [a, b] = fromRow <= toRow ? [fromRow, toRow] : [toRow, fromRow]
        for (let x = a; x <= b; x++) {
          console.log('selecting', x)
          const id = state?.nodes.mapByIndexRow[x]?.id
          if (id) items.add(id)
        }
      },
      toggleSelectNode(node: DeepReadonly<IGridNode>, value?: boolean) {
        const val = value ?? !selection.items.is(node.id)
        selection.items.toggleSelect(node.id, val)

        for (const row of node.rows ?? []) {
          selection.items.toggleSelect(row, val)
        }
        for (const child of node.children) {
          selection.items.toggleSelectNode(child, val)
        }
      },
      moveToLast: (id: string | number) => {
        const fromIndex = items.list.findIndex(item => item === id)
        const toIndex = items.list.length - 1
        items.move(fromIndex, toIndex)
      },
      toggleExclusive: (id: string | number, value?: boolean) => {
        items.empty()
        items.toggle(id, value)
      },
      isAll() {
        return items.is('##ALL##')
      },
    },
    select: (
      { fromRow = null, fromColumn = null, toRow = null, toColumn = null }: Partial<IGridRangeSelectState> = {},
      { autoscroll = false, singleSelectItem = false }: Partial<IGridSelectOptions> = {},
    ) => {
      if (selectionMode === 'exclusive' && (fromRow !== null || fromColumn !== null)) selection.items.deselectAll()

      const isSingleCell = toRow === fromRow && toColumn === fromColumn
      const isRange = !isSingleCell && (toRow !== null || toColumn !== null)
      const newRange = {
        fromRow,
        fromColumn,
        toRow: !isSingleCell ? toRow : null,
        toColumn: !isSingleCell ? toColumn : null,
      }
      if (
        newRange.fromRow !== selection.range.fromRow ||
        newRange.fromColumn !== selection.range.fromColumn ||
        newRange.toColumn !== selection.range.toColumn ||
        newRange.toRow !== selection.range.toRow
      ) {
        selection.range.trackChanges = (selection.range.trackChanges + 1) % 1024
      }

      Object.assign(selection.range, newRange)
      updateSelectedNode()
      if (autoscroll) autoScroll(isRange)
      if (!isNull(fromRow) && !isNull(toRow) && selectionMode === 'combined') {
        const newList: (number | string)[] = []
        const startIndex = fromRow
        const endIndex = toRow
        const step = fromRow < toRow ? 1 : -1

        for (let i = startIndex; i !== endIndex + step; i += step) {
          const id = state?.nodes.mapByIndexRow[i]?.id
          if (!isNil(id)) {
            newList.push(id)
          }
        }

        items.replace(newList)
      } else if (singleSelectItem && !isNull(fromRow)) {
        const id = state?.nodes.mapByIndexRow[fromRow]?.id
        items.replace([id])
      }
    },
    selectUp: (extend = false, singleSelectItem = false) => selectDirection(EDirection.up, extend, singleSelectItem),
    selectDown: (extend = false, singleSelectItem = false) =>
      selectDirection(EDirection.down, extend, singleSelectItem),
    selectLeft: (extend = false) => selectDirection(EDirection.left, extend),
    selectRight: (extend = false) => selectDirection(EDirection.right, extend),
    selectAll: () => {
      if (!state) return

      if (selection.items.list.length) {
        selection.items.selectAll()
        return
      }

      selection.select({
        fromRow: 0,
        fromColumn: 0,
        toRow: state.nodes.root.total_rows - 1,
        toColumn: state.columns.visibleAll.length - 1,
      })
    },
    selectRange,
    selectRangeStart,
    selectRangeEnd,
    /**
     * Offset to apply when auto scrolling vertically or horizontally.
     */
    autoScrollOffset,
    autoScroll,
    restoreSelectionItems,
    setState: (s: IGridStateLimited) => (state = s),
  })

  /**
   * Computes the selected node and row. If a direction is provided, the function will keep the currently selected node and will increment or decrement the selected row.
   *
   * @param singleCellDirection - The direction we travelled in (up or down). If it's left or right, the function will do nothing.
   */
  function updateSelectedNode() {
    if (!state) return

    if (selection.range.fromRow === null) {
      selection.range.node = null
      selection.range.row = null
      return
    }

    const [node, row] = state.nodes.findRowByIndex(selection.range.fromRow)
    Object.assign(selection.range, { node: node ?? null, row: row ?? null })
  }

  /**
   * Automatically adjusts the scroll position to make the currently selected cell visible.
   * Works for both vertical and horizontal scrolling.
   */
  function autoScroll(isRange: boolean) {
    if (!state) return

    // Extract the top and bottom offset values from the state, if they exist.
    const offsetTop = selection.autoScrollOffset?.[EOffset.top]
    const offsetBotom = selection.autoScrollOffset?.[EOffset.bottom]

    const [focusedNode, focusedRow] = isRange
      ? state.nodes.findRowByIndex(selection.range.toRow!)
      : [selection.range.node, selection.range.row]

    // Check if there is a selected node in the state. If not, the function returns immediately.
    if (focusedNode === null || focusedNode === undefined) return

    const focusedRowTop = isNil(focusedRow) ? focusedNode.top : state.nodes.getRowTop(focusedNode, focusedRow)
    const scrollTop = state.scroll.top + offsetTop

    // console.log('autoScroll', focusedRowTop, scrollTop, state.scroll.containerHeight, offsetBotom, state.scroll.rowHeight)
    const scrollBottom = scrollTop + state.scroll.containerHeight - offsetBotom - state.scroll.rowHeight

    if (focusedRowTop < scrollTop) {
      state.scroll.setTop(focusedRowTop - offsetTop)
      state.scroll.throttled.update()
    }
    if (focusedRowTop >= scrollBottom) {
      state.scroll.setTop(focusedRowTop - state.scroll.containerHeight + state.scroll.rowHeight + offsetBotom)
      state.scroll.throttled.update()
    }
    console.log('autoScroll', selection.range)

    const focusedColumn = isRange ? selection.range.toColumn : selection.range.fromColumn

    if (focusedColumn === null || focusedColumn < state.columns.visibleFreezed.length) return

    console.log('autoScroll', selection.range.fromColumn, state.columns.visibleAll.length)
    const offsetLeft = selection.autoScrollOffset?.[EOffset.left]
    const offsetRight = selection.autoScrollOffset?.[EOffset.right]

    const selectedCellLeft = state.columns.visibleUnfreezed
      .slice(0, focusedColumn - state.columns.visibleFreezed.length)
      .reduce((acc, column) => acc + column.width, 0)
    const scrollLeft = state.scroll.left + offsetLeft
    const scrollRight =
      scrollLeft + state.scroll.containerWidth - offsetRight - state.columns.visibleAll[focusedColumn].width

    if (selectedCellLeft < scrollLeft) {
      state.scroll.setLeft(selectedCellLeft - offsetLeft)
      state.scroll.throttled.update()
    }
    if (selectedCellLeft >= scrollRight) {
      state.scroll.setLeft(
        selectedCellLeft - state.scroll.containerWidth + offsetRight + state.columns.visibleAll[focusedColumn].width,
      )
      state.scroll.throttled.update()
    }
  }

  /**
   * Selects a new cell in the provided direction
   *
   * @param direction - The direction to select. It can be up, down, left, or right.
   */
  function selectDirection(direction: EDirection, extend: boolean, singleSelectItem: boolean = false) {
    if (!state) return
    if (selection.range.fromColumn === null && selection.range.fromRow === null) return

    const columnOffset = direction === EDirection.left ? -1 : direction === EDirection.right ? 1 : 0

    if (extend) {
      const toRow =
        state.nodes.findSiblingVisibleRowByIndex(selection.range.toRow ?? selection.range.fromRow!, direction) ??
        selection.range.toRow
      const toColumn = minMax(
        (selection.range.toColumn ?? selection.range.fromColumn!) + columnOffset,
        0,
        state.columns.visibleAll.length - 1,
      )
      selection.select({ ...selection.range, toRow, toColumn }, { autoscroll: true })
      return
    }

    let fromRow =
      state.nodes.findSiblingVisibleRowByIndex(selection.range.fromRow!, direction) ?? selection.range.fromRow
    console.log('selectDirection', fromRow, selection.range.fromRow, direction, selection.range)
    let fromColumn =
      selection.range.fromColumn === null
        ? null
        : minMax(selection.range.fromColumn + columnOffset, 0, state.columns.visibleAll.length - 1)

    if (fromRow === selection.range.fromRow && fromColumn === selection.range.fromColumn) {
      fromRow = (fromRow || 0) + 1
      fromColumn = 0
    }
    selection.select({ fromRow, fromColumn }, { autoscroll: true, singleSelectItem })
  }

  let startScrollLeft = 0
  let startScrollTop = 0

  let madeSelection = false
  let shiftKey = false
  let selectedNode: DeepReadonly<IGridNode>
  let selectedColumn: number
  let referenceNode: DeepReadonly<IGridNode>
  let referenceColumn: number
  let startPageX = 0
  let startPageY = 0
  let cacheEvent: PointerEvent
  let cacheDirectionX = 0
  let cacheDirectionY = 0
  let unwatch: WatchStopHandle = () => {}
  let scrollingAnimation = 0
  let scrollingPreviousTime = 0
  function selectRangeStart(event: PointerEvent, startItem: DeepReadonly<IGridNode>, cell: number, isPicker = false) {
    if (!state) return

    selectRangeEnd(true)
    shiftKey = event.shiftKey
    madeSelection = false
    startPageX = isPicker
      ? event.pageX - state.columns.visibleAll[selection.range.fromColumn!].width
      : event.pageX - event.offsetX
    startPageY = isPicker ? event.pageY - state.nodes.rowHeight : event.pageY - event.offsetY
    if (!shiftKey || !selectedNode) {
      selectedNode = startItem
      selectedColumn = cell
    }
    referenceNode = startItem
    referenceColumn = cell
    startScrollLeft = state.scroll.throttled.left
    startScrollTop = state.scroll.throttled.top
    unwatch = watch([() => state?.scroll.throttled.top, () => state?.scroll.throttled.left], repeatSelectRange)
  }

  function selectRangeEnd(cleanOnStart = false) {
    if (!madeSelection && !cleanOnStart) {
      if (!shiftKey || !selectedNode) {
        selection.select({
          fromRow: selectedNode.index_item - selectedNode.index_item_first_row + selectedNode.index_first_row,
          fromColumn: selectedColumn,
        })
      } else
        selection.select({
          fromRow: selectedNode?.index_item - selectedNode.index_item_first_row + selectedNode.index_first_row,
          fromColumn: selectedColumn,
          toRow: referenceNode.index_item - referenceNode.index_item_first_row + referenceNode.index_first_row,
          toColumn: referenceColumn,
        })
    }
    unwatch()
    cancelAnimationFrame(scrollingAnimation)
    scrollingAnimation = 0
  }

  function repeatSelectRange() {
    // console.log('repeatSelectRange', cacheOffsetX, cacheOffsetY)
    selectRange(cacheEvent)
  }

  /**
   * Selects range of cells starting from an initially selected cell, taking in consideration scrolling of the grid.
   *
   * @param event - Pointer event of the mouse hovering over the current end of the range
   */
  function selectRange(event: PointerEvent) {
    if (!state) return

    // This flag is set to true once a selection is started
    madeSelection = true

    // Store the mouse event, required for repeating the selection when scrolling
    cacheEvent = event
    const offsetX = event.pageX - startPageX
    const offsetY = event.pageY - startPageY

    const selectedColumnLeft = state.columns.visibleAll
      .slice(0, referenceColumn)
      .reduce((acc, column) => acc + column.width, 0)

    const fromRow = selectedNode.index_item - selectedNode.index_item_first_row + selectedNode.index_first_row
    const fromColumn = selectedColumn
    let toRow = fromRow
    let toColumn = fromColumn

    const freezedWidth = state.columns.visibleFreezedWidth.value

    const mouseY = referenceNode.top + offsetY - startScrollTop
    const mouseX = selectedColumnLeft + offsetX - startScrollLeft

    cacheDirectionY = mouseY < 0 ? -1 : mouseY > state.scroll.containerHeight ? 1 : 0
    cacheDirectionX = mouseX < freezedWidth ? -1 : mouseX > state.scroll.containerWidth + freezedWidth ? 1 : 0

    if (scrollingAnimation === 0) runScrollingAnimation()

    // Calculate the current mouse position inside the grid, relative to the scroll position
    const positionY = referenceNode.top + offsetY + state.scroll.throttled.top - startScrollTop
    let positionX = selectedColumnLeft + offsetX
    positionX += positionX > freezedWidth ? state.scroll.throttled.left - startScrollLeft : 0

    let previousTop = positionY >= referenceNode.top ? -Infinity : Infinity

    // Iterate over visible items to select the item (row or node) under the mouse
    // If mouse is outside the bounds of the grid, select the first or last visible item
    for (const view of state.views) {
      // We found the item (row or node) under the mouse
      if (!view.show || view.node.type !== 'row') continue

      // We move downwards, select the last visible item found in a position above the mouse
      if (positionY >= referenceNode.top) {
        if (view.node.top <= positionY && view.node.top >= referenceNode.top && view.node.top >= previousTop) {
          previousTop = view.node.top
          toRow = view.node.index_first_row + view.rowIndex!
        }
      }
      // We move upwards, select the first visible item found in a position below the mouse
      else {
        if (
          view.node.top + view.node.height! >= positionY &&
          view.node.top <= referenceNode.top &&
          view.node.top <= previousTop
        ) {
          previousTop = view.node.top
          toRow = view.node.index_first_row + view.rowIndex!
        }
      }
    }

    let columnLeft = 0
    // Iterate over visible columns to select the column under the mouse
    // If mouse is outside the bounds of the grid, select the first or last visible column
    for (const column of state.columns.visibleAll) {
      // Mouse is to the left of the grid
      if (positionX <= 0) {
        toColumn = 0
        break
      }
      // We found the column under the mouse
      if (columnLeft <= positionX && positionX <= columnLeft + column.width) {
        toColumn = column.index
        break
      }
      // Mouse is to the right of the grid
      toColumn = state.columns.visibleAll.length - 1
      columnLeft += column.width
    }

    // Select the range of cells begining from initially selected cell up to the cell under the mouse
    selection.select({ fromRow, toRow, fromColumn, toColumn })
  }

  function runScrollingAnimation() {
    if (!state) return

    if (cacheDirectionX === 0 && cacheDirectionY === 0) {
      scrollingPreviousTime = 0
      scrollingAnimation = requestAnimationFrame(runScrollingAnimation)
      return
    }

    const offsetX = cacheEvent.pageX - startPageX

    const scrollingCurrentTime = 'now' in window.performance ? performance.now() : new Date().getTime()
    scrollingPreviousTime = scrollingPreviousTime || scrollingCurrentTime
    const timeDiff = scrollingCurrentTime - scrollingPreviousTime

    if (timeDiff > 0) {
      const speedX = 300 // px per second
      const speedY = cacheDirectionY > 0 ? 350 : 900 // px per second
      if (cacheDirectionX < 0 && offsetX > 0) {
        state.scroll.setLeft(0)
        startScrollLeft = 0
      } else state.scroll.offsetLeft((cacheDirectionX * speedX * timeDiff) / 1000)
      state.scroll.offsetTop((cacheDirectionY * speedY * timeDiff) / 1000)
      state.scroll.throttled.update()
      scrollingPreviousTime = scrollingCurrentTime
    }

    scrollingAnimation = requestAnimationFrame(runScrollingAnimation)
  }

  function restoreSelectionItems(storedSelectionItems: number[]) {
    for (const id of storedSelectionItems) {
      selection.items.toggleSelect(id)
    }
  }

  onUnmounted(() => {
    cancelAnimationFrame(scrollingAnimation)
  })

  return readonly(selection)
}
