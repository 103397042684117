const processRow = (row, share) => {
  if (!Array.isArray(row)) {
    return [row, share[row] || share.characteristics[row]]
  }
  if (row[1] === '') {
    return [row[0], share[row[0]] || share.characteristics[row[0]]]
  }
  return row.map(cell => {
    if (!/=>/.test(cell)) return cell
    try {
      cell = formatCell(cell)
      return eval(cell)()
    } catch (e) {
      return ''
    }
  })
}

const formatCell = cell => {
  // ensures backward compatibility
  cell = cell.replace(/\$root.share/g, 'share')
  cell = cell.replace(/management_fee/g, 'characteristics.management_fee')
  cell = cell.replace(/ongoing_fee/g, 'characteristics.ongoing_fee')
  return cell
}

export function useProcessTable() {
  const processTable = (tables, share, tab) => {
    let structure = tables.find(t => t.name === share.settings?.[tab])?.table
    return structure?.map(row => processRow(row, share))
  }
  return {
    processTable,
  }
}
