<template>
  <component
    :is="layout.theme.prefix + '-error'"
    :data="error"
    :options="{ data, layout, store, report, ...report.options }"
    v-if="error"
  ></component>
  <draggable
    v-else
    :style="style"
    :disabled="report.theme || !store.active.length"
    :group="{ name: keys[0] ? 'container' : 'item' }"
    :tag="report.tag_component || 'div'"
    :component-data="
      report.component
        ? { data: report.data_component || {}, options: { data, layout, store, report, ...report.options } }
        : {}
    "
    :list="keys[0]"
    item-key="path"
  >
    <template #item="{ element }">
      <builder
        :class="[
          element?.data?.format('dash'),
          element?.options?.class,
          element.tag_component ? [element.tag_component, 'pdf-block'] : keys[1],
          Object.equal(element.path, store.active) ? 'rounded-md ring-4 ring-yellow-400 z-10' : '',
          Object.equal(element.path, store.active.slice(0, -2)) ? 'rounded-md ring-4 ring-yellow-200 z-10' : '',
        ]"
        :report="element"
        :data="data"
        :layout="layout"
        :store="store"
        @mousedown.stop="$emit('active', element.path)"
        @active="$emit('active', $event)"
        @end="$emit('end', $event)"
      />
    </template>
  </draggable>
</template>

<script setup>
import draggable from 'vuedraggable'
</script>

<script>
export default {
  name: 'builder',
  props: {
    report: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    layout: {
      type: Object,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: false,
    }
  },
  computed: {
    keys() {
      if (this.report.pages) return [this.report.pages, 'pdf-page']
      if (this.report.columns) return [this.report.columns, 'pdf-column']
      if (this.report.lines) return [this.report.lines, 'pdf-line']
      return []
    },
    style() {
      if (this.report.width === 'fit' || this.report.height === 'fit') return 'flex: 0 1 min-content;'
      if (this.report.width) return `max-width: calc(${this.report.width} - var(--spacing) * 1/2);min-width: calc(${this.report.width} - var(--spacing) * 1/2);`
      if (this.report.height) return `max-height: calc(${this.report.height} - var(--spacing) * 1/2);min-height: calc(${this.report.height} - var(--spacing) * 1/2);`
    },
  },
  errorCaptured(e) {
    this.error = e
    return false
  },
}
</script>
