import { pagesFromGlobRecord, componentsFromGlobRecord, translationFromGlobRecord } from '@100-m/hauru/src/utils/platformBuilder'
import sorting from '../../sorting'
const config = {
  extend: ['app-asset-management', 'app-client-edition', 'plugin-white-label'],
  userflow: ['overview', 'performance', 'allocation', 'details'],
  formats: {
    contrib_sensi_fund: '.2f',
    contrib_sensi_benchmark: '.2f',
    contrib_sensi_fund_filter: '.2f',
    exposure_fund: '.2%',
    exposure_benchmark: '.2%',
    nav: ',.5s',
    aum: ',.5s',
    trackingError: '.2%',
    informationRatio: '.2%',
    valuation: '.2f',
  },
}
config.sorting = sorting
config.client = 'lgi'
config.groupingOptions = ['category']
config.tagField = 'category'
config.fundSelection = ['category']
config.characteristicsSchema = `
  characteristics {
    managers
    orientation
    custodian
    fund_admin
    category
    valuation_frequency
  }`
config.settingsSchema = `
  settings`
config.displayManagers = true
config.displayManagementComment = true
config.combine_in_out_mvt = true
config.defineColumns = () => ['name', 'weight', 'valuation', 'quantity', 'price']

export default {
  pages: pagesFromGlobRecord(import.meta.globEager('./pages/**/*.vue'), './pages'),
  components: componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  translations: translationFromGlobRecord(import.meta.globEager('./translations/**/*.yml')),
  config,
}
