import { getCurrentInstance, inject } from 'vue'
import { ComponentDoc } from 'vue-docgen-api'

const storiesRaw = import.meta.globEager('./**/*.vue', { as: 'raw' })
const stories = Object.fromEntries(
  Object.entries(storiesRaw).map(([k, v]) => [k.match(/([^\/]+)\/?$/)?.[1].replace('.story.vue', ''), v]),
)

export function getCode(name?: string) {
  if (name) return stories[name]

  const instance = getCurrentInstance()
  const componentName = instance?.type.__name?.replace('.story', '')
  if (!componentName) return
  return stories[componentName]
}

export function getDoc(componentName: string): ComponentDoc {
  const docs = inject('mergedComponents')
  return docs?.[componentName]?.__docgenInfo
}

export function getType(name: string) {
  if (name === 'IGrkBlockOptions')
    return {
      title: {
        name: 'title',
        type: 'string',
        description: 'Text information to display on top',
        required: false,
      },
      disclaimer: {
        name: 'disclaimer',
        type: 'string',
        description: 'Text information to display on bottom',
        required: false,
      },
      class: {
        name: 'class',
        type: 'string',
        description: 'CSS Class to style the content',
        required: false,
      },
      digit: {
        name: 'digit',
        type: 'number',
        description: 'Number of decimals',
        required: false,
      },
      unit: {
        name: 'unit',
        type: 'string',
        description: 'Unit for the content',
        required: false,
      },
      limit: {
        name: 'limit',
        type: 'number',
        description: 'Max number of lines to display',
        required: false,
      },
    }
  if (name === 'IGrkDataPoint')
    return {
      name: {
        name: 'name',
        type: 'string',
        description: '',
        required: true,
      },
      value: {
        name: 'value',
        type: 'number',
        description: '',
        required: true,
      },
    }
}

export { stories, docs }
