<template>
  <!-- grid-item -->
  <!-- creates visible frame that stays always present -->
  <!-- v-memo: will recalculate tree only if props.view?.show is true -->
  <!-- translateX allows column borders to be aligned under different zoom conditions -->
  <div
    :qa-grid-item="props.view.node.id"
    :qa-line-selected="props.state.selection.items.is(props.view.node.id)"
    :qa-grid-node-type="props.view.node.type"
    class="h-0"
    @mouseover="handleHover(true)"
    @mouseleave="handleHover(false)"
  >
    <div
      v-if="freezedCells.length || unfreezedCellsIndexes.length"
      class="overflow-hidden [contain:strict]"
      v-memo="[(props.view?.show ?? true) && Date.now()]"
      :style="{
        height: props.view.node.height! + 'px',
        width: props.state.container.width - props.view.node.left + 'px',
        transform: `translateX(${props.view.node.left}px)`,
        borderRadius: calculatedRadius,
      }"
    >
      <!-- creates left border -->
      <div
        class="nx-grid-left-border"
        :class="[evaluatedThemeGridItem?.container, gridZIndex.surround, themeSelectedItem]"
        :style="{
          background: 'transparent !important',
          borderRight: 'none !important',
          borderRadius: calculatedRadius + ' !important',
          borderTopRightRadius: '0 !important',
          borderBottomRightRadius: '0 !important',
        }"
      ></div>
      <!-- applies node styles except borders & applies position on the x axis -->
      <div
        class="nx-grid-node"
        :class="[evaluatedThemeGridItem?.container, `group-tree-${props.view.node.level}`, themeSelectedItem]"
        :style="{
          border: 'none',
          borderRadius: calculatedRadius,
          transform: `translateX(${0 - props.state.scroll.throttled.left}px)`,
        }"
      >
        <!-- applies row height -->
        <div
          class="flex items-center bg-inherit"
          :style="{ height: itemHeaderHeight + 'px' }"
          @click="handleHeaderClick"
        >
          <!-- creates right border -->
          <div
            class="nx-grid-right-border"
            :class="[evaluatedThemeGridItem?.container, gridZIndex.aboveSurround, themeSelectedItem]"
            :style="{
              background: 'transparent !important',
              borderLeft: 'none !important',
              borderRadius: calculatedRadius + ' !important',
              borderTopLeftRadius: '0 !important',
              borderBottomLeftRadius: '0 !important',
            }"
          ></div>
          <!-- groups static elements & reverses their position on the x axis, so they appear static -->
          <div
            qa-item-static-elements
            class="flex h-full items-center bg-inherit"
            :class="gridZIndex.surround"
            :style="{ transform: `translateX(${props.state.scroll.throttled.left}px)` }"
          >
            <!-- groups buttons -->
            <div
              qa-node-buttons
              :class="[evaluatedThemeGridItem?.buttons?.container, gridZIndex.surround]"
              class="absolute flex h-full items-center"
              v-show="props.view.node.type === 'node'"
            >
              <nx-button
                :qa-togggle-expand="isExpanded"
                @click="toggleExpanded()"
                class="ml-1 flex-shrink-0 flex-grow-0 bg-inherit"
                :class="[enableTransition ? '' : '!transition-none']"
                size="sm"
                :icon="['i-[ic/baseline-arrow-drop-down]', buttonIconClass]"
              />
              <input
                v-if="props.state.showCheckbox"
                class="!m-auto"
                :class="[
                  evaluatedThemeGridItem?.buttons?.checkbox?.idle,
                  props.state.selection.items.is(props.view.node.id)
                    ? evaluatedThemeGridItem?.buttons?.checkbox?.checked
                    : '',
                  props.state.selection.items.list.length > 0
                    ? evaluatedThemeGridItem?.buttons?.checkbox?.anyChecked
                    : '',
                ]"
                type="checkbox"
                :checked="props.state.selection.items.is(props.view.node.id)"
                @click.stop="handleGroupSelect"
              />
              <div :class="evaluatedThemeGridItem?.buttons?.fader"></div>
            </div>
            <div
              qa-row-buttons
              class="absolute flex h-full items-center"
              :class="[evaluatedThemeGridItem?.buttons?.container, gridZIndex.surround]"
              v-show="props.view.node.type !== 'node'"
            >
              <div qa-row-index v-if="props.state.showIndex" :class="evaluatedThemeGridItem?.buttons?.index">
                {{ rowIndex }}
              </div>
              <slot
                name="component-left"
                :item="props.view.node"
                :is-item-hover="isHover"
                :evaluatedThemeGridItem="evaluatedThemeGridItem"
                :handleItemSelect="handleItemSelect"
              >
                <input
                  v-if="props.state.showCheckbox"
                  class="!m-auto"
                  :class="[
                    evaluatedThemeGridItem?.buttons?.checkbox?.idle,
                    props.state.selection.items.is(props.view.node.id)
                      ? evaluatedThemeGridItem?.buttons?.checkbox?.checked
                      : '',
                    props.state.selection.items.list.length > 0
                      ? evaluatedThemeGridItem?.buttons?.checkbox?.anyChecked
                      : '',
                  ]"
                  type="checkbox"
                  :checked="props.state.selection.items.is(props.view.node.id)"
                  @click="handleItemSelect"
                />
              </slot>
              <slot name="buttons-left" :item="props.view.node" :is-item-hover="isHover" />
            </div>

            <!-- <div
              qa-buttons-right
              class="pointer-events-none absolute flex h-full items-center"
              :style="{
                width:
                  rowOffset +
                  Math.min(
                    props.state.rowWidth.value,
                    props.state.scroll.containerWidth + props.state.columns.visibleFreezedWidth.value,
                  ) +
                  'px',
              }"
            >
              <div
                class="pointer-events-auto absolute right-0 flex h-full items-center"
                :class="[evaluatedThemeGridItem?.buttons?.container, gridZIndex.surround]"
              >
                <slot name="buttons-right" :item="props.view.node" :is-item-hover="isHover" />
              </div>
            </div> -->

            <!-- groups freezed columns -->
            <div
              qa-freezed-columns
              v-if="freezedCells.length"
              class="absolute flex h-full bg-inherit"
              :x="props.state.nodes.maxLeftOffset"
              :y="props.view.node.left"
              :style="{ paddingLeft: `${props.state.nodes.maxLeftOffset - props.view.node.left}px` }"
            >
              <div
                v-for="freezedCell in freezedCells"
                :key="freezedCell.header.index"
                :qa-column-name="cleanLabel(freezedCell.header.label)"
                :nx-cell-column="freezedCell.header.index"
                :nx-cell-item="itemIndex"
                :nx-cell-row="rowIndex"
                :class="[
                  'group/cell',
                  freezedCell.styles?.container,
                  freezedCell.selected === 'range' ? gridTheme?.selected.range : '',
                  freezedCell.selected === 'cell' ? gridTheme?.selected.cell : '',
                ]"
                :style="{
                  width: freezedCell.header.width + 'px',
                }"
                @pointerdown="(e: PointerEvent) => rangeSelectStart(e, freezedCell)"
              >
                <!--
                  @slot Slot for rendering the content of a cell in the grid.
                  @binding {number}         index     The index of the cell within the row
                  @binding {IGridCell}      cell      The data object representing the cell
                -->
                <slot
                  name="cell"
                  :freezed="true"
                  :cell="freezedCell.cell"
                  :row="freezedCell.row"
                  :header="freezedCell.header"
                  :is-item-hover="isHover"
                >
                  <div nx-cell :class="freezedCell.styles?.content">{{ freezedCell.cell }}</div>
                </slot>
                <div
                  v-if="allCells?.[freezedCell.header.index]?.header.width >= 150"
                  qa-buttons-right
                  class="pointer-events-none absolute flex h-full w-full items-center"
                  :style="{
                    'min-width': allCells?.[freezedCell.header.index]?.header.width + 'px',
                  }"
                >
                  <div
                    class="pointer-events-auto absolute right-0 flex h-full items-center"
                    :class="[evaluatedThemeGridItem?.buttons?.container, gridZIndex.surround]"
                  >
                    <slot name="buttons-right" :item="props.view.node" :is-item-hover="isHover" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- groups non-freezed cells -->
          <div qa-unfreezed-columns class="flex h-full bg-inherit">
            <div
              :style="{ width: props.state.columns.visibleFreezedWidth.value + unfreezedCellsLeftWidth + 'px' }"
            ></div>
            <div
              v-if="unfreezedCellsIndexes.length"
              class="flex"
              :style="{ transform: `translateX(${props.state.nodes.maxLeftOffset - props.view.node.left}px)` }"
            >
              <div
                v-if="allCells.length"
                v-for="index in unfreezedCellsIndexes"
                :key="index"
                :qa-column-name="cleanLabel(allCells?.[index]?.header.label)"
                :nx-cell-column="index"
                :nx-cell-item="itemIndex"
                :nx-cell-row="rowIndex"
                :class="[
                  'group/cell',
                  allCells?.[index]?.styles?.container,
                  allCells?.[index]?.selected === 'range' ? gridTheme?.selected.range : '',
                  allCells?.[index]?.selected === 'cell' ? gridTheme?.selected.cell : '',
                ]"
                :style="{
                  width: allCells?.[index]?.header.width + 'px',
                }"
                @pointerdown="(e: PointerEvent) => rangeSelectStart(e, allCells?.[index])"
              >
                <slot
                  name="cell"
                  :freezed="false"
                  :cell="allCells?.[index]?.cell"
                  :row="allCells?.[index]?.row"
                  :header="allCells?.[index]?.header"
                  :is-item-hover="isHover"
                >
                  <div nx-cell :class="allCells?.[index]?.styles?.content">{{ allCells?.[index]?.cell }}</div>
                </slot>
                <div
                  v-if="allCells?.[index]?.header.width >= 150"
                  qa-buttons-right
                  class="pointer-events-none absolute flex h-full w-full items-center"
                  :style="{
                    'min-width': allCells?.[index]?.header.width + 'px',
                  }"
                >
                  <div
                    class="pointer-events-auto absolute right-0 flex h-full items-center"
                    :class="[evaluatedThemeGridItem?.buttons?.container, gridZIndex.surround]"
                  >
                    <slot name="buttons-right" :item="props.view.node" :is-item-hover="isHover" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="unfreezedCellsIndexes.length" :style="{ width: rowOffset + 'px' }"></div>
          </div>
        </div>
        <!-- applies background border for freezed columns -->
        <div
          v-if="props.state.columns.visibleFreezed.length > 0"
          class="h-full bg-inherit"
          :class="[props.state.scroll.throttled.left > 0 ? gridTheme?.freezed?.scrolled : '']"
          :style="{
            width: 20 + 'px',
            transform: `translateX(${
              props.state.columns.visibleFreezedWidth.value +
              props.state.nodes.maxLeftOffset -
              props.view.node.left +
              props.state.scroll.throttled.left -
              20
            }px) translateY(${-itemHeaderHeight}px)`,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * @component Represents a single item (row or node) in a grid
 */
import { isNil } from 'lodash'
import {
  IGridCell,
  IGridHeader,
  IGridState,
  IGridTheme,
  IGridView,
  IMoveEvent,
  evaluateThemeFunctions,
  gridZIndex,
  useMove,
  useTheme,
  watchImmediate,
} from '@hauru/common'
import { DeepReadonly, computed, onMounted, ref } from 'vue'

interface IProps {
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the grid among the types defined in the theme config
   */
  type?: string
  /**
   * Data of current view (views are reused, but their content changes)
   */
  view: DeepReadonly<IGridView>
  /**
   * State of the grid
   */
  state: IGridState
}

const DEBUG = false

const props = withDefaults(defineProps<IProps>(), {})

const themeConfig = useTheme()
const gridTheme = themeConfig.computedThemeType('grid', props)
const rowOffset = computed(
  () =>
    props.state.nodes.maxLeftOffset - props.view.node.left + props.state.nodes.maxRightOffset - props.view.node.right,
)

const isExpanded = ref(true)
const enableTransition = ref(false)

const freezedCells = ref<IGridCell[]>([])
const allCells = ref<IGridCell[]>([])
// An array of indexes improves v-for performance
const unfreezedCellsIndexes = ref<number[]>([])
const unfreezedCellsLeftWidth = ref(0)

const trackCellsChange = ref(false)

const isHover = ref(false)

function handleHover(status: boolean) {
  isHover.value = status
}

function registerCellsChange() {
  trackCellsChange.value = !trackCellsChange.value
}

const moveRange = useMove({
  onMove: rangeSelectMove,
  onMoveEnd: () => props.state.selection.selectRangeEnd(),
  setTargetElement: e => (e.target as HTMLElement).closest('[nx-cell-column]'),
})

function rangeSelectMove({ event }: IMoveEvent) {
  props.state.selection.selectRange(event)
}

function rangeSelectStart(event: PointerEvent, cell: IGridCell) {
  if (props.view.node.type !== 'row') {
    props.state.selection.items.toggleExclusive(props.view.node?.metadata?.data_id ?? props.view.node.id, true)
    return
  }

  moveRange.moveStart(event, false)
  props.state.selection.selectRangeStart(event, props.view.node, cell.header.index)

  if (props.state.selection.enable.selectionMode === 'combined' && !isNil(rowIndex.value)) {
    const includeItem = props.state.selection.items.is(props.view.node.id)

    if (event.ctrlKey) {
      if (!includeItem) {
        props.state.selection.items.toggleSelect(props.view.node.id, true)
      } else {
        props.state.selection.items.toggleSelect(props.view.node.id)
      }
    } else {
      if (!includeItem || props.state.selection.items.list.length > 1) {
        props.state.selection.items.toggleExclusive(props.view.node.id, true)
      }
    }
  }
}

const itemIndex = computed(() =>
  props.view.node.type === 'row'
    ? props.view.node.index_item_first_row + props.view.rowIndex!
    : props.view.node.index_item,
)

const rowIndex = computed(() =>
  props.view.node.type === 'row' ? props.view.node.index_first_row + props.view.rowIndex! : undefined,
)

/**
 * Updates cell selection status to optimize performance
 */
watchImmediate(
  () => props.state.selection.range.trackChanges,
  () => {
    freezedCells.value.forEach(cell => {
      cell.selected = determineSelectedStatus(cell.header)
    })
    allCells.value.forEach(cell => {
      cell.selected = determineSelectedStatus(cell.header)
    })
  },
)

/**
 * Updates cell content when data or theme changes
 */
watchImmediate(
  [
    () => props.view.dataId,
    () => props.state.data,
    () => props.view.node,
    gridTheme,
    props.state.columns.visibleFreezed,
  ],
  () => {
    if (props.view.dataId === undefined) return

    if (DEBUG) console.log('cells changed', props.view.dataId)
    registerCellsChange()
    freezedCells.value = getCells(props.state.columns.visibleFreezed)
    allCells.value = getCells(props.state.columns.visibleAll)
  },
)

/**
 * Recalculates the indexes of unfreezed cells that should be rendered on screen (based on the current scroll position & container resize).
 */
watchImmediate(
  [
    () => props.state.scroll.throttled.left,
    () => props.state.columns.trackResize,
    () => props.state.container.width,
    props.state.columns.visibleUnfreezed,
  ],
  () => {
    recalculateUnfreezedCellsIndexes()

    if (DEBUG) console.log('recalculate unfreezedCellsIndexes', unfreezedCellsIndexes.value)
  },
)

/**
 * Recalculates the indexes of unfreezed cells that should be rendered on screen
 * @param all - Indicates whether to recalculate indexes for all columns or only for those that are currently in view.
 */
function recalculateUnfreezedCellsIndexes() {
  const result: number[] = []
  let bufferWidth = null

  let previousWidths = 0
  let columnWidths = 0
  const availableWidth =
    props.state.container.width - props.state.columns.visibleFreezedWidth.value - props.view.node.left

  // Loop through unfreezed columns to find those that are currently in view
  for (let i = 0; i < props.state.columns.visibleUnfreezed.length; i++) {
    const column = props.state.columns.visibleUnfreezed[i]
    previousWidths += column.width
    // If the column is on the left of the viewport, we skip it
    if (props.state.scroll.throttled.left > previousWidths) {
      columnWidths += column.width
      continue
    }
    // If the column is on the right of the viewport, we stop
    if (previousWidths - column.width > props.state.scroll.throttled.left + availableWidth) {
      break
    }
    // Add column that is currently in view to the result array
    if (bufferWidth === null) bufferWidth = columnWidths
    result.push(column.index)
  }

  // Update indexes and buffer width if they have changed
  if (result.length !== unfreezedCellsIndexes.value.length || result[0] !== unfreezedCellsIndexes.value[0]) {
    registerCellsChange()
    unfreezedCellsIndexes.value = result
  }
  if (unfreezedCellsLeftWidth.value !== (bufferWidth ?? 0)) {
    unfreezedCellsLeftWidth.value = bufferWidth ?? 0
  }
}

const evaluatedGridTheme = ref<Partial<IGridTheme>>({})
/**
 * Reevaluates styles when the id of the item changes
 */
watchImmediate([() => props.view.dataId, () => props.state.data, () => props.view.node, gridTheme], () => {
  if (DEBUG) console.log('evaluating theme w. props')
  if (gridTheme.value)
    evaluatedGridTheme.value = evaluateThemeFunctions(
      {
        node: gridTheme.value.node,
        row: gridTheme.value.row,
      },
      props,
    )
  else evaluatedGridTheme.value = {}
})

const evaluatedThemeGridItem = computed(() => {
  if (DEBUG) console.log('evaluating theme item', props.view.node.type)
  return props.view.node.type === 'node' ? evaluatedGridTheme.value?.node : evaluatedGridTheme.value?.row
})

const itemHeaderHeight = computed(
  () =>
    (props.view.node.type === 'row' ? props.state.nodes.rowHeight : evaluatedGridTheme.value?.node?.nodeHeaderHeight) ??
    0,
)

const calculatedRadius = computed(() => {
  if (DEBUG) console.log('calculating radius')
  const radius = evaluatedThemeGridItem.value?.radius
  if (radius instanceof Array) {
    return radius.map(r => r + 'px').join(' ')
  }
  return radius + 'px'
})

const buttonIconClass = computed(() => {
  if (DEBUG) console.log('calculating button icon class')
  return [
    isExpanded.value ? '' : 'rotate-[-90deg]',
    enableTransition.value ? 'transition-transform' : '!transition-none',
  ]
})

/**
 * Updates the expanded state of the item when the collapsed list changes
 */
watchImmediate([() => props.view.dataId, props.state.nodes.collapsed.list], () => {
  if (props.view.dataId === undefined) return

  if (DEBUG) console.log('watching collapsed')
  const result = !props.state.nodes.collapsed.is(props.view.dataId)
  if (result !== isExpanded.value) {
    if (DEBUG) console.log('updating expanded from', isExpanded.value, 'to', result)
    isExpanded.value = result
  }
})

// usePropsDiff(`item${id.value}`, props)

/**
 * Toggles the expanded state of the current item
 */
function toggleExpanded() {
  if (props.view.dataId === undefined) return

  if (props.view.node.type === 'node' && !props.state.expandHeadersOnClick)
    props.state.nodes.collapsed.toggle(props.view.dataId)

  enableTransition.value = true
  setTimeout(() => {
    enableTransition.value = false
  }, 500)
}

onMounted(() => {
  if (DEBUG) console.log('mounted', props.view.id, props.view.dataId)
})

/**
 * Get an array of cells to be displayed based on the corresponding list columns
 * @param visibleColumns - The array of column headers to be displayed and their indexes.
 */
function getCells(visibleColumns: DeepReadonly<IGridHeader[]>) {
  const cells: IGridCell[] = []
  for (const header of visibleColumns) {
    const cell = header.getCell(props.view.dataId!)
    cells.push({
      header,
      cell,
      row: props.state.dataMap.get(props.view.dataId!) ?? {},
      styles: evaluateThemeFunctions(gridTheme.value?.cell ?? {}, { ...props, header, cell }) as any,
      selected: determineSelectedStatus(header),
    })
  }
  return cells
}

const themeSelectedItem = computed(() => {
  if (props.state.selection.items.is(props.view.node.id))
    return props.view.node.type === 'row' ? gridTheme.value?.selected.row : gridTheme.value?.selected.node
  return ''
})

function determineSelectedStatus(header: DeepReadonly<IGridHeader>): IGridCell['selected'] {
  if (!props.state.selection.enable.cells || props.view.node.type !== 'row') return false

  const range = props.state.selection.range

  // No cell is currently selected
  if (range.fromColumn === null && range.fromRow === null) return false

  // A row or column is currenty selected, we only select cells on columns (otherwise its the whole row)
  if (range.fromRow === null || range.fromColumn === null) return range.fromColumn === header.index ? 'range' : false

  // A single cell is currently selected
  if (range.toColumn === null || range.toRow === null)
    return range.fromColumn === header.index && range.fromRow === rowIndex.value ? 'cell' : false

  const [fromRow, toRow] = [range.fromRow, range.toRow].sort()
  const [fromColumn, toColumn] = [range.fromColumn, range.toColumn].sort()

  // Checks if in range
  return fromColumn <= header.index &&
    toColumn >= header.index &&
    rowIndex.value !== undefined &&
    fromRow <= rowIndex.value &&
    toRow >= rowIndex.value
    ? 'range'
    : false
}

function handleItemSelect(e: MouseEvent) {
  if (rowIndex.value === undefined) return
  if (e.shiftKey) props.state.selection.items.selectUntil(props.view.node.id)
  else props.state.selection.items.toggleSelect(props.view.node.id)
}

function handleGroupSelect(e: MouseEvent, node = props.view.node, value?: boolean) {
  props.state.selection.items.toggleSelectNode(node, value)
}

function handleHeaderClick() {
  if (props.view.dataId === undefined) return
  if (props.view.node.type === 'node' && props.state.expandHeadersOnClick)
    props.state.nodes.collapsed.toggle(props.view.dataId)
}

function cleanLabel(label: string) {
  const labelWithoutSpaces = label.replace(/ /g, '_')
  const cleanedLabel = labelWithoutSpaces.replace(/[^\w\s]/g, '')
  return cleanedLabel.toLowerCase()
}
</script>

<style lang="postcss" scoped>
.nx-grid-left-border {
  @apply pointer-events-none absolute h-full w-7 [contain:strict];
}

.nx-grid-right-border {
  @apply pointer-events-none absolute bottom-0 left-5 right-0 top-0 [contain:strict];
}

.nx-grid-node {
  @apply h-full w-fit overflow-hidden [contain:content];
}
</style>
