<template>
  <transition
    enter-active-class="transition-all transition-fastest ease-out-quad"
    leave-active-class="transition-all transition-faster ease-in-quad"
    enter-class="opacity-0 scale-70"
    enter-to-class="opacity-100 scale-100"
    leave-class="opacity-100 scale-100"
    leave-to-class="opacity-0 scale-70"
  >
    <teleport :to="toSelector" v-if="isOpenModal">
      <div
        class="fixed bg-white rounded overflow-hidden shadow-md teleportContent"
        role="dialog"
        ref="teleportContent"
        aria-modal="true"
        aria-labelledby="modal-headline"
        :style="positionStyle"
      >
        <slot />
      </div>
    </teleport>
  </transition>
</template>

<script>
import { ref, watch, computed, onMounted, onUnmounted } from 'vue'
export default {
  props: {
    isOpenModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    reattachedParent: {
      type: String,
      required: true,
      default: null,
    },
    toSelector: {
      type: String,
      required: false,
      default: 'body',
    },
  },
  setup(props) {
    const isOpenModal = ref(false)
    const teleportContent = ref(null)
    const YPosition = ref(0)
    const XPosition = ref(0)
    const XDirectionOpen = ref('right')
    const YDirectionOpen = ref('top')

    const resize = () => {
      if (isOpenModal.value === false) return
      const viewHeight = window.innerHeight
      const parentPosition = document.querySelector(props.reattachedParent).getBoundingClientRect()
      YDirectionOpen.value = viewHeight - parentPosition.y < viewHeight / 2 ? 'bottom' : 'top'
      XPosition.value = parentPosition.x
      if (YDirectionOpen.value === 'top') YPosition.value = parentPosition.y + parentPosition.height
      else YPosition.value = viewHeight - parentPosition.y
    }

    const checkOutOfTheWindow = () => {
      resize()
      if (teleportContent?.value?.offsetWidth) {
        const parentPosition = document.querySelector(props.reattachedParent).getBoundingClientRect()
        const menuWidth = document.querySelector('.nav-bottom')
          ? document.querySelector('.nav-bottom').getBoundingClientRect().x +
            document.querySelector('.nav-bottom').getBoundingClientRect().width
          : 0
        let positionInWindow = teleportContent.value.offsetWidth + XPosition.value - window.innerWidth - menuWidth
        let freeDistance = window.innerWidth - parentPosition.x + parentPosition.width
        let outOfWindow = parentPosition.x + teleportContent.value.offsetWidth - window.innerWidth
        if (outOfWindow > 0) XPosition.value -= outOfWindow
        if (XPosition.value < menuWidth) XPosition.value += menuWidth - XPosition.value
      }
    }

    watch(
      () => props.isOpenModal,
      isShow => {
        isOpenModal.value = isShow
      },
    )

    watch(
      () => $root.retract,
      () => {
        resize()
      },
      { deep: true },
    )

    const positionStyle = computed(() => {
      checkOutOfTheWindow()
      return {
        [YDirectionOpen.value]: `${YPosition.value}px`,
        left: `${XPosition.value}px`,
        'max-height': `calc(100vh - ${YPosition.value}px)`,
      }
    })

    onMounted(() => window.addEventListener('resize', resize))

    onUnmounted(() => window.removeEventListener('resize', resize))

    return {
      isOpenModal,
      teleportContent,
      positionStyle,
    }
  },
}
</script>
<style scoped>
.teleportContent {
  max-width: 95vw;
  overflow: auto;
  z-index: 1035;
}
</style>
