<template lang="pug">
<div v-html="'<style>' + $root.style + '</style>'"></div>
component(:is="layout")
</template>

<script>
import context from './features/context.js'
import { useTranslation, downloadMixin } from './features/translation.js'
import { provide, ref } from 'vue'
import './features/commandr.js'
export default {
  mixins: [context, downloadMixin],
  setup() {
    const progress = ref(0)
    const db = ref({})
    const updateDb = (key, value) => (db.value[key] = value)
    provide('updateDb', updateDb)
    provide('progress', progress)
    provide('setProgress', value => (progress.value = value))
    return {
      ...useTranslation(),
      progress,
      db,
    }
  },
}
</script>
