import { inject, onMounted, onUnmounted, ref } from 'vue'
import shareService from '../../../services/ShareService'

export function useShares(expired = false, standby = false, extra_share_characteristics = []) {
  const shares = ref([])
  const fund_context = ref({})
  const loaded = ref(false)

  onMounted(async () => {
    shares.value = await shareService.listAll(expired, standby, extra_share_characteristics)
    fund_context.value = makeFundContext(shares.value)
    loaded.value = true
  })

  return {
    shares,
    fund_context,
    loaded,
  }
}

function makeFundContext(shares) {
  return shares.reduce((acc, s) => {
    acc[s.share_id] = {
      ...s,
      ...s.characteristics
    }
    return acc
  }, {})
}
