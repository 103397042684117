import FirebaseDatabase from './database/firebase/FirebaseDatabase.js'
import PostgresAcrossHasura from './database/postgres/PostgresAcrossHasura.js'
import DockerFileSystem from './filesystem/docker'
import AWSFileSystem from './filesystem/aws'

export function initCommandrSDK({
  mode,
  root,
  name,
  project,
  apiKey,
  hasuraEndpoint,
  hasuraAdminSecret,
  hasuraDataSourceName
}) {
  switch (mode) {
    case 'firebase':
      return new FirebaseDatabase({ root, name, project, apiKey })
    case 'hasura':
      return new PostgresAcrossHasura({
        hasuraEndpoint,
        hasuraAdminSecret,
        hasuraDataSourceName
      })
    default:
      throw new TypeError(
        `for commandrSDK db mode "${mode}" doesn't have a implemented value`
      )
  }
}

export function initCommandrSDKFileSystem({
  endpoint,
  token,
  mode,
  awsRegion,
  awsBucketName,
  awsCredentials,
  awsAssumeRole
}) {
  switch (mode) {
    case 'docker':
      return new DockerFileSystem({ endpoint, token })
    case 'aws':
      return new AWSFileSystem({
        awsRegion,
        awsBucketName,
        awsCredentials,
        awsAssumeRole
      })
    case 'google':
      console.log('Not using the SDK file system.')
      return null
    default:
      throw new TypeError(
        `For commandrSDK file system mode "${mode}" doesn't have an implemented value.`
      )
  }
}
